import SecureLS from "secure-ls";

const ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "SLAskwqekasdfjSJdswqke",
});

// local storage action
export const setLocalStorage = (key, value) => ls.set(key, value);

export const getLocalStorage = (key) => ls.get(key);

export const removeLocalStorage = (key) => ls.remove(key);
export const removeAllLocalStorage = () => ls.removeAll();

export const formdataToJson = (formData) => {
  var object = {};
  formData.forEach(function (value, key) {
    object[key] = value;
  });
  var json = JSON.stringify(object);

  return json;
};

export const URL_REGEX = new RegExp(
  "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
  "i" // validate fragment locator
);

export const toBase64 = (type = "decode", value) => {
  if (type === "encode") {
    return window?.btoa(value);
  } else {
    return window?.atob(value);
  }
};

export const toCurrencyFormat = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  })
    .format(number)
    .replace("Rp", "")
    .replace(/\s/g, "");
};

export const toCapital = (text) => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return "";
};

export const getInitials = (str) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase()).join('');
}

export const formattorbase64 = (type, file) => {
  return `data:${type};base64,${file}`;
};

export const IDRFormater = (val) => {
  // val = val ? val.replace(/\.\d+/, '') : 0

  val = parseInt(val);

  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Ensure val is a number before using toFixed
  const formattedValue = typeof val === 'number' ? val.toFixed(0) : 0;

  // if nan
  if (formattedValue === 'NaN') return 'Rp 0';

  // Remove decimal digits
  return formatter.format(formattedValue).replace(/\,\d+/, '');
};

export const toPermalink = (str) => {
  // Menghapus karakter non-alfanumerik
  let permalink = str?.replace(/[^\w\s]/gi, '');

  // Mengganti spasi dengan tanda hubung
  permalink = permalink?.replace(/\s+/g, '-');

  // Mengonversi huruf menjadi huruf kecil
  permalink = permalink?.toLowerCase();

  return permalink;
}

export const countPercent = (val1, val2) => {
  return (val1 / val2) * 100;
}

export const hexToRGB = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  
  // return {r, g, b} 
  return { r, g, b };
}

const getFileExtension = (url) => {
  // Mendapatkan bagian akhir dari URL yang berisi ekstensi file
  const parts = url?.split('.');
  return parts[parts?.length - 1];
}

export const handleDetectFile = (url) => {
  const extension = getFileExtension(url)

  // Membuat pemetaan ekstensi file dengan jenis file yang sesuai
  const fileTypes = {
    'jpg': 'image',
    'jpeg': 'image',
    'png': 'image',
    'pdf': 'pdf',
  };

  return fileTypes[extension]
}

export const formatRekening = (rekening) => {
  // Memisahkan nomor rekening menjadi setiap 4 digit
  rekening = rekening?.toString()
  let formattedRekening = rekening?.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
  return formattedRekening;
}

export const IDRInitialSting = (num) => {
  if (num >= 1000000000000) {
    return (num / 1000000000000).toFixed(1).replace(/\.0$/, '') + ' Triliun';
  }
  if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + ' Miliar';
  }
  if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + ' Juta';
  }
  if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + ' Ribu';
  }
  return num;

}

export const removeHtmlTags = (text) => {
  return text.replace(/<[^>]*>?/gm, '');
}

export const handleName = (title, name) => {
  if (title != null) {
    return `${toCapital(title)}. ${name}`
  } else {
    return name
  }
}