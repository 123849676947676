import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getDataAkomodasi, getDataAkomodasiTourCountry } from "utils/api/requestPaket";

import { handleError } from "stores/actions/errorGeneral";
import HotelData from "./components/HotelData";


const Step3 = ({
  values,
  setFieldValues,
  monthDepature,
  yearDepature,
  tourCountryIds,
  tourCountryData
}) => {

  const dispatch = useDispatch()

  const [isLoadingGetHotel, setIsLoadingGetHotel] = useState(false)
  const [isLoadingGetHotelTour, setIsLoadingGetHotelTour] = useState(false)
  const [hotelOptions, setHotelOptions] = useState([]);
  const [hotelTourOptions, setHotelTourOptions] = useState([]);

  const getDataAllHotel = async () => {
    setIsLoadingGetHotel(true)
    const response = await getDataAkomodasi({ month: monthDepature, year: yearDepature });
    setIsLoadingGetHotel(false)

    if(response.status == 200) {
      setHotelOptions(response.data.data);
    } else {
      dispatch(handleError(response))
    }
  };

  const getDataAllHotelTour = async () => {
    setIsLoadingGetHotelTour(true)
    const response = await getDataAkomodasiTourCountry({ month: monthDepature, year: yearDepature, paket_kalkulator_tour_id: tourCountryIds });
    setIsLoadingGetHotelTour(false)

    if(response.status == 200) {
      setHotelTourOptions(response.data.data);
    } else {
      dispatch(handleError(response))
    }
  };

  const handleChangeHotelTour = (value, numberOfNights, index) => {
    let _tempHotelTourIds = [...values.hotel_tour_ids]
    _tempHotelTourIds[index] = value

    let _tempHotelNights = [...values.hotel_tour_number_of_nights]
    _tempHotelNights[index] = numberOfNights

    setFieldValues('hotel_tour_ids', _tempHotelTourIds)
    setFieldValues('hotel_tour_number_of_nights', _tempHotelNights)
  }

  useEffect(() => {
    getDataAllHotel()
  }, [])

  return (
    <>
      <div className="font-semibold font-base mb-4">Pilih Hotel</div>
      <div className="flex flex-col gap-4">
        <HotelData 
          label={"Madinah"}
          options={hotelOptions.filter((item) => item.city === "madinah")}
          loadingGet={isLoadingGetHotel}
          number_of_nights={values.hotel_madinah_number_of_nights}
          value={values.hotel_madinah_id}
          onChange={(value, number_of_nights) => {
            setFieldValues('hotel_madinah_id', parseInt(value))
            setFieldValues('hotel_madinah_number_of_nights', number_of_nights)
          }}
          name={`hotel-madinah`}
          
          />

        <HotelData 
          label={"Makkah"}
          options={hotelOptions.filter((item) => item.city === "makkah")}
          loadingGet={isLoadingGetHotel}
          number_of_nights={values.hotel_makkah_number_of_nights}
          value={values.hotel_makkah_id}
          onChange={(value, number_of_nights) => {
            setFieldValues('hotel_makkah_id', parseInt(value))
            setFieldValues('hotel_makkah_number_of_nights', number_of_nights)
          }}
          name={`hotel-makkah`}
          />
      </div>
    </>
  )
}

export default Step3