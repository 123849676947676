import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCheckValidEmail,
  handleCheckValidEmailForgetPassword,
  handleCheckingValidateNumberPhone,
  handleCheckingWithPhoneNumber,
  handleEmailForgotPassword,
  handleSubmitChangePassword,
  handleSubmitLogin,
  handleVerificationOTP,
} from "../../stores/actions/login";

import LoginStyle from "./Login.style";
import CardLogin from "./component/CardLogin";
import CardForgotPassword from "./component/CardForgotPassword";
import CardOTP from "./component/CardOTP";
import { EMAIL_REGEX } from "../../constant/constanta";
import useDebounce from "../../utils/helpers/useDebounce";

import ImageIllus from "../../assets/images/login-illus.svg";
import IconSidebar from "../../assets/images/logo-mosleme-travel.svg";

import NurRamadhanLogo from "../../assets/images/nur-ramadhan-logo.png";

import { TRAVEL_SHORT_NAME, SYSTEM_YEAR_INSTALL } from "../../constant/constanta";
import moment from "moment/moment";

import { osName, osVersion, browserName, fullBrowserVersion } from "react-device-detect";

const LoginPage = () => {
  const [loginType, setLoginType] = useState("email");
  const [selectPhoneCode, setSelectPhoneCode] = useState(false);
  const [phoneRegion, setPhoneRegion] = useState("+62");
  const [options, setOptions] = useState([
    {
      value: "+62",
      label: "Indonesia",
    },
    {
      value: "+63",
      label: "Filipina",
    },
    {
      value: "+64",
      label: "Selandia Baru",
    },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isLoading,
    error,
    emailValidated,
    dataForgotPassword,
    dataVerificationOTP,
    isChangePasswordSuccess,
  } = useSelector((state) => state.login);
  const [forgotPage, setForgotPage] = useState(false);
  const [otpPage, setOtpPage] = useState(false);
  const [otp, setOtp] = useState("");
  const [validOTP, setValidOTP] = useState(false);

  const formikLogin = useFormik({
    initialValues: {
      email: "",
      password: "",
      telephone: "",
    },
    initialTouched: {
      email: true,
    },
    validationSchema: Yup.object().shape(
      loginType === "email"
        ? {
            email: Yup.string()
              // .email("Email tidak valid")
              .matches(EMAIL_REGEX, { message: "Email tidak valid" })
              .required("Email wajib diisi."),
            password: Yup.string()
              .min(6, "Password minimal 6 karakter.")
              .required("Password wajib diisi."),
          }
        : {
            telephone: Yup.string().required("Nomer telephone Wajib diisi"),
          }
    ),
    onSubmit: (val, actions) => {
      let payload = {
        email: val.email,
        password: val.password,
        os_name: osName,
        os_version: osVersion,
        browser_name: browserName,
        browser_version: fullBrowserVersion,
      };
      if (loginType === 'telepon') {
        dispatch(
          handleCheckingValidateNumberPhone(
            {
              phone_number: formikLogin.values.telephone,
            },
            (message) => {
              setOtpPage(true);
            }
          )
        );
      } else {
        dispatch(handleSubmitLogin(payload, history));
      }
    },
  });

  const formikForgotPassword = useFormik({
    initialValues: {
      forgotEmail: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    initialTouched: {
      forgotEmail: true,
    },
    validationSchema: Yup.object().shape({
      forgotEmail: Yup.string().required(
        "Email atau phone number wajib diisi."
      ),
      newPassword: Yup.string()
        .min(6, "Password minimal 6 karakter.")
        .required("Password wajib diisi."),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Password harus sama")
        .required("Konfimasi Password wajib diisi."),
    }),
    onSubmit: (val, actions) => {},
  });

  const [isPhoneRender, setIsPhoneRender] = useState(false);

  const isPhoneRenderDebounce = useDebounce(
    formikForgotPassword.values.forgotEmail,
    1000
  );

  useEffect(() => {
    if (isNaN(parseInt(isPhoneRenderDebounce))) {
      setIsPhoneRender(false);
    } else {
      setIsPhoneRender(true);
    }
  }, [isPhoneRenderDebounce]);

  const emailDebounce = useDebounce(formikLogin.values.email, 1000);
  const emailForgotDebounce = useDebounce(
    formikForgotPassword.values.forgotEmail,
    2000
  );

  useEffect(() => {
    if (emailDebounce) {
      dispatch(
        handleCheckValidEmail(
          {
            email: emailDebounce,
          },
          (message) => {}
        )
      );
    }
  }, [emailDebounce]);

  useEffect(() => {
    if (emailForgotDebounce) {
      dispatch(
        handleCheckValidEmailForgetPassword(
          {
            account: emailForgotDebounce,
          },
          (message) => {
            // setValidOTP(true);
            setOtpPage(true);
            // setForgotPage(false);
          }
        )
      );
    }
  }, [emailForgotDebounce]);

  const forgotPasswordByEmail = (val) => {
    dispatch(
      handleCheckValidEmailForgetPassword(val, (message) => {
        // setValidOTP(true);
        setOtpPage(true);
      })
    );
    // dispatch(handleEmailForgotPassword(val));
  };

  const checkPhoneNumberOTPbyPhone = (val) => {
    dispatch(
      handleCheckingValidateNumberPhone(val, (message) => {
        setOtpPage(true);
      })
    );
  };

  useEffect(() => {
    if (otp.length === 4) {
      if (loginType === 'telepon') {
        dispatch(
          handleCheckingWithPhoneNumber(
            {
              phone_number: formikLogin.values.telephone,
              otp: otp,
              os_name: osName,
              os_version: osVersion,
              browser_name: browserName,
              browser_version: fullBrowserVersion,
            },
            history
          )
        );
      } else {
        dispatch(
          handleVerificationOTP(
            {
              account: formikForgotPassword.values.forgotEmail,
              otp: otp,
            },
            (message) => {
              setValidOTP(true);
            }
          )
        );
      }
    }
  }, [otp]);

  useEffect(() => {
    if (dataVerificationOTP) {
      setOtpPage(false);
      setValidOTP(true);
      setForgotPage(true);
    }
  }, [dataVerificationOTP]);

  useEffect(() => {
    if(forgotPage) {
      formikLogin.setFieldValue('telephone', "")
      formikLogin.setFieldValue('email', "")
    }
  }, [forgotPage])

  const submitChangePassword = () => {
    dispatch(
      handleSubmitChangePassword({
        account: formikForgotPassword.values.forgotEmail,
        otp: otp,
        password: formikForgotPassword.values.newPassword,
        password_confirmation: formikForgotPassword.values.confirmNewPassword,
      })
    );
  };

  useEffect(() => {
    if (isChangePasswordSuccess) {
      setValidOTP(false);
      setForgotPage(false);
      setOtp("");
      formikLogin.resetForm();
      formikForgotPassword.resetForm();
    }
  }, [isChangePasswordSuccess]);

  useEffect(() => {
    if (error) {
      formikLogin.setFieldTouched("email", true, false);
      formikLogin.setFieldError("email", error, true);
    }
  }, [error]);

  const getContent = () => {
    return (
      <>
        <LoginStyle className="flex justify-center items-center p-20 h-screen max-w-[1500px] m-auto">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {/* <Grid item md={12}>
              <img
                className="m-auto absolute top-4 left-[43.5%]"
                src={NurRamadhanLogo}
                alt="Logo"
                width={"234px"}
              />
            </Grid> */}
            <Grid item md={7}>
              <img className="m-auto w-[600px]" src={ImageIllus} alt="illustration"/>
            </Grid>

            <Grid item md={5}>
              {!forgotPage && !otpPage && (
                <CardLogin
                  loginType={loginType}
                  selectPhoneCode={selectPhoneCode}
                  phoneRegion={phoneRegion}
                  options={options}
                  setSelectPhoneCode={setSelectPhoneCode}
                  setPhoneRegion={setPhoneRegion}
                  values={formikLogin.values}
                  handleChange={formikLogin.handleChange}
                  handleBlur={formikLogin.handleBlur}
                  handleSubmit={formikLogin.handleSubmit}
                  touched={formikLogin.touched}
                  errors={formikLogin.errors}
                  setFieldValue={formikLogin.setFieldValue}
                  setLoginType={setLoginType}
                  emailValidated={emailValidated}
                  setForgotPage={setForgotPage}
                  isSubmitting={isLoading}
                />
              )}
              {forgotPage && !otpPage && (
                <CardForgotPassword
                  isPhoneRender={isPhoneRender}
                  values={formikForgotPassword.values}
                  touched={formikForgotPassword.touched}
                  errors={formikForgotPassword.errors}
                  handleBlur={formikForgotPassword.handleBlur}
                  setForgotPage={setForgotPage}
                  setFieldValue={formikForgotPassword.setFieldValue}
                  setOtpPage={setOtpPage}
                  isSubmitting={isLoading}
                  forgotPasswordByEmail={forgotPasswordByEmail}
                  dataForgotPassword={dataForgotPassword}
                  validOTP={validOTP}
                  setPhoneRegion={setPhoneRegion}
                  submitChangePassword={submitChangePassword}
                  setSelectPhoneCode={setSelectPhoneCode}
                  phoneRegion={phoneRegion}
                  options={options}
                />
              )}
              {otpPage && (
                <CardOTP
                  values={formikForgotPassword.values}
                  otp={otp}
                  setOtpPage={setOtpPage}
                  setForgotPage={setForgotPage}
                  setOtp={setOtp}
                  dataForgotPassword={dataForgotPassword}
                  forgotPasswordByEmail={forgotPasswordByEmail}
                  checkPhoneNumberOTPbyPhone={checkPhoneNumberOTPbyPhone}
                  telephone={formikLogin.values.telephone}
                />
              )}
            </Grid>
          </Grid>
        </LoginStyle>
      </>
    );
  };

  return getContent();
};

export default LoginPage;
