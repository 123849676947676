import React, { useState } from "react";
import Card from "../../../../../components/cards";
import Inputs from "../../../../../components/form/inputs";
import * as Yup from "yup";
import Textareas from "../../../../../components/form/textarea";
import Grid from "@mui/material/Grid";
import Buttons from "../../../../../components/buttons";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  createOrUpdateAdditional,
  getMasterAdditional,
} from "stores/actions/datamaster/adminAdditional";
import { getMasterId } from "stores/actions/datamaster";
import RichTextEditor from "components/richTextEditor";

function AdditionalComponentModal() {
  const dispatch = useDispatch();
  const { masterId } = useSelector((state) => state?.datamaster);
  const { global } = useSelector((state) => state.general);
  const { isLoading } = useSelector((state) => state.adminAdditional);

  const reload = () => {
    return setTimeout(() => {
      additional.resetForm();
      dispatch(getMasterId({}));
      dispatch(
        getMasterAdditional({
          page_number: global?.currentpage,
          per_page: global?.size,
          keyword: "",
          sort_column: "id_additional",
          sort_order: "DESC",
        })
      );
    }, 0);
  };

  const additional = useFormik({
    initialValues: {
      name: masterId?.data?.name || "",
      default_price: masterId?.data?.default_price || "",
      description: masterId?.data?.description || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama barang wajib diisi"),
      default_price: Yup.string().required("Harga default wajib diisi"),
      // description: Yup.string().required("Deskripsi wajib diisi"),
    }),
    onSubmit: (val) => {
      if (masterId?.data) {
        val.id_additional = masterId?.data?.id_additional;
        dispatch(createOrUpdateAdditional(val)).then(() => {
          reload();
        });
      } else {
        dispatch(createOrUpdateAdditional(val)).then(() => {
          reload();
        });
      }
    },
  });

  return (
    <Card containerClass="!px-0 !pb-4 !pt-4 !rounded-2xl !shadow-none !bg-transparent !border !border-gray-200">
      <div className="w-full px-4">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <Inputs
              label="Nama Barang"
              placeholder="Masukan Nama Barang"
              id="name"
              name="name"
              value={additional.values.name}
              onChange={additional.handleChange}
              onBlur={additional.handleBlur}
              error={additional.touched.name && additional.errors.name}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Inputs
              label="Harga Default"
              placeholder="Masukan Harga Default"
              id="default_price"
              name="default_price"
              value={additional.values.default_price}
              onChange={additional.handleChange}
              onBlur={additional.handleBlur}
              error={
                additional.touched.default_price &&
                additional.errors.default_price
              }
            />
          </Grid>
        </Grid>
        <div className="mt-4">
          <Grid item sm={12}>
            <RichTextEditor
              placeholder="Masukan Deskripsi Additonal"
              data={{ value: additional.values.description }}
              onChange={(value) => {
                additional.setFieldValue("description", value);
              }}
            />
          </Grid>
        </div>
      </div>
      <div className="w-full flex justify-end border-t border-gray-200 pt-4 mt-4">
        <div className="w-64 px-4 h-auto">
          <Buttons
            text="Selanjutnya"
            disabled={
              !(
                additional.isValid &&
                additional.dirty &&
                additional.values.description !== "<p><br></p>"
              )
            }
            loading={isLoading ? true : false}
            onClick={() => additional.handleSubmit()}
            customClass="btn-primary btn-large"
          />
        </div>
      </div>
    </Card>
  );
}

export default AdditionalComponentModal;
