import React, { useState } from "react";
import ViewItem from "./ViewItem";
import EditItem from "./EditItem";

const Item = ({
  data,
  removeItem,
  generalInfo,
  onClickEditGeneralInfo,
  handleClickManasikCabang,
  handleEdit
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleClickEdit = (status) => {
    handleEdit(status)
    setIsEdit(status);
  };

  return (
    <div className="w-full border p-4 rounded-2xl">
      <ViewItem
        data={data}
        setIsEdit={handleClickEdit}
        removeItem={removeItem}
        generalInfo={generalInfo}
        onClickEditGeneralInfo={onClickEditGeneralInfo}
      />
    </div>
  );
};

export default Item;
