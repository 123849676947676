import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaketFeeAction, {
  addPaketFeeAgent,
  getPaketListAgentFEE,
  getPaketListTabelAgent,
  updatedPaketFeeAgent,
} from "stores/actions/paket/fee";
import { toCurrencyFormat } from "utils/helpers";
import "../../styles/Fee.scss";

import Inputs from "components/form/inputs";
import MoslemeTable from "components/Table";
import Badge from "components/badge";
import Switch from "components/form/switch";
import Buttons from "components/buttons";

import IconEdit from "assets/icons/edit.svg";
import IconSearch from "assets/icons/search-normal.svg";
import IllusJamPasir from "assets/images/Jam-Pasir.svg";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import useDebounce from "utils/helpers/useDebounce";
import Popup from "components/popup";
import Scroll from "components/scroll";
import IhwanWan from "assets/icons/ihwan_icon.svg";
import InputsCurrency from "components/form/inputsCurrency";

const AgenFreelance = () => {
  const dispatch = useDispatch();
  const router = useHistory();

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const { data_list_agent, data_agent, isLoading } = useSelector(
    (state) => state.paketFee
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [listAgen, setListAgent] = useState([]);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  let id = router.location.pathname.replace(/\D/g, "");
  const [dataCustomeListAgentPusat, setDataCustomeListAgentPusat] = useState(
    []
  );
  const [search, setSearch] = useState("");
  const keyword = useDebounce(search, 1000);

  const handleEditFee = (key, idx) => {
    let _temp = [...dataCustomeListAgentPusat];
    _temp[idx][key] = !_temp[idx][key];
    setDataCustomeListAgentPusat(_temp);
  };

  const handleChangeRow = (val, name, index) => {
    let _temp = [...dataCustomeListAgentPusat];
    _temp[index][`${name}`] = val;
    setDataCustomeListAgentPusat(_temp);
  };

  const updatedPaketFee = (data) => {
    setIsLoadingAction(true);

    const payload = {
      id: data?.id,
      fee: data?.fee,
      requiring_fee_active: data?.requiring_fee_active,
      requiring_fee: data?.requiring_fee,
    };

    dispatch(
      updatedPaketFeeAgent(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);

          dispatch(
            getPaketListTabelAgent(id, {
              role_id: 7,
              keyword: keyword,
              per_page: 1000,
              page_number: page,
              sort_column: "created_at",
              sort_order: "asc",
            })
          );

          dispatch(handleSuccess(message));
        },
        (error) => {
          setIsLoadingAction(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const columns = () => [
    {
      title: "Nama",
      dataIndex: "nama",
      render: (val, record) => {
        return <span>{record?.agent_name}</span>;
      },
    },
    {
      title: "Cabang",
      dataIndex: "branch",
      render: (record) => {
        return (
          <Badge
            label={record?.name}
            customClass="uppercase"
            backgroundColor={record?.bg_color}
            color={record?.text_color}
          />
        );
      },
    },
    {
      title: "Fee",
      dataIndex: "fee",
      width: "500px",
      render: (val, record, rowIndex) => (
        <>
          {record.is_edit_fee ? (
            <div className="w-full relative my-[8px] ">
              <InputsCurrency
                id="test"
                placeholder="Masukkan Harga"
                value={record?.fee}
                onChange={(e) => handleChangeRow(e, "fee", rowIndex)}
              />
              <Buttons
                text="Simpan"
                loading={isLoadingAction}
                customClass="btn-primary btn-small absolute top-2 right-2 !w-fit"
                onClick={() => {
                  updatedPaketFee(record);
                }}
              />
            </div>
          ) : (
            <div className="flex flex-row items-center gap-[8px]">
              <div className="txt-green font-semibold">
                Rp {toCurrencyFormat(record?.fee)}
              </div>
              {permissions?.includes('update_package') && (
                <>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleEditFee("is_edit_fee", rowIndex);
                    }}
                  >
                    <img src={IconEdit} alt="edit" />
                  </div>
                </>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Requiring Fee",
      dataIndex: "requiringFee",
      width: "500px",
      render: (val, record, rowIndex) => {
        return (
          <>
            {record.is_edit_fee_requiring ? (
              <div className=" w-full relative my-[8px]">
                <InputsCurrency
                  placeholder="Masukkan harga..."
                  value={record?.requiring_fee}
                  onChange={(e) =>
                    handleChangeRow(e, "requiring_fee", rowIndex)
                  }
                  currency
                />
                <Buttons
                  loading={isLoadingAction}
                  text="Simpan"
                  customClass="btn-primary btn-small absolute top-2 right-2 !w-fit"
                  onClick={() => {
                    updatedPaketFee(record);
                  }}
                />
              </div>
            ) : (
              <div className="flex flex-row items-center gap-[8px]">
                {record?.requiring_fee_active === 1 && (
                  <>
                    <div className="txt-green font-semibold">
                      Rp {toCurrencyFormat(record?.requiring_fee)}
                    </div>
                    {permissions?.includes('update_package') && (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            handleEditFee("is_edit_fee_requiring", rowIndex);
                          }}
                        >
                          <img src={IconEdit} alt="edit" />
                        </div>
                      </>
                    )}
                  </>
                )}

                <Switch
                  customClass="w-[52px] h-[20px]"
                  checked={record?.requiring_fee_active === 1 ? true : false}
                  disabled={!permissions?.includes('update_package')}
                  onChange={(e) => {
                    updatedPaketFee({
                      ...record,
                      requiring_fee_active:
                        record?.requiring_fee_active === 1 ? 0 : 1,
                    });
                  }}
                />
              </div>
            )}
          </>
        );
      },
    },
  ];

  //pagination config
  const pagination = {
    showSizeChanger: true,
    total: data_agent?.tota_data,
    current: page,
    pageSize: pageSize,
    defaultPageSize: 1000,
    pageSizeOptions: ["10", "20"],
  };

  useEffect(() => {
    dispatch(
      getPaketListAgentFEE(id, {
        role_id: 7,
        keyword: "",
        per_page: 1000,
        page_number: 1,
        sort_column: "name",
        sort_order: "asc",
      })
    );

    dispatch(
      getPaketListTabelAgent(id, {
        role_id: 7,
        keyword: keyword,
        per_page: 1000,
        page_number: page,
        sort_column: "created_at",
        sort_order: "asc",
      })
    );
  }, [page, keyword]);

  useEffect(() => {
    if (data_list_agent) {
      let _resultMap = data_list_agent?.data?.map((item) => {
        return {
          ...item,
          checked: item.is_added === 1 ? true : false,
        };
      });

      setListAgent(_resultMap);
    }

    if (data_agent) {
      let _temp = data_agent?.data?.map((item) => {
        return {
          ...item,
          is_edit_fee: false,
          is_edit_fee_requiring: false,
        };
      });

      setDataCustomeListAgentPusat(_temp);
    }
  }, [data_list_agent, data_agent]);

  const [isPilihAgen, setIsPilihAgen] = useState(false);

  const handleAddAgent = () => {
    setIsLoadingAction(true);
    let _temp = listAgen?.filter((item) => item.checked)?.map((val) => val.id);

    const payload = {
      role_id: 7,
      agents_id: _temp,
    };

    dispatch(
      addPaketFeeAgent(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);
          setIsPilihAgen(false);
          dispatch(
            getPaketListTabelAgent(id, {
              role_id: 7,
              keyword: keyword,
              per_page: 1000,
              page_number: page,
              sort_column: "created_at",
              sort_order: "asc",
            })
          );

          dispatch(handleSuccess(message));
        },
        (error) => {
          setIsLoadingAction(false);

          setIsPilihAgen(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleChoiceAgent = () => {
    dispatch(
      getPaketListAgentFEE(id, {
        role_id: 7,
        keyword: "",
        per_page: 1000,
        page_number: 1,
        sort_column: "name",
        sort_order: "asc",
      })
    );
    setIsPilihAgen(true);
  };

  return (
    <>
      <Popup
        open={isPilihAgen}
        title="Pilih Agen"
        width="1020px"
        handleClose={() => setIsPilihAgen(false)}
      >
        <div className="border-solid border-[1px] border-[#E0E0E0]  rounded-[16px]">
          <div className="m-[16px]">
            <Scroll height="524px">
              <div class="grid grid-cols-2 gap-[16px]">
                {listAgen?.map((item, index) => (
                  <div
                    className={`p-[16px] flex flex-row justify-between items-center  @apply shadow-[0px_4px_80px_-4px_#0000000A] ${
                      item.checked ? "!bg-[#FFDCDC] rounded-[12px]" : ""
                    } `}
                  >
                    <div className="flex flex-row justify-start">
                      <div className="h-[56px] w-[56px] rounded-full bg-red-300">
                        <img
                          src={IhwanWan}
                          alt="icon_ihwan"
                          width={56}
                          height={56}
                        />
                      </div>
                      <div className="flex flex-col justify-start content-start items-start ml-[18px]">
                        <div className="text-[14px] text-[#000] font-semibold mb-[8px]">
                          {item?.name}
                        </div>
                        <Badge
                          label={item?.cabang_name}
                          backgroundColor={item?.color_bg_role}
                          color={item?.color_text_role}
                        />
                      </div>
                    </div>
                    <Switch
                      checked={item.checked}
                      onChange={(e) => {
                        let _temp = [...listAgen];
                        _temp[index].checked = !_temp[index].checked;

                        setListAgent(_temp);
                      }}
                    />
                  </div>
                ))}
              </div>
            </Scroll>
          </div>

          <div className="divider"></div>
          <div className="flex flex-row justify-end gap-x-[16px] w-full p-[16px]">
            <Buttons
              text="Batalkan"
              customClass="btn-outline btn-medium "
              onClick={() => setIsPilihAgen(false)}
            />
            <Buttons
              // disabled={listAgen?.filter((item) => item.checked).length === 0}
              text="Simpan"
              customClass="btn-primary btn-medium "
              loading={isLoadingAction}
              onClick={handleAddAgent}
            />
          </div>
        </div>
      </Popup>

      <div className="flex flex-row items-center justify-between p-5">
        <h1 className="text-[18px] text-[#000] font-semibold">
          Custom Fee Per-Agen Freelancer
        </h1>
        <div className="flex flex-row justify-start">
          <Inputs
            id="test"
            placeholder="Search"
            containerClass="w-[491px]"
            iconPosition="left"
            icon={IconSearch}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {permissions?.includes('update_package') && (
            <Buttons
              text="Pilih Agen"
              customClass="btn-outline btn-medium !ml-[16px]"
              onClick={() => handleChoiceAgent()}
            />
          )}
        </div>
      </div>

      {dataCustomeListAgentPusat === null ||
      dataCustomeListAgentPusat?.length === 0 ? (
        <div className="flex flex-col justify-center items-center gap-2 py-10">
          <img
            className="h-[260px] w-[260px]"
            src={IllusJamPasir}
            alt="empty"
          />
          <div>Belum ada agen, di bagian Agen Freelance</div>
        </div>
      ) : (
        <div className="pb-[36px] overflow-auto">
          <MoslemeTable
            loading={isLoading}
            selectionType="checkbox"
            withSelection={true}
            columns={columns()}
            data={dataCustomeListAgentPusat}
            pagination={pagination}
            rowId={(record) => record.id}
            customPaginationClass="pr-4 pt-8 pb-9"
            onPageChange={(page) => setPage(page)}
            onSizeChange={(currentpage, pageSize) => {
              setPage(currentpage);
              setPageSize(pageSize);
            }}
          />
        </div>
      )}
    </>
  );
};

export default AgenFreelance;
