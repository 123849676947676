import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";

import { checkPackage, requestPaket } from "utils/api/requestPaket";

import Buttons from "components/buttons";
import Steppers from "components/steps/Steppers";
import Scroll from "components/scroll";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { IDRFormater } from "utils/helpers";

const CheckPackagePopup = ({
  onSuccess
}) => {

  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(0)

  const [tourCountrySelected, setTourCountrySelected] = useState([])
  const [isCheckingPackage, setIsCheckingPackage] = useState(false)

  const [isCheckedPackage, setIsCheckedPackage] = useState(true)

  const [isRequestingPaket, setIsRequestingPaket] = useState(false)

  const [countryTourName, setCountryTourName] = useState(null)

  const [prices, setPrices] = useState({
    'quad_price': 0,
    'triple_price': 0,
    'double_price': 0,
    'single_price': 0
  })

  const stepItems = [
    {
      title: "Jumlah Hari",
    },
    {
      title: "Maskapai",
    },
    {
      title: "Hotel",
    },
    {
      title: "Opsi Layanan",
    },
    {
      title: "Fee Cabang",
    },
  ];

  const step1Formik = useFormik({
    initialValues: {
      total_pax: null,
      airplane_class: 'economy',
      total_days: null,
      month_departure: null,
      year_departure: null,
      tour_country_id: null,
    },
    validationSchema: Yup.object().shape({
      total_pax: Yup.number()
                  .required("Jumlah pax harus diisi")
                  .min(1, "Jumlah pax minimal 1 pax")
                  .max(500, "Jumlah pax maxilam 500 pax"),
      airplane_class: Yup.string().required("Kelas Maskapai harus dipilih"),
      total_days: Yup.number().required("Total hari harus diisi"),
      month_departure: Yup.string().required("Bulan keberangkatan harus diisi"),
      year_departure: Yup.number().required("Tahun keberangkatan harus diisi"),
      tour_country_id: Yup.number().nullable(),
    })
  })

  const step2Formik = useFormik({
    initialValues: {
      airplane_id: null,
      airplane_in: null,
      airplane_out: null,
      airplane_name: null,
      airplane_embarkation_id: null,
    },
    validationSchema: Yup.object().shape({
      airplane_id: Yup.number().required("Maskapai harus dipilih"),
    }),
  })


  const step3Formik = useFormik({
    initialValues: {
      hotel_makkah_id: null,
      hotel_makkah_number_of_nights: 3,
      hotel_madinah_id: null,
      hotel_madinah_number_of_nights: 3,
      hotel_tour_ids: [],
      hotel_tour_number_of_nights: [],
    },
    validationSchema: Yup.object().shape({
    }),
  });

  const step4Formik = useFormik({
    initialValues: {
      airplane_domestic: false,
      airplane_domestic_id: false,
      airplane_domestic_starting_id: false,
      transportation_highspeed_train: false,
      equipment_additional: false,
      equipment_additional_values: [],
      guide_service: false,
      transportation_bus: false,
      equipment_completed: false,
      tour_city: false,
      tour_city_values: [],
      manasik: false,
      passport: false,
      meningitis: false,
    }
  });

  const step5Formik = useFormik({
    initialValues: {
      branch_fee: 0,
      total_staff: 0,
      total_take_free: 0,
    },
    validationSchema: Yup.object().shape({
      branch_fee: Yup.number().required("FEE harus diisi"),
    }),
  });

  const handleCheckPackage = async () => {
    const payload = {
      ...step1Formik.values,
      ...step2Formik.values,
      ...step3Formik.values,
      ...step4Formik.values,
      ...step5Formik.values,
    }

    setIsCheckingPackage(true)
    const response = await checkPackage(payload)
    setIsCheckingPackage(false)

    if(response.status == 200) {
      setIsCheckedPackage(true)
      setPrices(response.data.data)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleRequestPackage = async () => {
    const payload = {
      ...step1Formik.values,
      ...step2Formik.values,
      ...step3Formik.values,
      ...step4Formik.values,
      ...step5Formik.values,
    }

    setIsCheckedPackage(false)
    setIsRequestingPaket(true)
    const response = await requestPaket(payload)
    setIsRequestingPaket(false)

    if(response.status == 200) {
      dispatch(handleSuccess(response))
      onSuccess()
    } else {
      dispatch(handleError(response.data))
    }

  }

  useEffect(() => {
    step2Formik.setFieldValue("airplane_id", null)
    step3Formik.setFieldValue("hotel_makkah_id", null)
    step3Formik.setFieldValue("hotel_madinah_id", null)
    step1Formik.setFieldValue("tour_country_id", null)
    step2Formik.setFieldValue("airplane_id", null)
    setCountryTourName(null)

    // for(let i = 0; i < step1Formik.values.tour_country_ids.length; i++) {
    //   _tempHotelTourIds.push(null)
    //   _tempHotelTourNights.push(3)
    // }

    // step3Formik.setFieldValue("hotel_tour_ids", _tempHotelTourIds)
    // step3Formik.setFieldValue("hotel_tour_number_of_nights", _tempHotelTourNights)

  }, [step1Formik.values.month_departure, step1Formik.values.year_departure])

  useEffect(() => {
    setIsCheckedPackage(false)
  }, [step1Formik.values, step2Formik.values, step3Formik.values, step4Formik.values, step5Formik.values])

  return (
    <>
      <div className="flex gap-5">
        <div className="border rounded-2xl py-6 px-8">
          <Steppers
            items={stepItems}
            current={currentStep}
            labelPlacement="vertical"
            direction="vertical"
          />
        </div>
        <div className="border rounded-2xl w-full">
          <Scroll 
            height="calc(100vh - 290px)"
          >
            <div className="p-4">
              {currentStep === 0 && (
                <Step1
                  values={step1Formik.values}
                  setFieldValues={step1Formik.setFieldValue}
                  tourCountrySelected={tourCountrySelected}
                  setTourCountrySelected={setTourCountrySelected}
                  hotelTourNights={step3Formik.values.hotel_tour_number_of_nights}
                  setHotelTourNights={(value) => step3Formik.setFieldValue('hotel_tour_number_of_nights', value)}
                  hotelTourIds={step3Formik.values.hotel_tour_ids}
                  setHotelTourIds={(value) => step3Formik.setFieldValue('hotel_tour_ids', value)}
                  countryTourName={countryTourName}
                  setCountryTourName={(value) => setCountryTourName(value)}
                />
              )}
              {currentStep === 1 && (
                <Step2
                  values={step2Formik.values}
                  setFieldValues={step2Formik.setFieldValue}
                  monthDepature={step1Formik.values.month_departure}
                  yearDepature={step1Formik.values.year_departure}
                  setFieldTouched={step1Formik.setFieldTouched}
                  airplaneClass={step1Formik.values.airplane_class}
                />
              )}
              {currentStep === 2 && (
                <Step3
                  values={step3Formik.values}
                  setFieldValues={step3Formik.setFieldValue}
                  monthDepature={step1Formik.values.month_departure}
                  yearDepature={step1Formik.values.year_departure}
                  tourCountryIds={step1Formik.values.tour_country_ids}
                  tourCountryData={tourCountrySelected}
                />
              )}
              {currentStep === 3 && (
                <Step4
                  values={step4Formik.values}
                  setFieldValues={step4Formik.setFieldValue}
                />
              )}
              {currentStep === 4 && (
                <Step5
                  values={step5Formik.values}
                  setFieldValues={step5Formik.setFieldValue}
                  handleCheckPackage={handleCheckPackage}
                  isCheckingPackage={isCheckingPackage}
                  // values={step5Formik.values}
                  // setFieldValues={step5Formik.setFieldValue}
                />
              )}
            </div>
          </Scroll>


          <div className="flex justify-between border-t p-4">
            <div>
              {!isCheckedPackage ? (
                <>
                  <h1 className="text-base font-semibold text-[#141414]">
                    Total
                  </h1>
                  <h1 className="total flex flex-row items-center text-xl font-semibold mt-1 text-green-3">
                    -
                  </h1>
                </>
              ) : (
                <div className="flex items-end gap-5">

                  <div className="flex flex-col gap-1">
                    <div className="font-semibold text-base">Quad</div>
                    <div className="text-green-3 font-semibold text-[20px]">
                      {IDRFormater(prices?.quad_price)}
                      <span className="text-[#141414] font-normal text-sm"> /pax</span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="font-semibold text-xs">Triple</div>
                    <div className="text-green-3 font-semibold text-sm">
                      {IDRFormater(prices?.triple_price)}
                      <span className="text-[#141414] font-normal text-sm"> /pax</span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="font-semibold text-xs">Double</div>
                    <div className="text-green-3 font-semibold text-sm">
                      {IDRFormater(prices?.double_price)}
                      <span className="text-[#141414] font-normal text-sm"> /pax</span>
                    </div>
                  </div>

                </div>
              )}
            </div>
            <div className="flex gap-4">
              {currentStep > 0 && (
                <>
                  <Buttons
                    text="Kembali"
                    customClass="btn-outline btn-large"
                    onClick={() => setCurrentStep(currentStep - 1)}
                  />
                </>
              )}
              {currentStep === 0 && (
                <Buttons
                  text="Selanjutnya"
                  customClass={`btn-primary btn-large`}
                  disabled={!(step1Formik.isValid && step1Formik.dirty)}
                  onClick={() => setCurrentStep(currentStep + 1)}
                />
              )}
              {currentStep === 1 && (
                <Buttons
                  text="Selanjutnya"
                  customClass={`btn-primary btn-large`}
                  disabled={step2Formik.values.airplane_id === null}
                  onClick={() => setCurrentStep(currentStep + 1)}
                />
              )}
              {currentStep === 2 && (
                <Buttons
                  text="Selanjutnya"
                  customClass={`btn-primary btn-large`}
                  // disabled={!(step3Formik.isValid && step3Formik.dirty)}
                  onClick={() => setCurrentStep(currentStep + 1)}
                />
              )}
              {currentStep === 3 && (
                <Buttons
                  text="Selanjutnya"
                  customClass={`btn-primary btn-large`}
                  // disabled={!(step3Formik.isValid && step3Formik.dirty)}
                  onClick={() => setCurrentStep(currentStep + 1)}
                />
              )}
              {currentStep === 4 && (
                <>
                  <Buttons
                    text="Request Paket"
                    customClass={`btn-primary btn-large`}
                    disabled={!isCheckedPackage}
                    loading={isRequestingPaket}
                    // disabled={!(step3Formik.isValid && step3Formik.dirty)}
                    onClick={() => handleRequestPackage()}
                  />
                </>
              )}
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default CheckPackagePopup