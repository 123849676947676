import Buttons from "components/buttons";
import Badge from "components/badge";
import DatePicker from "components/datepicker/DatePicker";
import MoslemeTable from "components/Table";
import RichTextEditor from "components/richTextEditor";
import Places from "components/map";
import { useEffect, useState } from "react";
import "./customStyle.scss";
import Timepickers from "components/timepickers";
import moment from "moment";
import {
  getListManasikPaket,
  saveManasikPaket,
} from "stores/actions/paket/generalInfo";
import { useDispatch } from "react-redux";
import { handleSuccess } from "stores/actions/errorGeneral";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Scroll from "components/scroll";

const columns = [
  {
    title: "Kode Pesan",
    dataIndex: "kode_pesan",
  },
  {
    title: "Keterangan",
    dataIndex: "keterangan",
  },
];

const dataTable = [
  {
    key: "1",
    kode_pesan: "[nama_jamaah]",
    keterangan: "Untuk memanggil nama jamaah",
  },
  {
    key: "2",
    kode_pesan: "[lokasi]",
    keterangan: "Untuk memanggil lokasi manasik",
  },
  {
    key: "3",
    kode_pesan: "[hari_manasik]",
    keterangan: "Untuk memanggil hari manasik",
  },
  {
    key: "4",
    kode_pesan: "[tanggal_manasik]",
    keterangan: "Untuk memanggil tanggal manasik",
  },
  {
    key: "5",
    kode_pesan: "[waktu]",
    keterangan: "Untuk memanggil jam pelaksanaan manasik",
  },
  {
    key: "6",
    kode_pesan: "[link_map]",
    keterangan: "Untuk memanggil link google map lokasi manasik",
  },
  {
    key: "7",
    kode_pesan: "[judul_paket]",
    keterangan: "Untuk memanggil judul paket",
  },
  {
    key: "8",
    kode_pesan: "[hari_keberangkatan]",
    keterangan: "Untuk memanggil hari keberangkatan paket",
  },
  {
    key: "9",
    kode_pesan: "[tanggal_keberangkatan]",
    keterangan: "Untuk memanggil tanggal keberangkatan paket",
  },
  
];

const ManasikForm = ({ data, setIsEdit, handleClickManasikCabang, onChange }) => {
  const [selected, setSelected] = useState(data.koordinat);
  const [address, setAdress] = useState("");
  const [isLoading, setLoading] = useState(false);

  let { id } = useParams();

  const [formManasik, setFormManasik] = useState({
    date: data.date,
    start_at: data.start_at,
    end_at: "23:59",
  });
  const [listManasik, setListManasik] = useState([]);
  const [message, setMessage] = useState({ value: null });
  const handleSetAdress = (data) => {
    setAdress(data);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object?.keys(data)?.length > 0) {
      setFormManasik({
        ...formManasik,
        date: data.date,
        start_at: data.start_at ?? "00:00"
      });

      setMessage({
        ...message,
        value: data.message,
      });

      setAdress(data.lokasi);
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      getListManasikPaket(id, (message) => {
        setListManasik(message.data);
      })
    );
  }, [dispatch, id]);

  const handleChangeForm = (val, key) => {
    setFormManasik({
      ...formManasik,
      [key]: val,
    });
  };

  const handleSelected = (data) => {
    setSelected(data);
  };

  const handleSaveManasik = () => {
    setLoading(true);
    let payload = {};

    if (data.id_manasik) {
      payload = {
        ...formManasik,
        lotitude: selected?.lat,
        langitude: selected?.lng,
        cabang_id: data.cabang_id,
        lokasi: address,
        paket_id: id,
        message: message.value,
        id: data.id_manasik,
      };
    } else {
      payload = {
        ...formManasik,
        lotitude: selected?.lat,
        langitude: selected?.lng,
        cabang_id: data.cabang_id,
        lokasi: address,
        paket_id: id,
        message: message.value,
      };
    }

    dispatch(
      saveManasikPaket(payload, (message) => {
        dispatch(
          getListManasikPaket(id, (resp) => {
            setListManasik(resp.data);
          })
        );
        setIsEdit(false);
        // handleClickManasikCabang(data.cabang_id);
        setLoading(false);
        dispatch(handleSuccess(null, { message: `Berhasil ${data.id_manasik ? `memperbarui` : `menambahkan`} manasik`, code: 200 }));
        onChange(data.id_manasik ? "edit" : "add")
      })
    );
  };
  return (
    <>
      {/* <div className="w-full flex">
        <Buttons
          text="Simpan"
          loading={isLoading}
          customClass={`btn-primary btn-sm !w-fit float-right `}
          onClick={handleSaveManasik}
        />
      </div> */}
      <div className="border rounded-2xl">
        
        <Scroll height="calc(100vh - 300px)" customClass="p-4">
          <div className="w-full flex justify-between gap-8">
            <div className="">
              <Buttons
                text="Set Koordinat"
                customClass={`btn-outline !border-dashed !w-fit `}
              />
              <div className="flex flex-col gap-4 mt-4">
                <div className="">
                  <h2 className="font-semibold mb-2 text-sm">Tanggal Pelaksanaan</h2>
                  <DatePicker
                    id="date"
                    placeholder="Pilih Tanggal"
                    // onChange={onDatePickerChange}
                    containerClass="mt-2"
                    customClass="w-full"
                    formatManual
                    value={formManasik?.date ? dayjs(moment(formManasik?.date).format("YYYY-MM-DD"), "YYYY-MM-DD") : null}
                    onChange={(val) => {
                      handleChangeForm(val, 'date')
                    }}
                    />
                </div>
                <div className="">
                  <h2 className="font-semibold mb-2 text-sm">Waktu Pelaksanaan</h2>
                  <Timepickers
                    value={formManasik?.start_at ? dayjs(formManasik?.start_at, "HH:mm") : null}
                    placeholder="Pilih Jam"
                    containerClass="relative"
                    onChange={(e) => {
                      let data = moment(e?.$d).format("HH:mm");
                      handleChangeForm(data, "start_at");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="rounded-lg overflow-hidden shadow-soft">
                <Places
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "243px",
                  }}
                  selected={selected}
                  setSelected={handleSelected}
                  address={address}
                  setAdress={handleSetAdress}
                />
              </div>
            </div>
          </div>
          <div className="border rounded-lg mt-5">
            <MoslemeTable
              columns={columns}
              data={dataTable}
              withSelection={false}
              withPagination={false}
            />
          </div>
          <div className="mt-5">
            <RichTextEditor
              label="Pesan Undangan"
              className="text-editor-grey rounded-md overflow-hidden"
              data={message}
              onChange={(value) => setMessage({ value })}
              placeholder="Masukkan pesan undangan"
            />
          </div>
        </Scroll>
        
        <div className="border-t p-4 flex justify-end items-center">
          <Buttons
            text="Simpan"
            loading={isLoading}
            customClass={`btn-primary btn-large`}
            onClick={handleSaveManasik}
          /> 
        </div>
        
      </div>
    </>
  );
};

export default ManasikForm;
