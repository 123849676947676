import { useEffect, useState } from "react"

import AntdSelect from "components/form/antdSelect"
import Buttons from "components/buttons"
import InputsCurrency from "components/form/inputsCurrency"



const Step5 = ({
  values,
  setFieldValues,
  handleCheckPackage,
  isCheckingPackage
}) => {

  const [totalOptions, setTotalOptions] = useState([])

  const handleSetTotalOptions = () => {
    let _tempOptions = []

    for(let i = 0;i <= 50; i++) {
      _tempOptions.push({
        value: i,
        label: i
      })
    }

    setTotalOptions(_tempOptions)
  }

  useEffect(() => {
    handleSetTotalOptions()
  }, [])

  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="w-full">
          <InputsCurrency 
            label="Nominal Fee"
            value={values.branch_fee}
            onChange={(e) => {
              setFieldValues('branch_fee', e)
            }}
            />
        </div>
        <div className="w-full">
          <AntdSelect 
            label={"Pembimbing/Petugas/Pendamping"}
            placeholder={"Pilih Pembimbing/Petugas/Pendamping"}
            showSearch={true}
            options={totalOptions}
            value={values.total_staff}
            allowClear={true}
            onChange={(dataSelected) => {
              setFieldValues('total_staff', dataSelected.value)
            }}
            />
        </div>
        <div className="w-full">
          <AntdSelect 
            label={"Ambil Free"}
            placeholder={"Pilih Ambil Free"}
            showSearch={true}
            options={totalOptions}
            value={values.total_take_free}
            allowClear={true}
            onChange={(dataSelected) => {
              setFieldValues('total_take_free', dataSelected.value)
            }}
            />
        </div>
      </div>
      
      <div className="mt-4 inline-block">
        <Buttons 
          customClass="btn-secondary btn-medium"
          text="Cek Paket"
          onClick={() => handleCheckPackage()}
          loading={isCheckingPackage}
          />
      </div>
    </div>
  )
}

export default Step5