import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import OperationalAirplaneDomesticForm from "pages/calculator/components/priceUpdate/components/OperationalAirplaneDomesticForm";

const PriceUpdateAPI = {
    
    // MASKAPAI
    maskapaiGetList: () =>
        axios.get(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-maskapai`),
    maskapaiSave: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/save-maskapai`, payload),
    maskapaiRemove: (maskapaiID) => 
        axios.delete(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/delete-maskapai/${maskapaiID}`),
    maskapaiGetPrice: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-harga-maskapai`, payload),
        

    
    // HOTEL
    akomodasiGetAll: () =>
        axios.get(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-akomodasi`),
    akomodasiSave: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/save-akomodasi`, payload),
    akomodasiRemove: (akomodasiID) => 
        axios.delete(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/delete-akomodasi/${akomodasiID}`),
    akomodasiGetPrice: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-harga-akomodasi`, payload),


    operationalGet: () =>
        axios.get(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-operasional`),
    operationalSave: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/save-operasional`, payload),
    operationalGetAirplaneDomesticPrice: (payload) =>
        axios.get(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-airplane-domestic-price`, { params: payload }),

    cityToursGet: () =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-city-tour`),
    cityToursSave: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/save-city-tour`, payload),
    cityToursUpdate: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/update-city-tour`, payload),
    cityTourRemove: (cityTourID) => 
        axios.delete(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/delete-city-tour/${cityTourID}`),


    countryToursGet: () =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-tour`),
    countryToursGetByNameTime: (params) =>
        axios.get(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-tour-by-name-time`, { params }),
    countryToursSave: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/save-tour`, payload),
    countryToursUpdate: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/update-tour`, payload),
    countryToursRemove: (name) => 
        axios.delete(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/delete-tour`, {data: {name}}),
    
    countryToursAkomodasiGetAll: () =>
        axios.get(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-tour-akomodasi`),
    countryToursAkomodasiSave: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/save-tour-akomodasi`, payload),
    countryToursAkomodasiRemove: (payload) => 
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/delete-tour-akomodasi`, payload),
    countryToursAkomodasiGetPrice: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-harga-tour-akomodasi`, payload),

}

export { PriceUpdateAPI }