

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Choice from "components/Choice"

import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import { getDataMaskapai } from "utils/api/requestPaket";

import { handleError } from "stores/actions/errorGeneral";
import { Skeleton } from "antd";
import Badge from "components/badge";

const Step2 = ({
  values,
  setFieldValues,
  monthDepature,
  yearDepature,
  airplaneClass
}) => {

  const dispatch = useDispatch()

  const [airplaneOptions, setAirplaneOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const getDataAllAirplane = async () => {
    setIsLoading(true)
      const response = await getDataMaskapai({ month: monthDepature, year: yearDepature });

      if (response.data.code === 200) {
        setAirplaneOptions(response.data.data);
      } else {
        dispatch(handleError(response.data))
      }

      setIsLoading(false)
  };

  useEffect(() => {
    getDataAllAirplane();
  }, []);

  return ( 
    <>
      <div className="font-semibold font-base">Pilih Maskapai</div>
      <div className="w-full grid grid-cols-2 gap-4 mt-4">
        {isLoading ? (
          <>
            {[...Array(6)].map((_, index) => (
              <Skeleton.Input key={index} active={true} size="large" block={true} />
            ))}
          </>
        ) : (
          <>
            {airplaneOptions.map((item, index) => (
              <div className="w-full" key={index}>
                <Choice 
                  key={2}
                  type="radio"
                  name={`airplane`}
                  customClass={"!items-start"}
                  value={item.id}
                  checked={values.airplane_name == item.maskapai_name}
                  id={`airplane-${index}`}
                  onChange={() => {
                    setFieldValues('airplane_name', item.maskapai_name)
                    setFieldValues('airplane_embarkation_id', null)
                    setFieldValues('airplane_id', null)
                    setFieldValues('airplane_in', null)
                    setFieldValues('airplane_out', null)
                  }}
                >
                  <>
                    <div>
                      <div className="font-semibold font-base">{item.maskapai_name}</div>
                    </div>
                    {values.airplane_name === item.maskapai_name && (
                      <>
                        <div className="flex flex-col gap-4 mt-4">
                          {item.data.map((airplaneData, dataIndex) => (
                            <>
                              <Choice 
                                key={dataIndex}
                                type="radio"
                                name={`embarkasi`}
                                customClass={"!items-start !border-0 !p-0 !items-start"}
                                value={item.id}
                                checked={values.airplane_embarkation_id == airplaneData.emberkation_id}
                                id={`embarkasi-${dataIndex}`}
                                onChange={() => {
                                  setFieldValues('airplane_embarkation_id', airplaneData.emberkation_id)
                                  setFieldValues('airplane_id', null)
                                  setFieldValues('airplane_in', null)
                                  setFieldValues('airplane_out', null)
                                }}
                              >
                                <div>
                                  <div className="font-semibold font-base flex gap-4 items-center">
                                    <div>Embarkasi</div>
                                    <Badge 
                                      label={airplaneData.emberkation?.name}
                                      color={airplaneData.emberkation?.color_text}
                                      backgroundColor={airplaneData.emberkation?.color}
                                      />
                                  </div>
                                  {values.airplane_embarkation_id == airplaneData.emberkation_id && (
                                    <>
                                      <div className="w-full flex flex-col gap-4 mt-4">
                                          {airplaneData[`${airplaneClass}_price_jeddah_jeddah`] > 0 && (
                                            <>
                                              <Choice 
                                                key={0}
                                                type="radio"
                                                name={`airplane-in-out`}
                                                label={
                                                  <>
                                                    <span className="text-green-3">In</span> Bandara Jeddah, <span className="text-red-3">Out</span> Bandara Jeddah
                                                  </>
                                                }
                                                customClass={"!border-0 !p-0 !items-start"}
                                                // onClick={() => e.stopPropagation()}
                                                // value={6}
                                                checked={(values.airplane_in === "Jeddah" && values.airplane_out === "Jeddah")}
                                                id={`airplane-in-out-0`}
                                                onChange={() => {
                                                  setFieldValues('airplane_id', airplaneData.maskapai_id)
                                                  setFieldValues('airplane_in', "Jeddah")
                                                  setFieldValues('airplane_out', "Jeddah")
                                                }}
                                              />
                                            </>
                                          )}
                                          {airplaneData[`${airplaneClass}_price_madinah_jeddah`] > 0 && (
                                            <>
                                              <Choice 
                                                key={1}
                                                type="radio"
                                                name={`airplane-in-out`}
                                                label={
                                                  <>
                                                    <span className="text-green-3">In</span> Bandara Madinah, <span className="text-red-3">Out</span> Bandara Jeddah
                                                  </>
                                                }
                                                customClass={"!border-0 !p-0 !items-start"}
                                                // onClick={() => e.stopPropagation()}
                                                // value={6}
                                                checked={(values.airplane_in === "Madinah") && (values.airplane_out === "Jeddah")}
                                                id={`airplane-in-out-1`}
                                                onChange={() => {
                                                  setFieldValues('airplane_id', airplaneData.maskapai_id)
                                                  setFieldValues('airplane_in', "Madinah")
                                                  setFieldValues('airplane_out', "Jeddah")
                                                }}
                                              />
                                            </>
                                          )}
                                          {airplaneData[`${airplaneClass}_price_jeddah_madinah`] > 0 && (
                                            <>
                                              <Choice 
                                                key={2}
                                                type="radio"
                                                name={`airplane-in-out`}
                                                label={
                                                  <>
                                                    <span className="text-green-3">In</span> Bandara Jeddah, <span className="text-red-3">Out</span> Bandara Madinah
                                                  </>
                                                }
                                                customClass={"!border-0 !p-0 !items-start"}
                                                // onClick={() => e.stopPropagation()}
                                                // value={6}
                                                checked={(values.airplane_in === "Jeddah") && (values.airplane_out === "Madinah")}
                                                id={`airplane-in-out-2`}
                                                onChange={() => {
                                                  setFieldValues('airplane_id', airplaneData.maskapai_id)
                                                  setFieldValues('airplane_in', "Jeddah")
                                                  setFieldValues('airplane_out', "Madinah")
                                                }}
                                              />
                                            </>
                                          )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Choice>
                            </>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                </Choice>
              </div>
            ))}
          </>
        )}
      </div>
      {(airplaneOptions.length === 0 && !isLoading) && (
        <div className="flex flex-col justify-center items-center w-full h-[320px]">
          <img src={IconJamPasir} alt="jam_pasir" width="150px" />
          <div className="text-[#141414] text-[14px] font-normal text-center font-sans mt-2 whitespace-pre-line w-64">
            Di Bulan & Tahun yang Anda tentukan belum ada Maskapai yang tersedia
          </div>
        </div>
      )}
    </>
  )
}

export default Step2