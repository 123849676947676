import Badge from "components/badge";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import { getMasterId } from "stores/actions/datamaster";

const rupiah = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number);
};

export const columns = (dispatch, permissions) => [
  {
    title: "Nama Starting",
    dataIndex: "name",
    render: (name, data) => (
      <div
        className="text-sm"
        // style={{ color: data?.color === "#fff" ? "#111" : data?.color }}
      >
        <h1 className={`text-xs font-normal text-[#141414]`}>{name}</h1>
      </div>
    ),
  },
  {
    title: "Berangkat",
    dataIndex: "_bandara_departure",
    render: (_bandara_departure, record) => (
      <div className={`flex gap-x-4 items-center uppercase`}>
        <Badge
          label={_bandara_departure?.city}
          backgroundColor={record.color_bg}
          color={record.color}
        />
      </div>
    ),
  },
  {
    title: "Pulang",
    dataIndex: "_bandara_return",
    render: (_bandara_return, record) => (
      <div className={`flex gap-x-4 items-center uppercase`}>
        <Badge
          label={_bandara_return?.city}
          backgroundColor={record.color_bg}
          color={record.color}
        />
      </div>
    ),
  },
  {
    title: "Single",
    dataIndex: "price_single",
    render: (price_single) => (
      <div className="text-sm">{rupiah(price_single)}</div>
    ),
  },
  {
    title: "Double",
    dataIndex: "price_double",
    render: (price_double) => (
      <div className="text-sm">{rupiah(price_double)}</div>
    ),
  },
  {
    title: "Tripple",
    dataIndex: "price_tripple",
    render: (price_tripple) => (
      <div className="text-sm">{rupiah(price_tripple)}</div>
    ),
  },
  {
    title: "Quad",
    dataIndex: "price_quad",
    render: (price_quad) => <div className="text-sm">{rupiah(price_quad)}</div>,
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    render: (s, data) => (
      <div className="w-full">
        <div className={`flex gap-x-4 items-center`}>
          {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}
        </div>
        <div className="action">
          <div className="action-content">
            {permissions?.includes("delete_master-data") && (
              <div className="action-content-item">
                <Trash
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ modal: true, data: data }))
                  }
                  size="16"
                  color="#141414"
                />
              </div>
            )}
            {permissions?.includes("create_master-data") && (
              <div className="action-content-item">
                <DocumentCopy
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ popup: true, data: data }))
                  }
                  size="16"
                  color="#141414"
                />
              </div>
            )}
            {permissions?.includes("update_master-data") && (
              <div className="action-content-item">
                <Edit
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(getMasterId({ modalEdit: true, data: data }))
                  }
                  size="15"
                  color="#141414"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  },
];
