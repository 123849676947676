import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateManifestAPI } from 'utils/api/booking'
import { handleError, handleSuccess } from 'stores/actions/errorGeneral'
import { handleUpdatePembimbingManfiest } from 'utils/api/paket/manifest'

import Inputs from 'components/form/inputs'
import Selects from 'components/form/selects'
import DatePicker from 'components/datepicker/DatePicker'

import moment from 'moment'
import dayjs from "dayjs";
import Textarea from 'components/form/textarea'
import { toPermalink } from 'utils/helpers'
import DocumentRecipient from 'components/Manifest/DocumentRecipient'

const Passport = ({
    data,
    pembimbingPaketID,
    onSuccess,
    auth,
    permissions,
    mentorData
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            data
        }
    })
    const [isLoading, setIsLoading] = useState(false)

    const [documentRecipientLock, setDocumentRecipientLock] = useState(false)

    const handleSave = async () => {
        // console.log(formik.values);
        setIsLoading(true)
        const response = await handleUpdatePembimbingManfiest(params.id, pembimbingPaketID,formik.values)
        setIsLoading(false)
        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data manifest", code: 200 }))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }

        if(formik.values.data?.passport_document_recipient != null) {
            setDocumentRecipientLock(true)
        }

    }

    useEffect(() => {
        formik.setValues({
            data
        })

    }, [data])



    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <DocumentRecipient 
                    value={formik.values?.data?.passport_document_recipient}
                    onChange={(value) => {
                        formik.setFieldValue('data.passport_document_recipient', value)
                    }}
                    locked={documentRecipientLock}
                    />
                <div className='flex gap-4'>
                    <div className='w-1/2'>
                        <UploadArea
                            viewOnly={!permissions.includes('update_manifest') || auth.user.access_data !== 'all_branches'}
                            type='chose'
                            label="Dokumen Scan Passport"
                            customClass="w-full"
                            defaultUrl={formik.values?.data?.passport_1}
                            onChange={(url) => formik.setFieldValue('data.passport_1', url)}
                            handleRemove={() => formik.setFieldValue('data.passport_1', null)}
                            customName={`passport_1-pembimbing-${pembimbingPaketID}-${toPermalink(mentorData?.name)}`}
                            />
                        <Inputs 
                            disabled={!permissions.includes('update_manifest') || auth.user.access_data !== 'all_branches'}
                            name='data.passport_name'
                            id='data.passport_name'
                            label='No Passport'
                            placeholder='No Passport'
                            containerClass='my-4'
                            value={formik.values?.data?.passport_no}
                            onChange={(e) => formik.setFieldValue('data.passport_no', e.target.value)}
                            customName={`passport_2-pembimbing-${pembimbingPaketID}-${toPermalink(mentorData?.name)}`}
                            />
                        <div className='mb-4'>   
                            <div className='text-sm font-semibold mb-2'>Issue</div>
                            <DatePicker 
                                disabled={!permissions.includes('update_manifest') || auth.user.access_data !== 'all_branches'}
                                customClass='w-full'
                                value={formik.values?.data?.passport_issue ? dayjs(moment(formik.values?.data?.passport_issue).format("YYYY-MM-DD"), "YYYY-MM-DD") : dayjs(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
                                onChange={(val) => {
                                    formik.setFieldValue('data.passport_issue', moment(val, "DD-MM-YYYY").format("YYYY-MM-DD"))
                                }}
                                />
                        </div>
                        <Inputs 
                            disabled={!permissions.includes('update_manifest') || auth.user.access_data !== 'all_branches'}
                            name='data.passport_name'
                            id='data.passport_name'
                            label='Passport Office'
                            placeholder='Office Passport'
                            containerClass='my-4'
                            value={formik.values?.data?.passport_office}
                            onChange={(e) => formik.setFieldValue('data.passport_office', e.target.value)}
                            />
                    </div>
                    <div className='w-1/2'>
                        <UploadArea
                            viewOnly={!permissions.includes('update_manifest') || auth.user.access_data !== 'all_branches'}
                            type='chose'
                            label="Dokumen Scan Passport"
                            customClass="w-full"
                            defaultUrl={formik.values?.data?.passport_2}
                            onChange={(url) => formik.setFieldValue('data.passport_2', url)}
                            handleRemove={() => formik.setFieldValue('data.passport_2', null)}
                            />
                        
                        <Inputs 
                            disabled={!permissions.includes('update_manifest') || auth.user.access_data !== 'all_branches'}
                            name='data.passport_name'
                            id='data.passport_name'
                            label='Nama sesuai Passport'
                            placeholder='Nama Sesuai Passport'
                            containerClass='my-4'
                            value={formik.values?.data?.passport_name}
                            onChange={(e) => formik.setFieldValue('data.passport_name', e.target.value)}
                            />
                        <div className=''>   
                            <div className='text-sm font-semibold mb-2'>Expired</div>
                            <DatePicker 
                                disabled={!permissions.includes('update_manifest') || auth.user.access_data !== 'all_branches'}
                                customClass='w-full'
                                value={formik.values?.data?.passport_expire ? dayjs(moment(formik.values?.data?.passport_expire).format("YYYY-MM-DD"), "YYYY-MM-DD") : dayjs(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
                                onChange={(val) => {
                                    formik.setFieldValue('data.passport_expire', moment(val, "DD-MM-YYYY").format("YYYY-MM-DD"))
                                }}
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            {permissions.includes('update_manifest') && auth.user.access_data === 'all_branches' && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default Passport