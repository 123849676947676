/* eslint-disable jsx-a11y/img-redundant-alt */

import InputsCurrency from "components/form/inputsCurrency";
import Edit from "../../../../../assets/icons/colored/Edit.svg";
import Eye from "../../../../../assets/icons/colored/Eye.svg";
import Button from "../../../../../components/buttons";
import Switch from "../../../../../components/form/switch/";
import "../Fee/styles/Fee.scss";

import { useSelector } from 'react-redux'

const RoomPriceCard = ({
  data,
  title,
  img,
  checked,
  total = 0,
  onHandleEdit,
  onHandleEditBusiness,
  priceAdditional = 0,
  isPenambahan = false,
  price = 0,
  priceBusiness = 0,
  input,
  inputBusiness,
  isLoading = false,
  priceAdditionalBusiness = 0,
  totalBussines = 0,
  disabled,
  handleChangeTipeDasar = (data, value) => {},
  handleSwitch = (data) => {},
  handleActionSaveData = (data) => {},
  handleChangeTipeBusiness = (data, value) => {},
  onHandleView = (data) => {},
}) => {
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  const moneyFormatter = (val) => {
    return new Intl.NumberFormat("id-ID").format(val);
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="p-4 bg-[#FFDCDC] border-b w-full ">
        <div className="flex justify-between">
          <div className="flex gap-2 font-extrabold capitalize">
            <img src={img} alt="room type image" />
            {title}
          </div>
          <img
            src={Eye}
            alt="Eye Icon"
            className="cursor-pointer"
            onClick={() => {
              onHandleView(data);
            }}
          />
        </div>
        <div
          className={`flex ${
            isPenambahan ? "justify-between" : "justify-end"
          } items-center  mt-2`}
        >
          {isPenambahan && (
            <>
              <div className="text-sm">
                Total{" "}
                <b className="!text-[#141414] font-semibold">
                  Rp. {moneyFormatter(total)}
                </b>
                /pax
              </div>
            </>
          )}

          <Switch
            checked={checked === 1 ? true : false}
            disabled={disabled}
            onChange={(e) => {
              handleSwitch(data);
            }}
          />
        </div>
        <div
          className={`flex ${
            isPenambahan ? "justify-between" : "justify-end"
          } items-center  mt-2`}
        >
          {isPenambahan && (
            <>
              <div className="text-sm">
                Total Bussines Class{" "}
                <b className="!text-[#141414] font-semibold">
                  Rp. {moneyFormatter(totalBussines)}
                </b>
                /pax
              </div>
            </>
          )}

          <div></div>
        </div>
      </div>
      <div className="px-4 py-5 bg-white border-b flex justify-between w-full">
        {input ? (
          <div className="relative w-full">
            {isPenambahan ? (
              <InputsCurrency
                placeholder="Masukkan harga..."
                value={priceAdditional}
                onChange={(e) => {
                  handleChangeTipeDasar(data, e);
                }}
              />
            ) : (
              <InputsCurrency
                placeholder="Masukkan harga..."
                value={price}
                onChange={(e) => {
                  handleChangeTipeDasar(data, e);
                }}
              />
            )}

            <Button
              text="Simpan"
              loading={isLoading}
              customClass="btn-primary !w-fit !font-extrabold absolute top-2 right-2 btn-small"
              onClick={() => handleActionSaveData(data)}
            />
          </div>
        ) : (
          <>
            <div className="text-xl">
              {isPenambahan ? (
                <b>+ {moneyFormatter(priceAdditional)}</b>
              ) : (
                <>
                  <b>Rp. {moneyFormatter(price || 0)}</b>
                  <span>/pax</span>
                </>
              )}
            </div>
            {auth?.user?.access_data === 'all_branches' && (
              <>
                {permissions?.includes('update_package') && (
                  <>
                    <img
                      src={Edit}
                      alt="edit icon"
                      className="cursor-pointer"
                      onClick={() => onHandleEdit(data)}
                    />
                  </>
                )}
              </>
            )}
            
          </>
        )}
      </div>

      {inputBusiness ? (
        <div className="relative w-full">
          {isPenambahan ? (
            <div className="mx-5 my-3">
              <InputsCurrency
                placeholder="Masukkan harga..."
                value={priceAdditionalBusiness}
                onChange={(e) => handleChangeTipeBusiness(data, e)}
              />
            </div>
          ) : (
            <div className="mx-5 my-3">
              <InputsCurrency
                placeholder="Masukkan harga..."
                value={priceBusiness}
                onChange={(e) => handleChangeTipeBusiness(data, e)}
              />
            </div>
          )}

          <Button
            text="Simpan"
            loading={isLoading}
            customClass={`btn-primary !w-fit !font-extrabold absolute ${
              inputBusiness ? "top-2 right-6" : "top-2 right-2"
            } btn-small`}
            onClick={() => handleActionSaveData(data)}
          />
        </div>
      ) : (
        <div className="px-4 py-5 bg-white flex justify-between w-full relative">
          <div className="bg-[#4590F43D] text-[#4590F4] text-[12px] font-normal  px-2 py-[2px] rounded-br-xl absolute left-0 top-0">
            Bussines Class
          </div>
          <div className="text-xl">
            {isPenambahan ? (
              <>
                <b>+{moneyFormatter(priceAdditionalBusiness)} </b>
                <span>/pax</span>
              </>
            ) : (
              <>
                <b>Rp. {moneyFormatter(priceBusiness || 0)}</b>
                <span>/pax</span>
              </>
            )}
          </div>
          {auth?.user?.access_data === 'all_branches' && (
            <>
              {permissions?.includes('update_package') && (
                <img
                  src={Edit}
                  alt="edit icon"
                  className="cursor-pointer"
                  onClick={() => onHandleEditBusiness(data)}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

RoomPriceCard.defaultProps = {
  disabled: false,
}

export default RoomPriceCard;
