import DropdownCustom from "components/DropdownCustom"
import PropTypes from "prop-types";

import IconFilter from "assets/icons/filter.svg"
import IconCloseBlack from "assets/icons/close-black.svg"

import FilterForm from "./partials/FilterForm"

import { useEffect, useState } from "react";

const Filter = ({
  type,
  options,
  data,
  onChange
}) => {

  const [values, setValues] = useState({})
  const [allValues, setAllValues] = useState([])

  const handleSelectFilter = (dataValues) => {

    const _combinedAllValues = [].concat(...Object.values(dataValues));

    setValues(dataValues)
    onChange(dataValues)

    setAllValues(_combinedAllValues)

  }
  
  const handleReset = (e) => {
    e.stopPropagation()
    setValues({})
    setAllValues([])
    onChange({})
  }

  return (
    <div>
      <DropdownCustom
        placement="rightBottom"
        icon={IconFilter}
        label="Filter"
        iconPosition="left"
        dropdownRender={() => (
          <>
            {type == "options" && (
              <>
                <FilterForm 
                  options={options}
                  data={data}
                  onChange={(dataValues) => handleSelectFilter(dataValues)}
                  values={values}
                  />
              </>
            )}
          </>
        )}
      >
        {allValues.length > 0 && (
          <>
            <div 
              className="border py-[10px] px-3 rounded-lg cursor-pointer flex justify-between items-center">
              <div className="flex items-center gap-2">
                <div className="text-white bg-red-3 w-5 h-5 rounded-full flex justify-center items-center">
                  {allValues.length}
                </div>
                <div>
                  Filter
                </div>
              </div>
              <div 
                onClick={(e) => handleReset(e)}
                className="cursor-pointer ml-2 hover:bg-gray-5 w-6 h-6 rounded-full transition-all duration-300">
                <img src={IconCloseBlack} />
              </div>
            </div>
          </>
        )}
      </DropdownCustom>
    </div>
  )
}

Filter.protoTypes  = {
  type: PropTypes.string,
  options: PropTypes.array,
  data: PropTypes.object
}

Filter.defaultProp = {
  type: "", // options
  options: [], // key: label, key, type, customKey
  data: {}
}

export default Filter