import Buttons from "components/buttons"
import AntdSelect from "components/form/antdSelect";
import InputsCurrency from "components/form/inputsCurrency";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AlertPopup from "components/popup/alert";

import {
  getAllDataMaskapai,
} from "stores/actions/datamaster";
import {getMasterStarting} from "stores/actions/datamaster/starting"

import IconTrash from "assets/icons/trash-maroon.svg";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";
import { handleError } from "stores/actions/errorGeneral";
import Inputs from "components/form/inputs";

const OperationalAirplaneDomesticForm = ({
  data,
  onChange
}) => {
  
  const dispatch = useDispatch();

  const [airlaneOptions, setAirlaneOptions] = useState([])
  const [startingOptions, setStartingOptions] = useState([])

  const masterDataStore = useSelector(({ datamaster }) => datamaster);
  const masterDataStartingStore = useSelector(({ adminStarting }) => adminStarting)

  const [removeConfirmShow, setRemoveConfirmShow] = useState(false)
  const [airplaneSelectedIndex, setAirplaneSelectedIndex] = useState(null)

  const [airplaneDomestics, setAirplaneDomestics] = useState([])

  const handleGetMaskapai = () => {
    dispatch(
      getAllDataMaskapai({
          per_page: 1000,
          page_number: 1,
          sort_column: "name",
          sort_order: "",
          keyword: "",
      })
    );
  }

  const handleGetStarting = () => {
    dispatch(getMasterStarting(
      getAllDataMaskapai({
        per_page: 1000,
        page_number: 1,
        sort_column: "name",
        sort_order: "",
        keyword: "",
    })
    ))
  }

  const handleSetStartingOptions = (checklistStartings = []) => {

    return masterDataStartingStore?.allStarting?.data?.map((item) => ({
        value: item.id_starting,
        label: item.name,
        check: checklistStartings.includes(item.id_starting),
        data: {
          bgColor: item.color_bg,
          textColor: item.color
        }
    }));
};


  const handleChangeData = (key, value, index) => {
    let _tempData = data
    _tempData[index][key] = value

    onChange(_tempData)
  }

  const handleSetAirplaneOptions = () => {
    let masterMaskapaisTemp = [];
  
    // Gunakan forEach jika tidak ingin return array baru (map bukan pilihan tepat di sini)
    masterDataStore?.dataMaskapai?.data?.forEach((val) => {
      // Ubah kondisi 'some' agar pengecekannya benar
      if (!data.some(item => item.airplane_id === val.id_maskapai)) {
        masterMaskapaisTemp.push({
          id: val.id_maskapai,
          desc: val.code,
          img: val.logo,
          value: val.id_maskapai,
          label: val.name,
        });
      }
    });
  
    setAirlaneOptions(masterMaskapaisTemp);
  }
  

  useEffect(() => {
    if(masterDataStartingStore?.allStarting?.data?.length > 0) {
      const _tempStartingOptions = masterDataStartingStore.allStarting.data.map((item) => {
        return {
          value: item.id_starting,
          label: item.name,
          data: {
            bgColor: item.color_bg,
            textColor: item.color
          }
        }
      })

      setStartingOptions(_tempStartingOptions)
    }
  }, [masterDataStartingStore.allStarting])

  const handleRemoveItemConfirm = (index) => {
    setRemoveConfirmShow(true)
    setAirplaneSelectedIndex(index)
  }

  const handleRemoveData = () => {
    let _tempData = data
    _tempData.splice(airplaneSelectedIndex, 1)
    setRemoveConfirmShow(false)
  }

  const handleAddData = () => {
    let _tempData = data
    _tempData.push({
      airplane_id: null,
      starting_id: null,
      price: 0,
      new: true
    })

    onChange(_tempData)
  }

  const handleGetPrice = async (airplaneId, startingId, index) => {
    const params = {
      airplane_id: airplaneId, 
      starting_id: startingId
    }

    const response = await PriceUpdateAPI.operationalGetAirplaneDomesticPrice(params)

    if (response.status === 200) {
      handleChangeData("price", response.data.data.price, index)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleParseAirplaneDomestic = () => {
    let _tempAirplaneDomestics = []
    data?.forEach((item, index) => {
      _tempAirplaneDomestics.push({
        airplane_id: item.airplane_id,
        starting_id: item.starting_id,
        price: item.price,
        new: false
      })
    })

    setAirplaneDomestics(_tempAirplaneDomestics)
  }

  useEffect(() => {
    handleParseAirplaneDomestic()
  }, [data])

  useEffect(() => {
    handleSetAirplaneOptions()
  }, [airplaneDomestics])

  useEffect(() => {
    handleGetMaskapai()
    handleGetStarting()
  }, [])

  return (
    <>
      <AlertPopup
        open={removeConfirmShow}
        handleClose={() => setRemoveConfirmShow(false)}
        handleContinue={() => handleRemoveData()}
        subtitle="Apakah anda yakin menghapus data ini?"
      />
    <div className="border p-4 rounded-2xl">

        <div className="w-full flex justify-between items-center">
          <div className="font-semibold">
            Pesawat Domestik
          </div>
          <Buttons
            text="Tambah Maskapai"
            customClass="btn-primary btn-small"
            onClick={handleAddData}
            />
        </div>

        <div className="flex flex-col gap-4 mt-4">

          {data?.map((item, index) => (
            <>
              <div className="w-full flex items-center gap-4" key={index}>
                <div className="w-full flex gap-4 items-center">
                  <div className="w-full">
                    {item.new ? (
                      <>
                        <AntdSelect
                            showSearch={true}
                            label="Maskapai"
                            placeholder="Pilih maskapai"
                            type="secondary"
                            value={item.airplane_id}
                            options={airlaneOptions}
                            onChange={(dataSelected) => {
                              handleChangeData('airplane_id', dataSelected.value, index)
                            }}
                        />
                      </>
                    ) : (
                      <>
                        <Inputs
                          label={'Maskapai'}
                          readonly
                          value={masterDataStore?.dataMaskapai?.data?.find((val) => val.id_maskapai === item.airplane_id)?.name}
                          // disabled={isGetPriceLoading ? actionIndex === index : false}
                      />
                      </>
                    )}
                  </div>
                  <div className="w-full">
                    <AntdSelect
                        showSearch={true}
                        label="Starting"
                        placeholder="Pilih starting"
                        type="badge-check"
                        value={item.starting_id}
                        options={handleSetStartingOptions(item.starting_exists)}
                        onChange={(dataSelected) => {
                          handleChangeData('starting_id', dataSelected.value, index)
                          handleGetPrice(item.airplane_id, dataSelected.value, index)
                        }}
                    />
                  </div>
                  <div className="w-full">
                    <InputsCurrency
                      label={"Harga"}
                      value={item.price}
                      placeholder="Masukan harga"
                      onChange={(value) => {
                        handleChangeData('price', value, index)
                      }}
                    />
                  </div>
                </div>
                {index !== 0 && (
                  <>
                    <div 
                      onClick={() => handleRemoveItemConfirm(index)}
                      className="cursor-pointer mt-[25px]"
                      >
                      <img className="w-6 h-6" src={IconTrash} alt="trash"/>
                    </div>
                  </>
                )}
              </div>
            </>
          ))}
        </div>

      </div>
    </>
  )

}

export default OperationalAirplaneDomesticForm