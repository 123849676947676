import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import Sliders from "components/slider";
import GeneralInfo from "./components/Generalinfo";
import TipeKamar from "./pages/tipe_kamar";
import PaketPenerbangan from "./components/Penerbangan";
import Badge from "components/badge";
import PaketTagihan from "./components/Tagihan";
import Fee from "./pages/Fee";
import Coupon from "./components/Coupon";
import Akomodasi from "./components/Akomodasi";
import Additional from "./components/Additional";
import Laporan from "./components/Laporan";
import Perlengkapan from "./pages/Perlengkapan";
import Dokumen from "./pages/Dokumen";
import Manifest from "./components/Manifest";
import Blockseat from "./pages/Blockseat";
import Ibadahku from "./pages/Ibadahku";
import PaketRoomlist from "./pages/Roomlist";

import IconBack from "assets/icons/arrow-left-dark-icon.svg";
import GeneralIcon from "assets/icons/colored/General.svg";
import GeneralInfoIcon from '../../../assets/icons/GeneralInfo.svg'
import IconPenerbanganGrey from '../../../assets/icons/penerbangan.svg'
import IconPenerbanganAktif from "assets/icons/Penerbangan-aktif.svg";
import PaketDetailStyle from "./PaketDetail.Style";
import Transportasi from "./pages/transportasi";
import Itinenary from "./pages/Itinenary";
import { IcFee } from "assets/icons/dashboard";
import IconTipeKamarGrey from '../../../assets/icons/tipekamar.svg'
import IconTipeKamar from "assets/icons/colored/Tipe-Kamar.svg";
import IconAkomodasiGrey from '../../../assets/icons/akomodasi-grey.svg'
import IconHotelAkomodasi from "assets/icons/colored/Hotel-Akomodasi.svg";
import IconKendaraanGrey from '../../../assets/icons/kendaraan-grey.svg'
import IconKendaraan from "assets/icons/Kendaraan1.svg";
import IconAdditional from "assets/icons/Additional1.svg";
import IconAdditionalGrey from '../../../assets/icons/additional-grey.svg'
import IconFeeGrey from '../../../assets/icons/fee-grey.svg'
import IconFeeRed from "assets/icons/Fee-red.svg";
import IconItenerary from "assets/icons/colored/Itinerary.svg"
import IconItinenaryGrey from '../../../assets/icons/itinenary-grey.svg'
import IconCouponGrey from '../../../assets/icons/coupon-grey.svg'
import IconCoupon from "assets/icons/colored/Coupon.svg"
import IconTagihan from "assets/icons/Tagihan.svg";
import IconTagihanGrey from '../../../assets/icons/tagihan-grey.svg'
import IconLaporan from "assets/icons/Laporan.svg";
import IconLaporanGrey from '../../../assets/icons/laporan-grey.svg'
import IconPerlengkapan from "assets/icons/colored/Perlengkapan.svg";
import IconPerlengkapanGrey from "../../../assets/icons/perlengkapan-grey.svg"
import IconDokumen from "assets/icons/Dokumen.svg";
import IconDokumenGrey from "../../../assets/icons/dokumen-grey.svg"
import IconManifest from "assets/icons/Manifest.svg";
import IconBlockseatGrey from "../../../assets/icons/blockseat-grey.svg";
import IconBlockseat from "../../../assets/icons/Blockseat.svg";
import IconIbadahkuGrey from "../../../assets/icons/ibadahku-grey.svg";
import IconIbadahku from "../../../assets/icons/ibadahku-active.svg";
import IconDelete from "assets/icons/icon-delete-maroon.svg";
import IconDuplicate from "assets/icons/icon-duplicated.svg";

import IconManasik from 'assets/icons/Manasik.svg';
import IconManasikGrey from 'assets/icons/ManasikGrey.svg';

import Buttons from "components/buttons";
import { useDispatch, useSelector } from "react-redux";

import { deleteAllPaket, changeStatusValuePaket } from "stores/actions/paket/generalInfo";
import { duplicatePackage } from "utils/api/paket";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import AlertPopup from "components/popup/alert/";
import moment from "moment";

import Page404 from "components/404";
import { handleName } from "utils/helpers";

import './style.css'
import Scroll from "components/scroll";
import Manasik from "./pages/manasik";

const useStyle = makeStyles({
  content: {
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.04)",
    background: "#fff",
  },

  activeTitle: {
    background: "linear-gradient(204.44deg, #F90601 -18.49%, #8A1212 84.72%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    fontWeight: '600'
  },
  header: {
    background: "#FFFFFF",
    padding: "18px 16px 18px 16px",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.04)",
    borderRadius: "20px 20px 0px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  title: {
    marginLeft: "13px",
    marginRight: "8px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "32px",
    background:
      "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  menuPanel: {
    "& h1": {
      color: '#828282'
    }
  }
});

const PaketDetail = () => {
  const classes = useStyle();
  const router = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const store = useSelector(({ generalInfo }) => generalInfo);
  const [clickedButton, setClickedButton] = useState("GeneralInfo");
  

  const [isNotFound, setIsNotFound] = useState(false);

  const { isLoadingDetail } = useSelector((state) => state.generalInfo);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const [isDeletePaket, setIsDeletePaket] = useState(false);
  const [isLoadingDel, setIsloadingDel] = useState(false);

  const [isDuplicatePaket, setIsDuplicatePaket] = useState(false);
  const [isLoadingDup, setIsloadingDup] = useState(false);
  
  const [isChangeStatus, setIsChangeStatus] = useState(0)
  const [changeStatusValue, setChangeStatusValue] = useState(null)

  const [publishSettingAgents, setPublishSettingAgents] = useState([]);
  const [publishSettingBranches, setPublishSettingBranches] = useState([]);
  const [publishSettingAgentsMore, setPublishSettingAgentsMore] = useState([]);

  const [publishSettingAgentsRemaining, setPublishSettingAgentsRemaining] = useState(0);
  const [publishSettingBranchesRemaining, setPublishSettingBranchesRemaining] = useState(0);
  const [publishSettingBranchesMore, setPublishSettingBranchesMore] = useState([]);


  const handleButtonClick = (buttonText) => {
    setClickedButton(buttonText);
  };

  useEffect(() => {
    const data = store.data;
    
    const agentData = data?.publish_settings?.agents
    const branchData = data?.publish_settings?.branches

    setPublishSettingBranchesRemaining(branchData?.length - 1)
    setPublishSettingBranches(branchData?.slice(0, 1))
    setPublishSettingAgentsMore(agentData?.slice(1))

    setPublishSettingAgentsRemaining(agentData?.length - 1)
    setPublishSettingAgents(agentData?.slice(0, 1))
    setPublishSettingBranchesMore(branchData?.slice(1))

  }, [store.data]);

  const getContent = () => {
    switch (clickedButton) {
      case "GeneralInfo":
        return <GeneralInfo handlePackageNotFound={() => setIsNotFound(true)} />;
      case "TipeKamar":
        return <TipeKamar />;
      case "Penerbangan":
        return <PaketPenerbangan />;
      case "Akomodasi":
        return <Akomodasi />;
      case "Transportasi":
        return <Transportasi />;
      case "Itenerary":
        return <Itinenary />;
      case "InfoTambahan":
        return <div>InfoTambahan</div>;
      case "Additional":
        return <Additional />;
      case "Coupon":
        return <Coupon />;
      case "Fee":
        return <Fee />;
      case "Tagihan":
        return <PaketTagihan />;
      case "Perlengkapan":
        return <Perlengkapan />;
      case "Dokumen":
        return <Dokumen />;
      case "Manifest":
        return <Manifest />;
      case "Roomlist":
        return <PaketRoomlist packageID={id} />;
      case "Laporan":
        return <Laporan />;
      case "Blockseat":
        return <Blockseat packageID={id} />;
      case "Manasik":
        return <Manasik packageID={id} />;
      case "Ibadahku":
        return <Ibadahku />;
      default:
        return null;
    }
  };

  const handleDeleteAllPaket = () => {
    setIsloadingDel(true);
    dispatch(
      deleteAllPaket(id, (message) => {
        setIsloadingDel(false);
        setIsDeletePaket(false);

        setTimeout(() => {
          dispatch(handleSuccess(null, { message: "Berhasil menghapus paket", code: 200 }));
        }, 1000);

        router.push(`/paket/${handleParamsPackageType(store?.data?.tipe_paket)}`);
      })
    );
  };

  const handleDuplicatePaket = async () => {
    setIsloadingDup(true);
    const response = await duplicatePackage(id);
    setIsloadingDup(false);
    if (response.status === 200) {
      setIsDuplicatePaket(false);
      dispatch(handleSuccess(response));
      router.push(`/paket/${handleParamsPackageType(store?.data?.tipe_paket)}`);
    } else {
      dispatch(handleError(response.data));
    }

  }

  const handelChangeStatus = (status) => {
    setIsChangeStatus(1)
    setChangeStatusValue(status)
  }

  const handleCloseModalDelete = () => {
    setIsDeletePaket(false);
  };

  const handleCloseModalPublish = () => {
    setIsChangeStatus(0)
  }

  const handleChangeStatusPackage = async () => {
    const payload = {
      id_paket: store?.data?.id_paket,
      status: changeStatusValue,
    };

    await dispatch(changeStatusValuePaket(payload));
    setIsChangeStatus(false);
  };

  const handleParamsPackageType = (paramID) => {
    switch(paramID) {
      case 1:
        return "haji"
      case 2:
        return "umroh"
      case 3:
        return "tour"
      case 4:
        return "jasabadal"
      case 5:
        return "tabungan"
      default:
        return 'umroh'
    }
  }

  return (
    <PaketDetailStyle>
      <>
          {isNotFound ? (
            <>
              <Page404 />
            </>
          ) : (
            <>
              <div className={classes.header}>
                <div className="flex flex-row items-center w-[80%]">
                  <img
                    src={IconBack}
                    alt="back button"
                    className="w-[16px] h-[16px] cursor-pointer"
                    onClick={() => router.push(`/paket/${handleParamsPackageType(store?.data?.tipe_paket)}`)}
                  />
                  <h1 className={classes.title}>{`${store.data?.judul_paket}`}</h1>
                  <div className="flex justify-center gap-2">
                    {store?.data?.status_keberangkatan === 0 ? (
                      <>
                        {store?.data.status === 0 ? (
                          <Badge
                            style={{}}
                            label={"Draft"}
                            color="#564592"
                            backgroundColor="#56459233"
                            customClass="!leading-6"
                          />
                        ) : (
                          <Badge
                            style={{}}
                            label={"Publish"}
                            color="#333D29"
                            backgroundColor="rgba(46, 178, 67, 0.2)"
                            customClass="!leading-6"
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {store?.data?.status_keberangkatan === 1 ? (
                          <Badge
                            style={{}}
                            label={"Sedang Berangkat"}
                            color="#e5be00"
                            backgroundColor="#FFF2B2"
                            customClass="!leading-6"
                          />
                        ) : (
                          <Badge
                            style={{}}
                            label={"Sudah Berangkat"}
                            color="#6C757D"
                            backgroundColor="#CED4DA"
                            customClass="!leading-6"
                          />
                        )}
                      </>
                    )}

                    {auth.user.access_data === 'all_branches' && (
                      <>
                        {publishSettingBranches?.map((item, index) => (
                          <Badge
                            key={index}
                            label={item?.name.toUpperCase()}
                            color={item?.color_text}
                            backgroundColor={item?.color}
                            customClass="!max-w-[110px] !line-clamp-1 !leading-6"
                          />
                        ))}
                        {publishSettingBranchesRemaining > 0 && (
                          <Badge
                            label={<>
                              {`+${publishSettingBranchesRemaining}`}
                              <div 
                                className="publish-more-content top-10 right-0 gap-2 cursor-default shadow-soft rounded-lg !bg-white !opacity-100 z-10">
                                <Scroll height="200px" customClass="!flex flex-col gap-2 !p-2">
                                  {publishSettingBranchesMore.map((item, index) => (
                                    <Badge
                                      key={index}
                                      label={item?.name.toUpperCase()}
                                      color={item?.color_text}
                                      backgroundColor={item?.color}
                                    />
                                  ))}
                                </Scroll>
                              </div>
                            </>}
                            color={"#141414"}
                            backgroundColor={"#fff"}
                            customClass="!border !leading-6 cursor-pointer publish-more"
                          />
                        )}

                        {publishSettingAgents?.map((item, index) => (
                          <Badge
                            key={index}
                            label={handleName(item.title, item.name)}
                            color={"#60308C"}
                            backgroundColor={"#CCDCFF"}
                            customClass="!max-w-[110px] !line-clamp-1 !leading-6"
                          />
                        ))}
                        {publishSettingAgentsRemaining > 0 && (
                          <Badge
                            label={<>
                              {`+${publishSettingAgentsRemaining}`}
                              <div 
                                className="publish-more-content top-10 right-0 gap-2 cursor-default shadow-soft rounded-lg !bg-white !opacity-100 z-10">
                                <Scroll height="200px" customClass="!flex flex-col gap-2 !p-2">
                                  {publishSettingAgentsMore?.map((item, index) => (
                                    <Badge
                                      key={index}
                                      label={handleName(item.title, item.name)}
                                      color={"#60308C"}
                                      backgroundColor={"#CCDCFF"}
                                    />
                                  ))}
                                </Scroll>
                              </div>
                            </>}
                            color={"#141414"}
                            backgroundColor={"#fff"}
                            customClass="!border !leading-6 cursor-pointer publish-more"
                          />
                        )}
                      </>
                    )}

                  </div>
                </div>

                {auth.user.access_data === 'all_branches' && (
                  <>
                    <div className=" flex flex-row justify-end w-[20%] py-[4px] items-center">
                      {permissions?.includes('create_package') && (
                        <>
                          <div 
                            onClick={() => setIsDuplicatePaket(true)}
                            className="mr-[16px] cursor-pointer">
                            <img src={IconDuplicate} alt="icon_duplicated" />
                          </div>
                        </>
                      )}
                      {!store?.data?.status && (
                        <>
                          {permissions?.includes('delete_package') && (
                            <>
                              <div
                                className="mr-[16px] cursor-pointer"
                                onClick={() => setIsDeletePaket(true)}
                              >
                                <img src={IconDelete} alt="icon_delete" />
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {permissions?.includes('update_package') && (
                        <>
                          {store?.data?.status ? (
                            <Buttons 
                              text="Batalkan Publish" 
                              onClick={() => handelChangeStatus(0)}
                              customClass="btn-primary btn-small" />
                          ) : (
                            <Buttons 
                              text="Publish" 
                              onClick={() => handelChangeStatus(1)}
                              customClass="btn-primary btn-small" />
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="bg-white h-[full] normal-shadow border-t border-solid border-gray-5 rounded-bl-3xl rounded-br-3xl ">
                <div className={classes.content}>
                  <div
                    className={`${classes.menuPanel} ml-6 py-[19px]`}
                    style={{ width: "calc(100% - 40px)" }}
                  >
                    <Sliders
                      customClass={`!flex !flex-row !items-center !gap-1`}
                      slidesToShow={8}
                    >
                      <div
                        className={`!flex !flex-row gap-3 cursor-pointer ${
                          clickedButton === "GeneralInfo" ? classes.activeTitle : ""
                        }`}
                        onClick={() => handleButtonClick("GeneralInfo")}
                      >
                        <img src={clickedButton === "GeneralInfo" ? GeneralIcon : GeneralInfoIcon} alt="general Info" />
                        <h1>General Info</h1>
                      </div>
                      <div
                        className={`!flex !flex-row gap-3 cursor-pointer ${
                          clickedButton === "TipeKamar" ? classes.activeTitle : ""
                        }`}
                        onClick={() => handleButtonClick("TipeKamar")}
                      >
                        <img src={clickedButton === "TipeKamar" ? IconTipeKamar : IconTipeKamarGrey} alt="TipeKamar" />
                        <h1>Tipe Kamar</h1>
                      </div>
                      {permissions?.includes('view_flight') && (
                        <>
                          <div
                            className={`!flex !flex-row gap-3 cursor-pointer ${
                              clickedButton === "Penerbangan" ? classes.activeTitle : ""
                            }`}
                            onClick={() => handleButtonClick("Penerbangan")}
                          >
                            <img src={clickedButton === "Penerbangan" ? IconPenerbanganAktif : IconPenerbanganGrey } alt="general Info" />
                            <h1>Penerbangan</h1>
                          </div>
                        </>
                      )}
                      <div
                        className={`!flex !flex-row gap-3 cursor-pointer ${
                          clickedButton === "Akomodasi" ? classes.activeTitle : ""
                        }`}
                        onClick={() => handleButtonClick("Akomodasi")}
                      >
                        <img src={clickedButton === "Akomodasi" ? IconHotelAkomodasi : IconAkomodasiGrey} alt="general Info" />
                        <h1>Akomodasi</h1>
                      </div>
                      <div
                        className={`!flex !flex-row gap-3 cursor-pointer ${
                          clickedButton === "Transportasi" ? classes.activeTitle : ""
                        }`}
                        onClick={() => handleButtonClick("Transportasi")}
                      >
                        <img src={ clickedButton === "Transportasi" ? IconKendaraan : IconKendaraanGrey } alt="general Info" />
                        <h1>Transportasi</h1>
                      </div>
                      <div
                        className={`!flex !flex-row gap-3 cursor-pointer ${
                          clickedButton === "Itenerary" ? classes.activeTitle : ""
                        }`}
                        onClick={() => handleButtonClick("Itenerary")}
                      >
                        <img src={clickedButton === "Itenerary" ? IconItenerary : IconItinenaryGrey} alt="general Info" />
                        <h1>Itenerary</h1>
                        <div className={`ml-3 border-r-[2px] border-solid border-gray-5`}></div>
                      </div>

                      <div
                        className={`!flex !flex-row gap-3 cursor-pointer ${
                          clickedButton === "Additional" ? classes.activeTitle : ""
                        }`}
                        onClick={() => handleButtonClick("Additional")}
                      >
                        <img src={clickedButton === "Additional" ? IconAdditional : IconAdditionalGrey} alt="general Info" />
                        <h1>Additional</h1>
                      </div>
                      <div
                        className={`!flex !flex-row gap-3 cursor-pointer ${
                          clickedButton === "Coupon" ? classes.activeTitle : ""
                        }`}
                        onClick={() => handleButtonClick("Coupon")}
                      >
                        <img src={clickedButton === "Coupon" ? IconCoupon : IconCouponGrey} alt="general Info" />
                        <h1>Coupon</h1>
                      </div>
                      <div
                        className={`!flex !flex-row gap-3 cursor-pointer ${
                          clickedButton === "Fee" ? classes.activeTitle : ""
                        }`}
                        onClick={() => handleButtonClick("Fee")}
                      >
                        <img src={clickedButton === "Fee" ? IcFee : IconFeeGrey} alt="general Info" />
                        <h1>Fee</h1>
                      </div>
                      {permissions?.includes('view_bill') && (
                        <>
                          <div
                            className={`!flex !flex-row gap-3 cursor-pointer ${
                              clickedButton === "Tagihan" ? classes.activeTitle : ""
                            }`}
                            onClick={() => handleButtonClick("Tagihan")}
                          >
                            <img src={clickedButton === "Tagihan" ? IconTagihan : IconTagihanGrey} alt="tagihan" />
                            <h1>Tagihan</h1>
                            <div className={`ml-3 border-r-[2px] border-solid border-gray-5`}></div>
                          </div>
                        </>
                      )}
                        {permissions?.includes('view_equipment') && (
                          <div
                            className={`!flex !flex-row gap-3 cursor-pointer ${
                              clickedButton === "Perlengkapan" ? classes.activeTitle : ""
                            }`}
                            onClick={() => handleButtonClick("Perlengkapan")}
                          >
                            <img src={clickedButton === "Perlengkapan" ? IconPerlengkapan : IconPerlengkapanGrey} alt="perlengkapan" />
                            <h1>Perlengkapan</h1>
                          </div>
                        )}
                      {permissions?.includes('view_manifest') && (
                        <div
                            className={`!flex !flex-row gap-3 cursor-pointer ${
                              clickedButton === "Dokumen" ? classes.activeTitle : ""
                            }`}
                            onClick={() => handleButtonClick("Dokumen")}
                          >
                            <img src={ clickedButton === "Dokumen" ? IconDokumen : IconDokumenGrey} alt="perlengkapan" />
                            <h1>Dokumen</h1>
                        </div>
                      )}
                      {permissions?.includes('view_manifest') && (
                        <div
                            className={`!flex !flex-row gap-3 cursor-pointer ${
                              clickedButton === "Manifest" ? classes.activeTitle : ""
                            }`}
                            onClick={() => handleButtonClick("Manifest")}
                          >
                            <img src={clickedButton === "Manifest" ? IconDokumen : IconDokumenGrey} alt="manifest" />
                            <h1>Manifest</h1>
                        </div>  
                      )}
                      {permissions?.includes('view_roomlist') && (
                          <div
                            className={`!flex !flex-row gap-3 cursor-pointer ${
                              clickedButton === "Roomlist" ? classes.activeTitle : ""
                            }`}
                            onClick={() => handleButtonClick("Roomlist")}
                          >
                            <img src={clickedButton === "Roomlist" ? IconTagihan : IconTagihanGrey} alt="roomlist" />
                            <h1>Roomlist</h1>
                          </div>
                      )}
                      <div
                          className={`!flex !flex-row gap-3 cursor-pointer ${
                            clickedButton === "Laporan" ? classes.activeTitle : ""
                          }`}
                          onClick={() => handleButtonClick("Laporan")}
                        >
                          <img src={clickedButton === "Laporan" ? IconLaporan : IconLaporanGrey} alt="laporan" />
                        <h1>Laporan</h1>
                      </div>
                      {permissions?.includes('view_blockseat') && (
                        <>
                          <div
                            className={`!flex !flex-row gap-3 cursor-pointer ${
                              clickedButton === "Blockseat" ? classes.activeTitle : ""
                            }`}
                            onClick={() => handleButtonClick("Blockseat")}
                          >
                            <img src={clickedButton === "Blockseat" ? IconBlockseat : IconBlockseatGrey} alt="blockseat" />
                            <h1>Blockseat</h1>
                            <div className={`ml-3 border-solid border-gray-5`}></div>
                          </div>
                        </>
                      )}
                      {permissions?.includes('view_manasik') && (
                        <>
                          <div
                            className={`!flex !flex-row gap-3 cursor-pointer ${
                              clickedButton === "Manasik" ? classes.activeTitle : ""
                            }`}
                            onClick={() => handleButtonClick("Manasik")}
                          >
                            <img src={clickedButton === "Manasik" ? IconManasik : IconManasikGrey} alt="manasik" />
                            <h1>Manasik</h1>
                            <div className={`ml-3 border-r-[2px] border-solid border-gray-5`}></div>
                          </div>
                        </>
                      )}
                      <div
                        className={`!flex !flex-row gap-3 cursor-pointer ${
                          clickedButton === "Ibadahku" ? classes.activeTitle : ""
                        }`}
                        onClick={() => handleButtonClick("Ibadahku")}
                      >
                        <img src={clickedButton === "Ibadahku" ? IconIbadahku : IconIbadahkuGrey} alt="ibadahku" />
                        <h1>Ibadahku</h1>
                      </div>
                    </Sliders>
                  </div>
                </div>
                {getContent()}
              </div>
              {/* AlertPopup */}
              <AlertPopup
                open={isDeletePaket}
                handleClose={handleCloseModalDelete}
                handleContinue={handleDeleteAllPaket}
                title="Hapus Paket?"
                subtitle={`Apakah anda yakin ingin menghapus paket ?`}
                loading={isLoadingDel}
              />

              <AlertPopup
                open={isDuplicatePaket}
                handleClose={() => setIsDuplicatePaket(false)}
                handleContinue={handleDuplicatePaket}
                title="Duplikat Paket?"
                subtitle={`Apakah anda yakin ingin menduplikat paket ?`}
                loading={isLoadingDup}
                text_accept="Duplikat"
              />

              <AlertPopup
                open={isChangeStatus}
                handleClose={handleCloseModalPublish}
                handleContinue={handleChangeStatusPackage}
                text_accept={changeStatusValue ? `Publish Paket` : `Batalkan Publish`}
                title={changeStatusValue ?  `Publish Paket?` : `Batalkan Publish Paket?`}
                subtitle={changeStatusValue ? `Apakah kamu yakin akan mempublish paket?` : `Apakah kamu yakin akan membatalkan publish paket?`}
                loading={store.isLoadingChangeStatus}
              />
            </>
          )}
        </>
      {/* {isLoadingDetail && (
        <>
          <div className="animate-pulse">
            <div className="h-[calc(100vh-130px)] bg-gray-200 rounded-[20px]">
            
            </div>
          </div>
        </>
      )} */}
    </PaketDetailStyle>
  );
};

export default PaketDetail;
