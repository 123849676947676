import React, { useEffect, useState } from "react";
import Badge from "components/badge";
import Buttons from "components/buttons";
import Item from "./Item";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "components/popup/alert";
import {
  deleteManasikCabangPaket,
  getListManasikPaket,
  getManasikCabangPaket,
} from "stores/actions/paket/generalInfo";
import { handleSuccess } from "stores/actions/errorGeneral";
import moment from "moment";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";
import Popup from "components/popup";

import ManasikForm from "./ManasikForm";

const Location = ({ data }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [dataCabangManasik, setDataCabangManasik] = useState([]);
  const [choiceManasik, setChoiceManasik] = useState(0);
  const [isDeleteManasik, setIsDeleteManasik] = useState(false);
  const [isLoadingDelManasik, setIsLoadingDeleteManasik] = useState(false);
  const [choiceIndex, setChoiceIndex] = useState(null);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const [openManasikForm, setOpenManasikForm] = useState(false);
  const [labelManasikForm, setLabelManasikForm] = useState("Tambah Manasik");

  const [branchIdSelected, setBranchIdSelected] = useState(null);

  const [listManasik, setListManasik] = useState([]);
  let { id } = useParams();

  const [locationSelected, setLocationSelected] = useState({})

  const dispatch = useDispatch();

  const addItem = () => {
    setLabelManasikForm("Tambah Manasik");
    setLocationSelected({
      koordinat: {
        lat: 0,
        lng: 0,
      },
      tanggal_pelaksanaan: "",
      waktu_pelaksanaan: "",
      pesan_undangan: "",
      lokasi: "",
      cabang_id: branchIdSelected,
      date: moment().format("YYYY-MM-DD")
    })

    setOpenManasikForm(true);

  };

  useEffect(() => {
    handleClickManasikCabang(auth?.user?.cabang_id);
  }, []);

  useEffect(() => {
    handleGetLocationManasik()
  }, [dispatch, id]);
  

  const handleGetLocationManasik = () => {
    dispatch(
      getListManasikPaket(id, (message) => {
        setListManasik(message.data);
      })
    );
  }

  const handleClickManasikCabang = (id_cabang) => {
    setBranchIdSelected(id_cabang);
    const payload = {
      id: id,
      cabang_id: id_cabang,
    };

    dispatch(
      getManasikCabangPaket(payload, (message) => {
        if (message?.data?.length > 0) {
          let resultMap = message?.data?.map((item) => {
            return {
              ...item,
              koordinat: {
                lat: parseFloat(item?.lotitude),
                lng: parseFloat(item?.langitude),
              },
              lokasi: item?.lokasi,
              tanggal_pelaksanaan: item.date,
              waktu_pelaksanaan: `${item?.start_at ? `${item?.start_at} - <strong>Selesai</strong>` : ''} `,
              pesan_undangan: item?.message,
            };
          });
          dispatch(
            getListManasikPaket(id, (message) => {
              setListManasik(message.data);
            })
          );
          setDataCabangManasik(resultMap);
        } else {
          setDataCabangManasik([]);
        }
        // else {
        //   let objectMap = {
        //     koordinat: {
        //       lat: 0,
        //       lng: 0,
        //     },
        //     lokasi: "",
        //     tanggal_pelaksanaan: "",
        //     waktu_pelaksanaan: ` - `,
        //     pesan_undangan: "",
        //     cabang_id: id_cabang,
        //   };
        //   dispatch(
        //     getListManasikPaket(id, (message) => {
        //       setListManasik(message.data);
        //     })
        //   );
        //   setDataCabangManasik([objectMap]);
        // }
      })
    );
  };

  const removeItem = (item_index) => {
    setIsLoadingDeleteManasik(true);
    let data = dataCabangManasik[item_index - 1];
    
    dispatch(
      deleteManasikCabangPaket(data.id_manasik, (message) => {
        handleClickManasikCabang(data.cabang_id);
        dispatch(
          getListManasikPaket(id, (message) => {
            setListManasik(message.data);
            setIsLoadingDeleteManasik(false);
            setIsDeleteManasik(false);
            dispatch(handleSuccess(null, {message: "Berhasil menghapus manasik", code: 200}));
          })
        );
      })
    );

  };

  const handleEditLocation = (status, data) => {
    setIsEdit(status);

    if(status) {
      setLabelManasikForm("Edit Manasik");
      setOpenManasikForm(true);
      setLocationSelected(data)
    }

  };

  const handleClosePopUpConfirmManasik = () => {
    setIsDeleteManasik(false);
  };

  useEffect(() => {
    handleGetLocationManasik()
  }, [dispatch, id]);

  return (
    <>
    <Popup
      open={openManasikForm}
      handleClose={() => setOpenManasikForm(false)}
      title={labelManasikForm}
      width="744px" 
      children={
        <ManasikForm
          data={locationSelected}
          setIsEdit={(status) => handleEditLocation(status, data)}
          onChange={(action) => {
            if(action === "add") {
              setOpenManasikForm(false)
            }
            handleClickManasikCabang(auth?.user?.cabang_id);
          }}
          />
      }
      />
    <div className="border-t"></div>
    {auth.user.access_data === 'all_branches' && (
      <>
        <div className="flex flex-col items-start px-4 gap-[16px] p-4">
          <div className="flex flex-col justify-center items-start gap-[16px]">
            <div className="flex flex-row items-start gap-[16px] flex-wrap">
              {listManasik?.map((item, idx) => (
                <div
                  className={`flex flex-row items-center gap-[12px] rounded-[10px] cursor-pointer hover:bg-[#fff] hover:shadow-md p-[2px] ${
                    choiceManasik === idx ? "p-[2px] shadow-highlight " : ""
                  }`}
                  onClick={() => {
                    handleClickManasikCabang(item.cabang_id);
                    setChoiceManasik(idx);
                  }}
                >
                  <Badge
                    label={`${item.cabang_name}`}
                    color={`${item.color_text}`}
                    backgroundColor={`${item.bg_color}`}
                    customClass="!capitalize font-bold text-[12px]"
                  />
                  <div className="px-[8px] py-[2px] rounded-md text-[#141414] flex flex-row items-center justify-center !bg-[#D8E1BE] font-sans font-semibold">
                    {item?.manasik_count}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="border-t"></div>
      </>
    )}
      
      {permissions?.includes('create_manasik') && (
        <>
          <div className="flex justify-end p-4">
            <Buttons
              text="Tambah Manasik"
              customClass="btn-outline btn-small"
              onClick={addItem}
            />
          </div>
        </>
      )}

      {/* {!!auth.user.access_data === 'all_branches' && ( */}
        

      {dataCabangManasik.length > 0 ? (
        <>
          <div className="grid grid-cols-2 px-4 gap-4 mb-4">
            {dataCabangManasik.map((item, index) => {
              const data = { ...item, id: index + 1 };
              return (
                <Item
                  removeItem={(index) => {
                    let data = dataCabangManasik[index-1];
                    // console.log("ini data :", data);
                    if(data?.id_manasik) {
                      setIsDeleteManasik(true);
                      setChoiceIndex(index);
                    } else {
                      let newDataManasik = [...dataCabangManasik]
                      newDataManasik.splice(index-1,1)
                      if(newDataManasik.length) {
                        setDataCabangManasik(newDataManasik)
                      }
                    }

                  }}
                  data={data}
                  key={index}
                  handleEdit={(status) => handleEditLocation(status, data)}
                  handleClickManasikCabang={handleClickManasikCabang}
                />
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col h-[400px] justify-center items-center bg-white">
            <img
              src={IconJamPasir}
              className="mb-2 w-[252px] h-[260px]"
            />
            <p className="text-base text-black">
              Belum ada manasik dicabang ini
            </p>
          </div>
        </>
      )}
      {/* AlertPopup */}
      <AlertPopup
        open={isDeleteManasik}
        handleClose={handleClosePopUpConfirmManasik}
        handleContinue={() => removeItem(choiceIndex)}
        title="Hapus Manasik?"
        subtitle={`Apakah kamu yakin ingin menghapus manasik ini ?`}
        loading={isLoadingDelManasik}
      />
    </>
  );
};

export default Location;
