import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import Avatar from "components/avatar"
import Badge from "components/badge"
import Buttons from "components/buttons"
import AntdSelect from "components/form/antdSelect"
import { handleName } from "utils/helpers"

import { getLocationByJamaah, changeLocationService } from "utils/api/manasik"
import { useFormik } from "formik"

import * as Yup from "yup"
import { handleError, handleSuccess } from "stores/actions/errorGeneral"

const ChangeLocationForm = ({
  manasik,
  onChange
}) => {

  const dispatch = useDispatch()

  const [locationManasik, setLocationManasik] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      branch_id: null,
      order_jamaah_id: manasik?.order_kamar_mapping_id
    },
    validationSchema: Yup.object().shape(
      {
        branch_id: Yup.number().required("Lokasi manasik harus dipilih"),
        order_jamaah_id: Yup.number().required("Jamaah tidak terdefinisi"),
      }
    ),
  })

  const handleGetLocationManasikByJamaah = async () => {
    const response = await getLocationByJamaah(manasik?.order_kamar_mapping_id)
    
    if (response && response.data) {
      const _tempLocationManasik = response.data.data.map((item) => ({
        value: item.id_cabang,
        label: item.name,
        data: {
          bgColor: item.color,
          textColor: item.color_text
        },
      }));

      setLocationManasik(_tempLocationManasik);

      formik.setFieldValue("branch_id", manasik.manasik_branch.id_cabang)
    }
    
  }

  const handleChangeLocationManasik = async () => {
    setIsLoading(true)
    const response = await changeLocationService(formik.values)
    setIsLoading(false)
    
    if(response.status === 200) {
      dispatch(handleSuccess(response))
      onChange()
    } else {
      dispatch(handleError(response.data))
    }

  }

  useEffect(() => {
    handleGetLocationManasikByJamaah()
  }, [])

  return (
    <>
      <div className="border rounded-2xl">
        <div className="p-4">
          
          <div className="flex gap-[18px] items-center">
            <Avatar 
              value={manasik} 
              containerClass={"w-[48px] h-[48px]"}
              customClass={"w-[48px] h-[48px]"}
              />
            <div className="flex justify-between items-center w-full">
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-sm">
                  {handleName(manasik?.jamaah_title, manasik?.jamaah_name)}
                </div>
                <Badge 
                  label={manasik?.branch_name}
                  color={manasik?.branch_text_color}
                  backgroundColor={manasik?.branch_bg_color}
                  />
              </div>

              <Badge
                label={manasik?.starting_name}
                color={manasik?.starting_text_color}
                backgroundColor={manasik?.starting_bg_color}
                />

            </div>
          </div>

          <AntdSelect
              label="Tempat Manasik"
              placeholder="Pilih tempat manasik"
              containerClass={"mt-4"}
              type="badge"
              defaultValue={null}
              value={formik.values.branch_id}
              options={locationManasik}
              onChange={(item) => formik.setFieldValue("branch_id", item.value)}
          />

        </div>
        <div className="p-4 border-t flex justify-end items-center">
          <Buttons 
            disabled={!(formik.isValid && formik.dirty && formik.values.branch_id != manasik.manasik_branch.id_cabang)}
            customClass="btn-primary btn-large" 
            onClick={handleChangeLocationManasik}
            text="Simpan" 
            loading={isLoading}
            />
        </div>
      </div>
    </>
  )
}

export default ChangeLocationForm