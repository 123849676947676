import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tab from "components/tab";
import Inputs from "components/form/inputs";

import IconSearch from "assets/icons/search-normal.svg";

import IconAbsensi from "assets/icons/absensi.svg";
import IconAbsensiGrey from "assets/icons/absensi-grey.svg";

import IconLocation from "assets/icons/icon-location-maroon.svg";
import IconLocationGrey from "assets/icons/icon-location-grey.svg";

import useDebounce from "utils/helpers/useDebounce";
import Attendance from "./partials/attendance";
import Filter from "components/filter";

import {
  getAllCabang,
} from "stores/actions/cabang";
import Location from "./partials/location";

const Manasik = ({
  packageID
}) => {

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [keyword, setKeyword] = useState("");

  const { dataAllCabang, isLoading } = useSelector((state) => state.cabang);

  const [dataFilter, setDataFilter] = useState({
    branch_id: [],
  })

  const handleGetFilterBranches = () => {
    dispatch(
      getAllCabang({
        page_number: 1,
        per_page: 10000,
        sort_column: "",
        sort_order: "Desc",
        keyword: "",
      })
    );
  };

  const renderTab = () => {
    let tabs = [
      {
        label: (
          <>
            <div className="px-4 py-3">
              <div className="flex gap-2 items-center">
                {activeTab === '1' ? (
                  <img src={IconAbsensi} alt="absensi" />
                ) : (
                  <img src={IconAbsensiGrey} alt="absensi" />
                )}
                <div>Absensi</div>
              </div>
            </div>
          </>
        ),
        key: '1',
        children: (
          <>
            <Attendance 
              packageId={packageID} 
              keyword={keyword}
              dataFilter={dataFilter}
              />
          </>
        )
      },
      {
        label: (
          <>
            <div className="px-4">
              <div className="flex gap-2 items-center">
                {activeTab === '2' ? (
                  <img src={IconLocation} alt="absensi" />
                ) : (
                  <img src={IconLocationGrey} alt="absensi" />
                )}
                <div>Lokasi Manasik</div>
              </div>
            </div>
          </>
        ),
        key: '2',
        children: (
          <>
            <Location />
          </>
        )
      }
    ]

    return tabs;
  }

  const filterOptions = [
    {
      label: "Berdasarkan Cabang",
      key: "branch_id",
      type: "badge",
      customKey: {
        value: 'id_cabang',
        label: 'name',
        badgeBgColor: 'color',
        badgeTextColor: 'color_text',
      }
    },
  ];

  useEffect(() => {
    handleGetFilterBranches();
  }, []);

  return (
    <>
      <div className="relative">
        <Tab 
          defaultActiveKey="1"
          onChange={(e) => setActiveTab(e)}
          items={renderTab()}
          destroyInactiveTabPane={true}
          />

        <div className="flex gap-4 items-center absolute top-2 right-4">
          {activeTab === '1' ? (
            <>
              <Inputs
                id="search"
                placeholder="Search"
                customClass="!w-[240px]"
                iconPosition="left"
                icon={IconSearch}
                onChange={e => setKeyword(e.target.value)}
              />
              <Filter 
                options={filterOptions}
                type="options"
                data={dataAllCabang?.data}
                onChange={(values) => {
                  console.log("Index", values)
                  setDataFilter({ ...values })
                }}
                />
            </>
          ) : (
            <div className="h-[54px] w-[240px]"></div>
          )}
        </div>
      </div>
    </>
  )
}

export default Manasik