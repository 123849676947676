import { useState } from "react";

import { handleName } from "utils/helpers";

import { useSelector } from "react-redux"

import IconChecklistCircle from "assets/icons/ChecklistCircle.svg";
import IconCheklistCircleChecked from "assets/icons/Checklist_Round.svg";
import { useEffect } from "react";
import Avatar from "components/avatar";
import Badge from "components/badge";

const DocumentRecipient = ({
  value,
  onChange,
  locked,
}) => {

  const [documentRecipient, setDocumentRecipient] = useState(null)
  const [documentRecipientSaved, setDocumentRecipientSaved] = useState(false)

  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const handleCheckDocumentRecipient = (val) => {

    if(!documentRecipientSaved) {
      if(val) {
          const admin = {
              'id' : auth?.user?.id,
              'name' : auth?.user?.name,
              'title': auth?.user?.title,
              'photo_profile': auth?.user?.image_profile,
              'role_name': auth?.user?.nama_role,
              'role_bg_color': auth?.user?.color_bg_role,
              'role_text_color': auth?.user?.color_text_role
          }

          setDocumentRecipient(admin)
          onChange(auth?.user?.id)

      } else {
          setDocumentRecipient(null)
          onChange(null)
      }
    }
    
  }

  useEffect(() => {
    
    if (value !== null) {
      if (typeof value === 'object' && !Array.isArray(value)) {
          setTimeout(() => {
            onChange(value.id)
          })
          setDocumentRecipient(value)
          setDocumentRecipientSaved(true)
      } else {
          setTimeout(() => {
            onChange(value)
          })
      }
    } else {
      setTimeout(() => {
        onChange(value)
      })
    }

  }, [value])

  useEffect(() => {
    if(locked) {
      setDocumentRecipientSaved(true)
    }
  }, [locked])

  return (
    <>
      <div 
          className='rounded-2xl p-4 border border-gray-4 flex justify-between items-center bg-[#FBF3F2] mb-4'>
          <div className='font-semibold'>
              {documentRecipient ? (
                  <>
                      <div className='flex gap-3 items-center'>
                          <div>
                              <Avatar 
                                  containerClass=
                                  "items-center justify-center  w-[56px] h-[56px] rounded-[100%]  bg-slate-100"
                                  customClass="object-cover w-[56px] h-[56px] rounded-[100%]"
                                  value={documentRecipient}
                                  />
                          </div>
                          <div>
                              <div className='mb-2 font-normal text-xs'>
                                  {handleName(documentRecipient?.title, documentRecipient?.name)}
                              </div>
                              <Badge 
                                  customClass='text-xs'
                                  label={documentRecipient?.role_name}
                                  backgroundColor={documentRecipient?.role_bg_color}
                                  color={documentRecipient?.role_text_color}
                                  />
                          </div>
                      </div>
                  </>
              ) : (
                  <>
                      Dokumen Fisik Telah Diterima
                  </>
              )}
          </div>
          <div>
              <div 
                  onClick={() => {
                          if(!documentRecipientSaved || permissions.includes('update_manifest') ) {
                              let status = true;
                              
                              if(value != null) {
                                  status = false
                              } else {
                                  status = true
                              }

                              handleCheckDocumentRecipient(status)
                          }
                      }
                  }
                  className={`${!!documentRecipientSaved || !permissions.includes('update_manifest')  ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                  {value == null && (
                    <img
                      className="w-6 h-6"
                      src={IconChecklistCircle}
                      />
                  )}
                  {value != null && (
                    <img
                      className="w-6 h-6"
                      src={IconCheklistCircleChecked}
                      />
                  )}
              </div>
          </div>
      </div>
    </>
  )
}


export default DocumentRecipient