// import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  getAllDataAkomodasi,
  getAllDataMaskapai,
} from "stores/actions/datamaster";

import Cards from "components/cards";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import MaskapaiPriceUpdate from './components/priceUpdate/maskapai'
import AkomodasiPriceUpdate from "./components/priceUpdate/akomodasi";
import OperationalPricUpdate from "./components/priceUpdate/operational";
import TourCityPriceUpdate from "./components/priceUpdate/tourCity";
import CountryTourUpdatePrice from "./components/priceUpdate/tourCountry";

function UpdateHarga() {
  
  const dispatch = useDispatch();

  return (
    <>
      {/* <AlertPopup
        open={airLinesRemoveConfirm.show}
        handleClose={() => setAirLinesRemoveConfirm({ show: false, index: 0 })}
        handleContinue={() => onHandleRemovingMaskapai(airLinesRemoveConfirm.index)}
        loading={updateHargaStore?.isLoadingRemoveMaskapai}
        subtitle="Apakah anda yakin menghapus maskapai ini?"
      /> */}

      <Cards containerClass="!p-0">
        <div className="pb-[72px]">
          <p className="font-bold text-xl px-4 py-[17px]">Update Harga</p>
          <hr />

          <div className="p-4">

            <MaskapaiPriceUpdate />        
            <div className="divider my-6"></div>

            <AkomodasiPriceUpdate />
            <div className="divider my-6"></div>

            <OperationalPricUpdate />

            <TourCityPriceUpdate/>
            <div className="divider my-6"></div>

            <CountryTourUpdatePrice/>
          </div>
        
        </div>
      </Cards>
    </>
  );
}

export default UpdateHarga;
