import Avatar from "components/avatar"
import Badge from "components/badge"
import Dropdowns, { closeDropdown } from "components/dropdowns"
import Inputs from "components/form/inputs"
import Textarea from "components/form/textarea"
import Scroll from "components/scroll"
import { useFormik } from "formik"
import { Trash } from "iconsax-react"
import { useEffect, useState } from "react"
import { IDRFormater, toCapital } from "utils/helpers"

import * as Yup from 'yup'

const Step3AdditionalJamaah = ({ additionalFormik, additionalsFormik, orderJamaahList, index, handleRemoveJamaah, additionalIndex }) => {

  const [selectedJamaah, setSelectedJamaah] = useState({})
  const [selectorJamaahLabel, setSelectorJamaahLabel] = useState('Pilih Jamaah')
  const [isDisableSelectorJamaah, setIsDisableSelectorJamaah] = useState(false)

  const jamaahFormik = useFormik({
    initialValues: {
      quantity: 1,
      name: null,
      title: null,
      ktp_nik: null,
      note: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Wajib pilih jamaah'),
      ktp_nik: Yup.string().required('Wajib Pilih Jamaah'),
      quantity: Yup.number().required('Quantity wajib diisi').min(1, 'Quantity minimal 1'),
    })
  })

  const handleSelectJamaah = (jamaah) => {
    setSelectedJamaah({
      quantity: 1,
      name: jamaah.jamaah_name,
      title: jamaah.jamaah_title,
      ktp_nik: jamaah.jamaah_identity_number,
      note: null
    })

    jamaahFormik.setFieldValue("name", jamaah.jamaah_name)
    jamaahFormik.setFieldValue("title", jamaah.jamaah_title)
    jamaahFormik.setFieldValue("ktp_nik", jamaah.jamaah_identity_number)

    setTimeout(() => {
      jamaahFormik.setFieldTouched("name", 1)
      jamaahFormik.setFieldTouched("title", 1)
      jamaahFormik.setFieldTouched("ktp_nik", 1)
    })

  }

  useEffect(() => {
    if (Object.keys(selectedJamaah).length > 0) {
      const label = `${selectedJamaah.title ? `${toCapital(selectedJamaah.title)}.` : ""} ${selectedJamaah.name?.toUpperCase()}`
      
      setSelectorJamaahLabel(label)
    }
  }, [selectedJamaah])


  useEffect(() => {
    if(additionalsFormik.values.paket_additionals[additionalIndex]?.jamaah[index]?.ktp_nik) {
      const dataJamaah = additionalsFormik.values.paket_additionals[additionalIndex]?.jamaah[index]

      setSelectedJamaah(dataJamaah)

      jamaahFormik.setFieldValue("name", dataJamaah.name)
      jamaahFormik.setFieldValue("title", dataJamaah.title)
      jamaahFormik.setFieldValue("quantity", dataJamaah.quantity)
      jamaahFormik.setFieldValue("note", dataJamaah.note)
      jamaahFormik.setFieldValue("ktp_nik", dataJamaah.ktp_nik)

      setTimeout(() => {
        jamaahFormik.setFieldTouched("name", 1)
        jamaahFormik.setFieldTouched("title", 1)
        jamaahFormik.setFieldTouched("quantity", 1)
        jamaahFormik.setFieldTouched("note", 1)
        jamaahFormik.setFieldTouched("ktp_nik", 1)
      })

      setIsDisableSelectorJamaah(true)

    } else {
      setIsDisableSelectorJamaah(false)
    }
  }, [])

  useEffect(() => {

    additionalFormik.setFieldValue(`jamaah[${index}]`, jamaahFormik.values)
    
    setTimeout(() => {
      additionalFormik.setFieldTouched(`jamaahy[${index}]`, 1)
    })

  }, [jamaahFormik.values])

  return (
      <>
        <div className="bg-white p-4 mt-4 rounded-xl">
          <div className="flex w-full justify-end">
            {additionalFormik.values.jamaah?.length > 1 && additionalFormik.values.is_wajib !== '1' && (
              <Trash
                className="cursor-pointer"
                onClick={() => handleRemoveJamaah(index)}
                size="24"
                color="#141414"
              />
            )}
          </div>
          <div>
            <div className="font-semibold text-sm mb-2">Jamaah</div>
            <Dropdowns
              customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
              placement="bottom"
              labelDropdown={selectorJamaahLabel}
              dropdownArrow={isDisableSelectorJamaah ? false : true}
              disabled={isDisableSelectorJamaah}
              error={jamaahFormik?.touched?.name && jamaahFormik?.errors?.name}
            >
              <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                <Scroll
                    height="350px"
                    customClass="pr-2"
                    >
                  {orderJamaahList?.map((jamaah, idx) => {
                    return (
                      <>
                        {additionalFormik.values.jamaah?.some((j) => j.name !== jamaah.jamaah_name) && (
                          <>
                            <div
                              key={idx}
                              className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                              onClick={() => {
                                handleSelectJamaah(jamaah)
                                closeDropdown();
                              }}
                            >
                              <Avatar
                                value={jamaah}
                                containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                                customClass={`object-cover w-[56px] h-[56px] rounded-[100%]`}
                              />
                              <div className="ml-3">
                                <div className="font-semibold text-[20px]">
                                  {jamaah.jamaah_title ? toCapital(jamaah.jamaah_title) + `.` : "" } {toCapital(jamaah.jamaah_name)}
                                </div>
                                <div className="flex gap-2 mt-2">
                                  <Badge backgroundColor={jamaah.starting?.bg_color} color={jamaah.starting?.text_color} label={jamaah.starting?.name} />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )
                  })}
                </Scroll>
              </div>
              
            </Dropdowns>
          </div>
          <div className="mt-4">
            <Textarea
              label="Catatan"
              id="note"
              name="note"
              placeholder="Masukkan catatan"
              onBlur={jamaahFormik.handleBlur}
              onChange={jamaahFormik.handleChange}
              value={jamaahFormik.values.note}
              error={jamaahFormik?.touched?.note && jamaahFormik?.errors?.note}
              />
          </div>
          <div className="mt-4 flex items-center gap-2">
            <div className="w-1/5">
              <Inputs
                id="quantity"
                name="quantity"
                type="number"
                value={jamaahFormik.values.quantity}
                min={1}
                onChange={jamaahFormik.handleChange}
                onBlur={jamaahFormik.handleBlur}
                error={jamaahFormik?.touched?.quantity && jamaahFormik?.errors?.quantity}
                />
            </div>
            <div className="w-4/5">
              <Inputs 
                name="additional_price"
                type="text"
                disabled={true}
                customClass="!font-semibold !text-black-1"
                value={IDRFormater((additionalFormik.values.price * jamaahFormik.values.quantity))}
                />
            </div>
          </div>
        </div>
      </>
  )
}

export default Step3AdditionalJamaah