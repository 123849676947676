
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";

import AlertPopup from "components/popup/alert";
import Buttons from "components/buttons";
import TourCountryForm from "./components/TourCountryForm";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import moment from "moment";


const CountryTourUpdatePrice = () => {

    const dispatch = useDispatch()
    const [countryTours, setCountryTours] = useState([])

    const [isGetAllLoading, setGetAllLoading] = useState(false)

    const [months, setMonths] = useState([])
    const [years, setYears] = useState([])

    const [isEditTours, setIsEditTours] = useState([])
    const [validateAddingTours, setValidateAddingTours] = useState([])

    const [deleteTourIds, setDeleteTourIds] = useState([])

    const [savingIndex, setSavingIndex] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)

    const [isAddForm, setIsAddForm] = useState(false)

    const onHandleCountryTours = async () => {
        try {

            setGetAllLoading(true)
            
            const res = await PriceUpdateAPI.countryToursGet()
            if(res.status = 200) {
                setCountryTours(res.data.data)
            } else {
                dispatch(handleError(res))
            }

            setGetAllLoading(false)

        } catch(error) {
            setGetAllLoading(false)
            dispatch(handleError(error))
        }
    }

    const handleConfirmDelete = (index) => {
        setShowConfirmDelete(true)
        setDeleteIndex(index)
    }

    const handleDelete = async () => {
        const tourSelected = countryTours[deleteIndex]

        setIsDeleting(true)
        const response = await PriceUpdateAPI.countryToursRemove(tourSelected.name)
        setIsDeleting(false)
        setShowConfirmDelete(false)
        
        if(response.status === 200) {
            dispatch(handleSuccess(response))
            onHandleCountryTours()
        } else {
            dispatch(handleError(response.data))
        }
    }

    const handleAddTour = () => {
        setIsAddForm(true)
        const newTour = {
            name: null,
            month: moment().format('MM'),
            year: moment().format('YYYY'),
            newData: true,
            data: []
        }

        setCountryTours([...countryTours, newTour])
    }

    const handleUpdateCountryTour = (values, index) => {
        const newCountryTours = [...countryTours]

        newCountryTours[index]['name'] = values?.name
        newCountryTours[index]['month'] = values?.month
        newCountryTours[index]['year'] = values?.year

        setCountryTours(newCountryTours)
    }

    const handleCancel = (index) => {
        const newCountryTours = [...countryTours]

        newCountryTours.splice(index, 1)

        setCountryTours(newCountryTours)
    }

    const handleUpdateItems = (index, data) => {
        const newCountryTours = [...countryTours];  // Buat salinan array countryTours
    
        // Buat salinan objek pada index yang ingin diubah
        const updatedTour = { ...newCountryTours[index] };
    
        // Hapus properti newData jika ada
        if (updatedTour.hasOwnProperty('newData')) {
            delete updatedTour['newData'];
        }
    
        // Transformasi data menjadi array baru
        const newData = data.map(item => ({
            id: item.id,
            name: item.name,
            month: item.month,
            year: item.year,
            total_nights: item.total_nights,
            total_days: item.total_days,
            price: item.price,
        }));
    
        // Update data di dalam objek salinan
        updatedTour['data'] = newData;
    
        // Masukkan objek yang telah diubah ke dalam array
        newCountryTours[index] = updatedTour;
    
        // Update state dengan array yang baru
        setCountryTours(newCountryTours);
    };
    

    useEffect(() => {
        onHandleCountryTours()
    }, [])

    return (
        <>
            <AlertPopup
                open={showConfirmDelete}
                handleClose={() => setShowConfirmDelete(false)}
                handleContinue={() => handleDelete()}
                loading={isDeleting}
                subtitle="Apakah anda yakin menghapus negara tour ini?"
            />

            <div className="flex flex-row">
                <div className="w-[25%]">
                    <div className="text-sm font-semibold">Negara Tour</div>
                </div>
                <div className="w-[75%] flex flex-col gap-4">
                {countryTours.map((countryTour, index) => (
                    <>
                        <TourCountryForm 
                            index={index}
                            countryTour={countryTour}
                            onDelete={() => handleConfirmDelete(index)}
                            onCancel={() => handleCancel(index)}
                            onUpdateParent={(values) => handleUpdateCountryTour(values, index)}
                            onUpdateItem={(index, data) => handleUpdateItems(index, data)}
                            />
                    </>
                ))}
                
                <Buttons
                    onClick={handleAddTour}
                    text="+ Tambah Negara Tour"
                    customClass="btn-outline btn-medium mt-4 !py-4"
                />
                </div>
            </div>  
        </>
    )
}

export default CountryTourUpdatePrice