import { useEffect, useState } from "react";
import { IDRFormater } from "utils/helpers";

const { default: InputsCurrency } = require("components/form/inputsCurrency");

const DiscountAgentForm = ({
  onChange,
  defaultValue,
  totalPax
}) => {

  const [value, setValue] = useState(0);
  const [totalDiscountAgent, setTotalDiscountAgent] = useState(0);

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    
    if(typeof value === 'undefined') {
      setValue(0)
    }
    

    setTotalDiscountAgent(value * totalPax)
  }, [value, totalPax])

  return (
    <>
      <InputsCurrency 
          defaultValue={defaultValue}
          label="Diskon Agen per Pax (Akan Mengurangi Fee Agen)"
          onChange={(e) => {
            setValue(e)
            onChange(e)
            if(typeof e === 'undefined') {
              e = 0
            }
            setTotalDiscountAgent(e * totalPax)
          }}
          />

        <div className="p-4 flex justify-between items-center bg-red-5 text-red-3 rounded-2xl mt-4">
          <span className="text-sm">
            Total nominal diskon yang diambil dari fee agen 
          </span>
          <div className="text-red-3 font-semibold text-lg">
            - {IDRFormater(totalDiscountAgent)}
          </div>
        </div>
    </>
  );
};

export default DiscountAgentForm