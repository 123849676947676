import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import Buttons from "../../../../components/buttons";
import Cards from "../../../../components/cards";
import Inputs from "../../../../components/form/inputs";
import ImageField from "../../../../components/image_field";
import ColorPicker from "../../../../components/color_picker";

import IconNamaCabang from "../../../../assets/icons/Nama-Cabang.svg";
import IconWebsite from "../../../../assets/icons/Website.svg";
import Scroll from "components/scroll";
import Badge from "components/badge";
import Switch from "components/form/switch";

const Informasi = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setCurrentStep,
  isLoading,
  errors,
  touch,
  formik,
  allStartings,
}) => {
  const [isError, setIsError] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");

  const handleSelectStarting = (startingId) => {
    const _tempControlStartings = values.control_startings

    if (_tempControlStartings.includes(startingId)) {
      setFieldValue("control_startings", _tempControlStartings.filter((i) => i !== startingId))
    } else {
      setFieldValue("control_startings", [..._tempControlStartings, startingId])
    }

  }

  return (
    <Cards containerClass="!p-0 flex flex-col flex-1 !shadow-soft overflow-hidden">
      <Scroll height="calc(100vh - 300px)">
        <div className="p-[20px]">
          <Grid container spacing={2}>
            <Grid item md={5} className="flex flex-1 flex-col !grow">
              <ImageField
                // customWrapper="flex justify-center items-center"
                // customContainer="flex flex-col flex-1"
                label="Foto Cabang"
                error={isError}
                isMulti={false}
                name={"map"}
                width="246px"
                height="246px"
                setFile={(file) => {
                  setFieldValue("images", file?.path);
                }}
              />
              {/* <div className="mt-2 text-[#4F4F4F] text-[12px]">
                File: maks. 10 Mb. Ekstensi: .JPG .JPEG .PNG
              </div> */}
            </Grid>
            <Grid item md={7}>
              <Inputs
                id="name"
                name="name"
                label="Nama Cabang"
                containerClass="!text-sm"
                placeholder="Nama Cabang"
                value={values?.name}
                onChange={handleChange}
                onBlur={handleBlur}
                icon={IconNamaCabang}
                error={touch.name && errors.name ? errors.name : ""}
              />
              <Inputs
                id="url_website"
                name="url_website"
                label="URL Website"
                containerClass="mt-[20px] !text-sm"
                placeholder="Website"
                value={values?.url_website}
                onChange={handleChange}
                onBlur={handleBlur}
                icon={IconWebsite}
                error={
                  touch.url_website && errors.url_website
                    ? errors.url_website
                    : ""
                }
              />
              <ColorPicker
                name="color"
                containerClass="mt-[20px]"
                customWrapper=" text-center"
                customStyle={values?.color !== "" ? values?.color : selectedColor}
                placeholder={values?.color !== "" ? values?.color : "Pilih Warna"}
                label="Pilih Warna"
                onChange={(color) => {
                  setSelectedColor(color.hex);
                  setFieldValue("color", color.hex);
                }}
                color={values?.color !== "" ? values?.color : selectedColor}
              />
            </Grid>
          </Grid>
          <div className="mt-4">
            <span className="font-semibold text-sm">Control Starting</span>
            <div className="mt-4">
              <Grid container spacing={4}>
                {allStartings.data?.map((item, index) => (
                  <>
                    <Grid item md={6}>
                      <div className={`${values.control_startings?.includes(item?.id_starting) ? 'bg-red-5' : 'bg-white'} p-3 rounded-lg shadow-soft flex justify-between items-center`}>
                        <Badge
                          color={item?.color}
                          backgroundColor={item?.color_bg}
                          label={item?.name}
                          />
                        <Switch
                          onChange={() => handleSelectStarting(item?.id_starting)}
                          checked={values.control_startings?.includes(item?.id_starting)}
                          />
                      </div>
                    </Grid>
                  </>
                ))}
              </Grid>
            </div>
          </div>
        </div>

        {/* <div className="divider"></div> */}
        {/* <div className="border-t mt-auto">
        </div> */}
      </Scroll>
      <div className="p-4 flex flex-row justify-end gap-x-4 border-t bg-white m-0">
          <Buttons
            text="Kembali"
            customClass="btn-outline btn-large"
            loading={isLoading ? true : false}
            onClick={() => setCurrentStep(1)}
          />
          <Buttons
            text="Selanjutnya"
            customClass="btn-primary btn-large"
            loading={isLoading ? true : false}
            onClick={() => setCurrentStep(1)}
            disabled={!(formik.dirty && formik.isValid)}
          />
        </div>
    </Cards>
  );
};

export default React.memo(Informasi);
