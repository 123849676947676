import styled from "styled-components";

const ButtonStyle = styled.div`
  .custom-btn {
    height: auto;
    font-weight: 600;
    border: 0;
    width: 100%;

    span {
      color: white;
    }
  }

  .ant-wave {
    border: 0;
    box-shadow: 0;
  }

  .btn-small {
    padding: 8px 12px;
    font-size: 12px;
    border-radius: 8px;
  }

  .btn-medium {
    padding: 12px 48px;
    font-size: 14px;
    border-radius: 12px;
  }

  .btn-large {
    padding: 16px 48px;
    font-size: 16px;
    border-radius: 16px;
  }

  .btn-primary {
    background: linear-gradient(180deg, #ea3634 0%, #94110f 100%);

    &:hover {
      background: linear-gradient(180deg, #de1a17 0%, #6f0d0b 100%)!important;
      color: white!important;
      span {
        color: white!important;
      }
    }

    &:active {
      color: white !important;
      background: linear-gradient(180deg, #A50E0B 0%, #4F0A09 100%);
      box-shadow: none;
    }

    &.disabled,
    &.loading {
      background: #FF9C9A !important;
      cursor: not-allowed;
    } 

    &:disabled {
      background: #FF9C9A !important;
      cursor: not-allowed;
    }


  }

  .btn-secondary {
    background: #ffdcdc;

    &:hover {
      background: var(--Primary-Red-Red-5, #FFDCDC)!important;
      span {  
        color: #b80006;
      }
    }

    span {
      color: #e82320;
    }

    &.disabled,
    &.loading {
      background: #ffdcdc !important;
      cursor: not-allowed;

      span {
        color: #ff9c9a !important;
      }

      &:hover,
      &:active {
        color: #e82320 !important;
      }
    }
  }

  .btn-outline {
    border: 1px solid #e82320;
    background: #fff;

    &:hover {
      border: 1px solid #b80006!important;
      background: #fff!important;
      color: #970C10!important;
      span {
        // color: #b80006;
        color: #970C10!important;
      }
    }

    &:active {
      border: 1px solid #970c10;

      span {
        color: #970c10;
      }
    }

    span {
      color: #e82320;
    }

    &.disabled,
    &.loading {
      background: #fff!important;
      border: 1px solid #ff9c9a;
      color: #ff9c9a;
      cursor: not-allowed;

      span {
        color: #ff9c9a !important;
      }

      &:hover,
      &:active {
        color: #ff9c9a !important;
      }
    }
  }

  .btn-outline-light {
    border: 1px solid #E0E0E0;
    background: #fff;

    &:hover {
      border: 1px solid #E0E0E0!important;
      background: #fff;

      span {
        color: #333;
      }
    }

    &:active {
      border: 1px solid #E0E0E0;

      span {
        color: #333;
      }
    }

    span {
      color: #333;
    }

    &.disabled,
    &.loading {
      background: #fff!important;
      border: 1px solid #E0E0E0;
      color: #ff9c9a;
      cursor: not-allowed;

      span {
        color: #ff9c9a !important;
      }

      &:hover,
      &:active {
        color: #ff9c9a !important;
      }
    }
  }

  .btn-brown-light {
    padding: 4px 12px;
    background: linear-gradient(
      180deg,
      rgba(173, 122, 45, 0.15) 0%,
      rgba(153, 4, 0, 0.15) 100%
    );

    span {
      background: linear-gradient(
        34.99deg,
        #151515 17.46%,
        #3d3d3d 58.62%,
        #595959 87.32%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &:hover,
    &:active {
      background: linear-gradient(
        180deg,
        rgba(173, 122, 45, 0.3) 0%,
        rgba(153, 4, 0, 0.3) 100%
      )!important;
    }
  }

  .btn-white-light {
    padding: 4px 12px;
    background: rgba(255, 255, 255, 0.5);

    span {
      background: linear-gradient(
        34.99deg,
        #151515 17.46%,
        #3d3d3d 58.62%,
        #595959 87.32%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .btn-green {
    border: 1px solid #25d366;
    background: transparent;

    &:hover,
    &:active {
      border: 1px solid #25d366;

      span {
        color: #25d366;
      }
    }

    span {
      color: #25d366;
    }
  }
`;

export default ButtonStyle;
