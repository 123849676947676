import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import IconArrowDown from "assets/icons/arrow-down.svg";

import "./index.css";

const DropdownCustom = ({ 
  children, 
  dropdownRender, 
  onClick, 
  placement,
  icon,
  iconPosition, 
  label
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  const [containerId, setContainerId] = useState(null);
  const [containerSelectedId, setContainerSelectedId] = useState(null);

  const [dropdownPosition, setDropdownPosition] = useState({
    top:0,
    left:0
  });
  const [differenceDropdownPositionTop, setDifferenceDropdownPositionTop] = useState(0);

  const handleDropdownRender = (el) => {
    if (isDropdownVisible) {
      return ReactDOM.createPortal(
        <div id="dropdown-custom-dropdown" ref={dropdownRef}>
          {el}
        </div>,
        document.body
      );
    }
    return null;
  };

  const toggleDropdown = async () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  const handleClick = async (event) => {
    onClick?.(event);
    await handleGetContainerSelectedId(event);
    toggleDropdown();
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownVisible(false);
    }
  };

  const handleSetPositionDropdown = (action = 'click') => {
    const containerElement = containerRef.current;
    
    if (containerElement) {

      const containerRect = containerElement.getBoundingClientRect();
      let dropdownElement = dropdownRef.current;

      if(dropdownElement) {
        const dropdownRect = dropdownElement.getBoundingClientRect();

        let newPosition = {
          top: 0,
          left: 0
        };

        if(placement === "centerLeft") {
          newPosition = handlePositionDropdownCenterLeft(containerRect, dropdownRect);
          console.log(newPosition)
          newPosition.top = handleFixedPositionDropdownY(newPosition.top, containerRect.top, dropdownRect, action);

        }

        if(placement === "centerRight") {
          newPosition = handlePositionDropdownCenterRight(containerRect, dropdownRect);
        }

        if(placement === "rightBottom") {
          newPosition = handlePositionDropdownRightBottom(containerRect, dropdownRect);
        }

        setDropdownPosition(newPosition);

        dropdownElement.style.top = newPosition.top + "px";
        dropdownElement.style.left = newPosition.left + "px";
      }
      
    }

  };


  const handleFixedPositionDropdownY = (dropdownPositionTop, containerPositionTop, dropdownRect, action) => {
    const windowHeight = window.innerHeight;
    const positionBottom = dropdownPositionTop + dropdownRect.height;
    
    if(positionBottom > windowHeight && containerPositionTop < windowHeight) {
      const difference = positionBottom - windowHeight;
      const newPositionTop = dropdownPositionTop - (difference + 16)
      return newPositionTop
    }

    return dropdownPositionTop
  }

  const handlePositionDropdownCenterLeft = (containerRect, dropdownRect) => {
    const newTop = containerRect.top - (dropdownRect.height / 2);
    const newLeft = containerRect.left - dropdownRect.width - 16;

    const newPosition = {
      top: newTop,
      left: newLeft
    }

    return newPosition

  };

  const handlePositionDropdownCenterRight = (containerRect, dropdownRect) => {
    const newTop = containerRect.top - (dropdownRect.height / 2);
    const newLeft = containerRect.right + 16;

    return {
      top: newTop,
      left: newLeft
    }

  };

  const handlePositionDropdownRightBottom = (containerRect, dropdownRect) => {
    const newTop = containerRect.bottom + 8;
    const newLeft = containerRect.right - dropdownRect.width;

    return {
      top: newTop,
      left: newLeft
    }

  };

  const handleGetContainerSelectedId = (event) => {
    let containerElement = event.target.closest('.dropdown-custom-container');
    const position = handlePositionContainer(containerElement);
    setContainerSelectedId(position.top + position.left);
  };

  const handleGetContainerId = () => {
    let containerElement = containerRef.current;
    const position = handlePositionContainer(containerElement);
    setContainerId(position.top + position.left);
  };

  const handlePositionContainer = (containerElement) => {
    let top = 0, left = 0;

    while (containerElement) {
      top += containerElement.offsetTop || 0;
      left += containerElement.offsetLeft || 0;
      containerElement = containerElement.offsetParent;
    }

    return {
      top: top,
      left: left
    };
  };

  useEffect(() => {
    handleGetContainerId();
  }, []);

  useEffect(() => {
    if (isDropdownVisible) {
      handleSetPositionDropdown();
    }
  }, [isDropdownVisible]);

  useEffect(() => {
    // console.log(containerSelectedId, containerId)
    // if (containerSelectedId === containerId) {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    // }
  }, [isDropdownVisible]);

  useEffect(() => {
    const handleScroll = (e) => {
      handleSetPositionDropdown('scroll');
    };

    // Add scroll event listener to the body and all child elements
    const body = document.querySelector("body");
    body.addEventListener("scroll", handleScroll, true);

    return () => {
      body.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  return (
    <>
      <div className="dropdown-custom-container inline-flex" onClick={handleClick} ref={containerRef}>
        {children ? children : (
          <>
            <div 
              className="border py-[10px] px-3 rounded-lg cursor-pointer flex justify-between items-center">
                <div className="flex items-center gap-2 mr-2">
                  {icon && iconPosition === "left" && (
                    <>
                      <img 
                        className="w-[16px] h-[16px]"
                        src={icon} />
                    </>
                  )}
                  {label}
                  {icon && iconPosition === "right" && (
                    <>
                      <img src={icon} />
                    </>
                  )}
                </div>
                <div className={`${isDropdownVisible ? 'rotate-180 transition-all duration-300' : 'rotate-0 transition-all duration-300'}`}>
                  <img 
                    className="w-[24px] h-[24px]" 
                    src={IconArrowDown} alt="arrow-down" />
                </div>
            </div>
          </>
        )}
      </div>
      {handleDropdownRender(dropdownRender())}
    </>
  );
};

DropdownCustom.propTypes = {
  children: PropTypes.any,
  dropdownRender: PropTypes.func,
  onClick: PropTypes.func,
  placement: PropTypes.string,
  icon: PropTypes.any,
  iconPosition: PropTypes.string,
  label: PropTypes.string,
};

DropdownCustom.defaultProps = {
  children: null,
  dropdownRender: () => {},
  onClick: null,
  placement: "centerLeft",
  icon: null,
  iconPosition: "left",
  label: "Dropdown",
};

export default DropdownCustom;
