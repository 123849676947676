import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { useDispatch } from "react-redux";

import Buttons from "components/buttons";
import { IDRFormater } from "utils/helpers";

import * as Yup from "yup";
import { useFormik } from "formik";
import { updateDiscountAgentService } from "utils/api/booking";
import DiscountAgentForm from "pages/tagihan/components/DiscountAgentForm";

const UpdateDiscountAgent = ({
  onSuccess
}) => {
  const dispatch = useDispatch();

  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { bookingDetail } = useSelector((state) => state.booking);

  const form = useFormik({
    initialValues: {
      nominal_discount: bookingDetail?.discount_agent_per_pax
    },
    validationSchema: Yup.object().shape({
      "nominal_discount": Yup.number().required("Nominal Diskon harus diisi"),
    })
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    const res = await updateDiscountAgentService(params.orderNumber, form.values);

    if (res.status === 200) {
      setIsLoading(false);
      dispatch(handleSuccess(res));
      onSuccess();
    } else {
      setIsLoading(false);
      dispatch(handleError(res?.data));
    }
  };

  return (
    <div className="border rounded-2xl">
      <div className="p-4">
        <DiscountAgentForm
          defaultValue={form.values.nominal_discount}
          onChange={(e) => {
            form.setFieldValue("nominal_discount", e)
          }}
          totalPax={bookingDetail?.total_pax}
          />
      </div>
      <div className="border-t p-4 flex justify-end">
        <Buttons
          loading={isLoading}
          disabled={!(form.isValid && form.dirty)}
          text="Simpan"
          customClass="btn-primary btn-large"
          onClick={handleSubmit}
          />
      </div>
    </div>
  );
};

export default UpdateDiscountAgent