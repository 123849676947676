import React, { useState, useCallback, useEffect } from "react";
import PropType from "prop-types";
import Buttons from "../../../../../../components/buttons";
import InfoDasar from "./PopupMenu/infoDasar";
import JumlahSeatBus from "./PopupMenu/jumlahSeatBus";
import Manasik from "./PopupMenu/Manasik";
import PembimbingGuide from "./PopupMenu/pembimbingGuide";
import SyaratKetentuan from "./PopupMenu/syaratKetentuan";
import "./styles/index.scss";
import { useDispatch, useSelector } from "react-redux";
import { detailById } from "stores/actions/general";
import PublishSetting from "./PopupMenu/publishSetting";

const PopupContent = ({
  data,
  bus,
  isDetail,
  closePopup,
  setSuccessEdit,
  listPembimbing,
  listGuide,
  isOpenPopup
}) => {
  const dispatch = useDispatch();
  const { byDetail } = useSelector((state) => state.general);
  const [open, setOpen] = useState(false);
  const [opensecond, setOpensecond] = useState(false);
  const auth = useSelector(({auth}) => auth)

  const [clickedMenu, setClickedMenu] = useState("InfoDasar");

  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (byDetail.menu) {
      setClickedMenu(byDetail.menu);
    }
  }, [byDetail.menu]);

  const handleNextClick = () => {
    const menuItems = [
      "InfoDasar",
      "Syarat",
      "JumlahSeat",
      "PembimbingGuide",
      "Manasik",
    ];
    const currentIndex = menuItems.indexOf(clickedMenu);
    const nextIndex = (currentIndex + 1) % menuItems.length;
    setClickedMenu(menuItems[nextIndex]);
  };

  const handleMenuClick = (buttonText) => {
    setClickedMenu(buttonText);
  };

  const getContent = () => {
    switch (clickedMenu || byDetail?.menu) {
      case "InfoDasar":
        return (
          <InfoDasar
            info={data}
            closePopup={closePopup}
            setSuccessEdit={setSuccessEdit}
          />
        );
      case "Syarat":
        return (
          <SyaratKetentuan
            data={data}
            closedPopup={closePopup}
            isOpenPopup={isOpenPopup}
            setSuccessEdit={setSuccessEdit}
          />
        );
      case "JumlahSeat":
        return (
          <JumlahSeatBus
            data={bus}
            closePopup={closePopup}
            setSuccessEdit={setSuccessEdit}
          />
        );
      case "PembimbingGuide":
        return (
          <PembimbingGuide
            info={data}
            closePopup={closePopup}
            setSuccessEdit={setSuccessEdit}
            selectedPembimbing={listPembimbing}
            selectedGuide={listGuide}
          />
        );
      case "Manasik":
        return <Manasik data={data} />;
      case "Atur Publish":
        return <PublishSetting />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-[550px]">
      <div className="flex flex-row w-full gap-x-[20px]">
        {auth.user?.access_data === 'all_branches' && (
          <>
            <div className="p-[16px] rounded-lg  @apply shadow-[0px_4px_80px_-4px_#0000000A] !w-[205px] box-border h-[550px]">
              <div className="flex flex-col items-start w-full gap-y-4">
                <div
                  className={`${
                    clickedMenu === "InfoDasar"
                      ? "text-[14px]  text-[#B80006] font-semibold"
                      : "text-[14px] text-[#828282] cursor-pointer font-normal"
                  }`}
                  onClick={() => {
                    handleMenuClick("InfoDasar");
                    dispatch(detailById({}));
                    closePopup(true);
                  }}
                >
                  <div
                    className={` ${
                      clickedMenu === "InfoDasar"
                        ? "text-[14px] text-[#B80006] font-semibold"
                        : "text-[14px] text-[#828282] cursor-pointer font-normal "
                    } `}
                  >
                    Info Dasar
                  </div>
                </div>
                <div
                  className={`${
                    byDetail?.menu === "Syarat"
                      ? "text-[14px]  text-[#B80006] font-semibold"
                      : "text-[#828282] cursor-pointer font-normal text-[14px]"
                  }`}
                  onClick={() => {
                    closePopup(false);
                    handleMenuClick("Syarat");
                    dispatch(
                      detailById({
                        status: true,
                        menu: "Syarat",
                        type: 1,
                        showAll: true,
                      })
                    );
                  }}
                >
                  <div
                    className={` ${
                      clickedMenu === "Syarat"
                        ? "text-[14px]  text-[#B80006] font-semibold"
                        : "text-[#828282] cursor-pointer font-normal text-[14px] "
                    } `}
                  >
                    Syarat dan Ketentuan
                  </div>
                </div>
                <div
                  className={`${
                    clickedMenu === "JumlahSeat"
                      ? "text-[14px]  text-[#B80006] font-semibold"
                      : "text-[#828282] cursor-pointer font-normal text-[14px]"
                  }`}
                  onClick={() => {
                    handleMenuClick("JumlahSeat");
                    dispatch(detailById({}));
                    closePopup(true);
                  }}
                >
                  <div
                    className={` ${
                      clickedMenu === "JumlahSeat"
                        ? "text-[14px]  text-[#B80006] font-semibold"
                        : "text-[#828282] cursor-pointer font-normal text-[14px] "
                    } `}
                  >
                    Jumlah Seat Bus
                  </div>
                </div>
                <div
                  className={`${
                    clickedMenu === "PembimbingGuide"
                      ? "text-[14px]  text-[#B80006] font-semibold"
                      : "text-[#828282] cursor-pointer font-normal text-[14px] "
                  }`}
                  onClick={() => {
                    handleMenuClick("PembimbingGuide");
                    dispatch(detailById({}));
                    closePopup(true);
                  }}
                >
                  <div
                    className={` ${
                      clickedMenu === "PembimbingGuide"
                        ? "text-[14px]  text-[#B80006] font-semibold"
                        : "text-[#828282] cursor-pointer font-normal text-[14px] "
                    } `}
                  >
                    Pembimbing & Guide
                  </div>
                </div>
                {/* <div
                  className={`${
                    clickedMenu === "Manasik"
                      ? "text-[14px]  text-[#B80006] font-semibold"
                      : "text-[#828282] cursor-pointer font-normal text-[14px] "
                  }`}
                  onClick={() => {
                    handleMenuClick("Manasik");
                    dispatch(detailById({}));
                    closePopup(true);
                  }}
                >
                  <div
                    className={` ${
                      clickedMenu === "Manasik"
                        ? "text-[14px]  text-[#B80006] font-semibold"
                        : "text-[#828282] cursor-pointer font-normal text-[14px] "
                    } `}
                  >
                    Manasik
                  </div>
                </div> */}
                <div
                  className={`${
                    clickedMenu === "Atur Publish"
                      ? "text-[14px]  text-[#B80006] font-semibold"
                      : "text-[#828282] cursor-pointer font-normal text-[14px] "
                  }`}
                  onClick={() => {
                    handleMenuClick("Atur Publish");
                    dispatch(detailById({}));
                    closePopup(true);
                  }}
                >
                  <div
                    className={` ${
                      clickedMenu === "Atur Publish"
                        ? "text-[14px]  text-[#B80006] font-semibold"
                        : "text-[#828282] cursor-pointer font-normal text-[14px] "
                    } `}
                  >
                    Setting Publish
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="rounded-lg w-full py-4 @apply shadow-[0px_4px_80px_-4px_#0000000A]">
          <div
            className={`w-full overflow-auto relative ${
              !isDetail && " p-[16px]"
            }`}
          >
            {getContent()}
          </div>
          {!isDetail ? (
            clickedMenu !== "Manasik" ? (
              <div className="flex flex-col justify-end border-t rounded-lg py-[8px] px-[20px]">
                <Buttons
                  text="Selanjutnya"
                  customClass="btn-primary btn-medium !w-[186px] float-right"
                  onClick={() => handleNextClick()}
                />
              </div>
            ) : (
              <div className="flex flex-col justify-end border-t rounded-lg py-[8px] px-[20px]">
                <div className="flex flex-row justify-end gap-[8px]">
                  <Buttons
                    text="Simpan Draft"
                    customClass="btn-secondary btn-medium !bg-[#FFDCDC] btn-medium !w-[186px] float-right"
                    onClick={() => handleNextClick()}
                  />
                  <Buttons
                    text="Publish"
                    customClass="btn-primary btn-medium !w-[186px] float-right"
                    onClick={() => handleNextClick()}
                  />
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

PopupContent.propTypes = {
  data: PropType.object,
  bus: PropType.array,
  isDetail: PropType.bool,
  closePopup: PropType.func,
  setSuccessEdit: PropType.func,
  isOpenPopup: PropType.bool
};

export default React.memo(PopupContent);
