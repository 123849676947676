
import PropTypes from "prop-types";

import './styles.scss'

import IconChecklistRound from "assets/icons/Checklist_Round.svg";
import IconRadioRound from "assets/icons/RadioButtonMaroon.svg";

const Choice = ({
  name,
  label,
  id,
  value,
  checked,
  onChange,
  type,
  children,
  customClass,
  onClick
}) => {
  return (
    <>
      <div className={`choice choice-${type}`}>
        <input 
          type={type}
          name={name}
          id={id}
          {...(checked && { checked: true })}
          value={value}
          onChange={onChange}
          onClick={onClick}
          />
        <label for={id} className={`${customClass}`}>
          <div className="choice-input-custom">
            <div className="choice-input-unchecked"></div>
            <div className="choice-input-checked">
              {type == 'checkbox' ? <img src={IconChecklistRound} /> : <img src={IconRadioRound} />}
            </div>
          </div>
          {!children ? (
            <>
              <div className="choice-label">{label}</div>
            </>
          ) : (
            <>
              <div className="choice-label-custom w-full">{children}</div>
            </>
          )}

        </label>
      </div>
    </>
  )
}

Choice.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string
};

Choice.defaultProps = {
  name: "",
  label: "",
  id: "",
  value: "",
  checked: false,
  onChange: () => {},
  onClick: () => {},
  type: "radio"
};

export default Choice