
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllRequestPaket, confirmationRequestPaket } from "utils/api/requestPaket"

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import Badge from "components/badge";
import Scroll from "components/scroll";
import Accordions from "components/accordions";
import Rating from "components/rating";
import Buttons from "components/buttons";
import AlertPopup from "components/popup/alert";

import IconJamPasir from "assets/icons/Jam-Pasir.svg";
import IconProfile from "assets/icons/Icon-profile.svg";
import IconAirplane from "assets/icons/icon-airplane.svg";
import IconCalender from 'assets/icons/icon-calendar-maroon.svg';
import IconClockMaroon from 'assets/icons/icon-clock-maroon.svg'
import IconTour from 'assets/icons/tour_active.svg'
import IconFee from 'assets/icons/fee.svg'
import IconQuad from 'assets/icons/Quad.svg'
import IconTriple from 'assets/icons/Triple.svg'
import IconDouble from 'assets/icons/Double.svg'
import IconCheckCircle from 'assets/icons/icon-check-circle.svg'
import IconHotel from 'assets/icons/Hotel Akomodasi1.svg'
import IconAddress from "assets/icons/colored/Address.svg";
import IconNight from 'assets/icons/icon-night.svg'
import IconTipeKamar from 'assets/icons/icon-tipe-kamar.svg'
import IconMentorMaroon from 'assets/icons/icon-mentor-maroon.svg'
import IconFree from 'assets/icons/icon-free.svg'

import Masonry from 'react-layout-masonry';
import moment from "moment";
import { handleName, IDRFormater, toCapital } from "utils/helpers";
import Spinner from "components/spinner";
import Avatar from "components/avatar";

const RequestPackage = ({
  perPage,
  currentPage,
  setTotal,
  isReload,
  setIsReload,
  confirmation
}) => {

  const dispatch = useDispatch();
  const [allPaket, setAllPaket] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showConfirmPopup, setShowConfirmPopup] = useState(false)

  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const [selectedPaket, setSelectedPaket] = useState(null);
  const [statusConfirmationSelected, setStatusConfirmationSelected] = useState(null);
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);

  const getDataAllRequestPaket = async () => {

    setIsLoading(true);

    let payload = {
      per_page: perPage || 10,
      page: currentPage
    }

    if(confirmation) {
      payload = {
        ...payload,
        action: 'confirmation'
      }
    }

    const response = await getAllRequestPaket(payload);
    setIsLoading(false);
    
    if(response.status == 200) {
      setAllPaket(response.data.data);
      setTotal(response.data.total);
    } else {
      dispatch(handleError(response.data));
    }
  }

  const handleRenderStatus = (status) => {
    if (status == 0) {
      return (
        <>
          <Badge
            label="Waiting"
            color="#FE9A00"
            backgroundColor="#FCDE9C"
            />
        </>
      )
    }

    if (status == 1) {
      return (
        <>
          <Badge
            label="Approved"
            color="#1A7431"
            backgroundColor="#92E6A7"
            />
        </>
      )
    }

    if (status == 2) {
      return (
        <>
          <Badge
            label="Rejected"
            color="#E82320"
            backgroundColor="#FFDCDC"
            />
        </>
      )
    }
  }

  const handleConfirm = async () => {

    const payload = {
      'package_request_id': selectedPaket,
      'status': statusConfirmationSelected
    }

    setIsConfirmationLoading(true)
    const response = await confirmationRequestPaket(payload)
    setIsConfirmationLoading(false)
    setShowConfirmPopup(false)

    if(response.status == 200) {
      dispatch(handleSuccess(response))
      getDataAllRequestPaket()
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleConfirmation = (id, status) => {
    setShowConfirmPopup(true)
    setSelectedPaket(id)
    setStatusConfirmationSelected(status)
  }

  // const handleTourCountries = (dataTour) => {
  //   return dataTour.map(item => item.name).join(", ");
  // }

  // useEffect(() => {
  //   getDataAllRequestPaket();
  // }, [])

  useEffect(() => {
    getDataAllRequestPaket();
  }, [currentPage, perPage])

  useEffect(() => {
    if(isReload) {
      getDataAllRequestPaket();
      setIsReload(false)
    }
  }, [isReload])

  return (
    <>
      <AlertPopup
          open={showConfirmPopup}
          handleClose={() => setShowConfirmPopup(false)}
          handleContinue={() => handleConfirm()}
          loading={isConfirmationLoading}
          subtitle={`Apakah anda yakin ${statusConfirmationSelected == 1 ? "menerima" : "menolak"} paket request ini?`}
        />
      <div>
      {isLoading && (
        <div className="content_list !flex !justify-center items-center !w-full">
          <Spinner width="56px" height="56px" />
        </div>
      )}
      {!isLoading && allPaket && allPaket.length > 0 ? (
          <div>
            <Scroll
            height="calc(100vh - 264px)"
            customClass="bg-[#FBF3F2]"
            >

              <div className="p-4">
                <Masonry
                  columns={3}
                  gap={16}
                  className="w-full"
                >
                  {allPaket.map((item, index) => (
                    <div className="bg-white shadow-soft p-4 rounded-2xl flex flex-col gap-4" key={index}>
                      <div className="flex justify-between items-center">
                        <div className="text-xs font-semibold">
                          {confirmation ? 'Request' : 'Cek'} Paket {String(item.id).padStart(2, '0')}
                        </div>
                        {handleRenderStatus(item.status)}
                      </div>

                      {confirmation && (
                        <div className="bg-gray-5 p-4 rounded-2xl flex gap-4 items-center">
                          <Avatar customClass={"w-[32px] h-[32px]"} value={item.requester} />
                          <div className="flex flex-col gap-1">
                            <div className="font-semibold">
                              {handleName(item?.requester?.title, item?.requester?.name)}
                            </div>
                            <div className="flex items-center gap-2">
                              <Badge 
                                color={item.requester.color_text_role}
                                backgroundColor={item.requester.color_bg_role}
                                label={item.requester.nama_role}
                                />
                              <Badge 
                                color={item.requester.color_text_cabang}
                                backgroundColor={item.requester.color_bg_cabang}
                                label={item.requester.nama_cabang}
                                />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="bg-gray-5 p-4 rounded-2xl flex flex-col gap-4">
                        <div className="flex items-center">

                          <div className="flex gap-2 items-center w-2/3">
                            <div>
                              <img src={IconProfile} className="w-4 h-4" alt="jam_pasir" />
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="text-xs">Jumlah Pax</div>
                              <div className="text-xs font-semibold">{item.total_pax}</div>
                            </div>
                          </div>

                          <div className="w-2/3"></div>

                          <div className="flex gap-2 items-center w-2/3">
                            <div>
                              <img src={IconClockMaroon} alt="jam_pasir" />
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="text-xs">Total Hari</div>
                              <div className="text-xs font-semibold">{item?.total_days} Hari</div>
                            </div>
                          </div>

                          {/* <div className="flex gap-2 items-center w-2/3">
                            <div>
                              <img src={IconAirplane} alt="jam_pasir" />
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="text-xs">{toCapital(item?.airplane_class)} Class</div>
                              <div className="text-xs font-semibold">{item?.airplane?.name ?? '-'}</div>
                            </div>
                          </div> */}

                        </div>
                        <div className="flex items-center">

                          <div className="flex gap-2 items-center w-2/3">
                            <div>
                              <img src={IconCalender} className="w-4 h-4" alt="jam_pasir" />
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="text-xs">Keberangkatan</div>
                              <div className="text-xs font-semibold">{moment(`${item.year}-${item.month}`).format('MMMM YYYY')}</div>
                            </div>
                          </div>

                          <div className="w-2/3"></div>

                          <div className="flex gap-2 items-center w-2/3">
                            <div>
                              <img src={IconFee} className="w-4 h-4" alt="jam_pasir" />
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="text-xs">Fee</div>
                              <div className="text-xs">
                                <span className="text-[#1271CE]  font-semibold">
                                  {IDRFormater(item.branch_fee ?? 0)}
                                </span>
                                <span> /pax</span>
                              </div>
                            </div>
                          </div>
                          
                          {/* <div className="flex gap-2 items-center w-2/3">
                            <div>
                              <img src={IconClockMaroon} alt="jam_pasir" />
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="text-xs">Total Hari</div>
                              <div className="text-xs font-semibold">{item?.total_days} Hari</div>
                            </div>
                          </div> */}

                        </div>
                        <div className="flex items-center">

                          <div className="flex gap-2 items-center w-2/3">
                            <div>
                              <img src={IconMentorMaroon} className="w-4 h-4" alt="jam_pasir" />
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="text-xs">Pembimbing</div>
                              <div className="text-xs font-semibold">
                                {item.total_staff ? (
                                  <>{item.total_staff} Pax</>
                                ) : (<>-</>)}
                              </div>
                            </div>
                          </div>

                          <div className="w-2/3"></div>

                          <div className="flex gap-2 items-center w-2/3">
                            <div>
                              <img src={IconFree} className="w-4 h-4" alt="jam_pasir" />
                            </div>
                            <div className="flex flex-col gap-1">
                              <div className="text-xs">Ambil Free</div>
                              <div className="text-xs">
                                <span className="font-semibold">
                                  {item.total_take_free ? (
                                    <>{item.total_take_free} Pax</>
                                  ) : (<>-</>)}
                                </span>
                              </div>
                            </div>
                          </div>

                        </div>
                        
                        <div className="flex gap-2 items-center w-2/3">
                          <div>
                            <img src={IconTour} className="w-4 h-4" alt="jam_pasir" />
                          </div>
                          <div className="flex flex-col gap-1">
                            <div className="text-xs">Negara Tour</div>
                            <div className="text-xs font-semibold">
                              {item.tour ? (
                                <>{item.tour.name} - {item.tour.total_days} Hari, {item.tour.total_nights} Malam</>
                              ) : (<>-</>)}
                            </div>
                          </div>
                        </div>

                        <div className="flex gap-2 items-center w-2/3">
                          <div>
                            <img src={IconAirplane} alt="jam_pasir" />
                          </div>
                          <div className="flex flex-col gap-1">
                            <div className="text-xs">{toCapital(item?.airplane?.airplane_class)} Class</div>
                            <div className="text-xs font-semibold">{item?.airplane?.name ?? '-'} - In {item?.airplane?.airplane_in}, Out {item?.airplane?.airplane_out}</div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-gray-5 p-4 rounded-2xl flex flex-col gap-4">
                        
                        <div className="flex justify-center items-center">
                          <div className="flex flex-col gap-1 justify-center items-center">
                            <div className="flex items-center gap-1">
                              <div>
                                <img className="w-4 h-4" src={IconQuad} />
                              </div>
                              <div className="text-xs font-semibold">Quad</div>
                            </div>
                            <div className="flex gap-1 items-end">
                              <div className="font-semibold text-xl text-green-3">
                                {IDRFormater(item.quad_price)}
                              </div>
                              <span className="text-[#141414] font-normal text-base"> /pax</span>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <img src={IconTriple} className="w-4 h-4" />
                            <span className="font-semibold text-sm">Triple</span>
                          </div>
                          <div className="">
                            <span className="text-sm text-green-3 font-semibold">{IDRFormater(item.triple_price)}</span>
                            <span className="text-sm"> /pax</span>
                          </div>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <img src={IconDouble} className="w-4 h-4" />
                            <span className="font-semibold text-sm">Double</span>
                          </div>
                          <div className="">
                            <span className="text-sm text-green-3 font-semibold">{IDRFormater(item.double_price)}</span>
                            <span className="text-sm"> /pax</span>
                          </div>
                        </div>

                      </div>

                      <div className="bg-gray-5 rounded-2xl flex flex-col gap-4">
                        <Accordions
                          title="Opsi Layanan"
                          // arrowIcon={ArrowDown}
                          customClass="!rounded-2xl !shadow-none !bg-gray-5"
                          customSummaryClass="!border-b-0 summary-m-4"
                          customTitleClass="font-semibold text-xs"
                          customContentClass="!px-0 mx-4 !text-xs !pt-0 !pb-4"
                          content={
                            <>
                              <div className="flex flex-col gap-3">

                                {!!item.operational_airplane_domestic && (
                                  <>
                                    <div>
                                      <div className="flex justify-between mb-2">
                                        <div className="flex items-center gap-2">
                                          <img src={IconCheckCircle} />
                                          <div className="mt-[2px]">Pesawat Domestik</div>
                                        </div>
                                        <div className="font-semibold">{item.airplane_domestic.name}</div>
                                      </div>
                                      <div className="flex flex-col gap-3">
                                        <div className="ml-6">
                                          <div className="w-full">
                                            <Badge 
                                              color={item.airplane_domestic.starting_text_color}
                                              backgroundColor={item.airplane_domestic.starting_bg_color}
                                              label={item.airplane_domestic.starting_name}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {!!item.operational_transportation_highspeed_train && (
                                  <>
                                    <div>
                                      <div className="flex items-center gap-2">
                                        <img src={IconCheckCircle} />
                                        <div className="mt-[2px]">Transportasi Darat By Kereta Cepat</div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {!!item.operational_tour_city && (
                                  <>
                                    <div>
                                      <div className="flex items-center gap-2">
                                        <img src={IconCheckCircle} />
                                        <div className="mt-[2px]">City Tour Tambahan</div>
                                      </div>
                                      <ul className="list-disc ml-10 mt-2">
                                        {item.city_tours.map((data, idx) => (
                                          <li key={idx}>{data.name}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  </>
                                )}

                                {!!item.operational_equipment_additional && (
                                  <>
                                    <div>
                                      <div className="flex items-center gap-2">
                                        <img src={IconCheckCircle} />
                                        <div className="mt-[2px]">Perlengkapan Tambahan</div>
                                      </div>
                                      <ul className="list-disc ml-10 mt-2">
                                        {item.equipment_additionals.map((data, idx) => (
                                          <li key={idx}>{data.name}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  </>
                                )}

                                {!!item.operational_guide_service && (
                                  <>
                                    <div>
                                      <div className="flex items-center gap-2">
                                        <img src={IconCheckCircle} />
                                        <div className="mt-[2px]">Layanan Jasa Guide</div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {!!item.operational_transportation_bus && (
                                  <>
                                    <div>
                                      <div className="flex items-center gap-2">
                                        <img src={IconCheckCircle} />
                                        <div className="mt-[2px]">Transportasi Darat by Bus</div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {!!item.operational_equipment_completed && (
                                  <>
                                    <div>
                                      <div className="flex items-center gap-2">
                                        <img src={IconCheckCircle} />
                                        <div className="mt-[2px]">Perlengkapan Lengkap</div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {!!item.operational_manasik && (
                                  <>
                                    <div>
                                      <div className="flex items-center gap-2">
                                        <img src={IconCheckCircle} />
                                        <div className="mt-[2px]">Layanan Manasik</div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {!!item.operational_passport && (
                                  <>
                                    <div>
                                      <div className="flex items-center gap-2">
                                        <img src={IconCheckCircle} />
                                        <div className="mt-[2px]">Layanan Passport</div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {!!item.operational_other_staff && (
                                  <>
                                    <div>
                                      <div className="flex items-center gap-2">
                                        <img src={IconCheckCircle} />
                                        <div className="mt-[2px]">Petugas/Pembimbing</div>
                                      </div>
                                    </div>
                                  </>
                                )}

                              </div>
                            </>
                          }
                        />
                      </div>

                      {item.hotels.map((data, idx) => (
                        <div className="bg-gray-5 rounded-2xl flex flex-col gap-4 p-4" key={idx}>
                          <div className="flex gap-2 items-center">
                            <img src={IconHotel} alt="hotel" className="w-4 h-4" />
                            <span className="font-semibold text-xs">{data.name}</span>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="flex gap-2 items-center">
                              <img src={IconAddress} alt="hotel" className="w-4 h-4" />
                              <span className="font-semibold text-xs">{toCapital(data.city)}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <img src={IconNight} alt="hotel" className="w-4 h-4" />
                              <span className="font-semibold text-xs">{data.total_nights} Malam</span>
                            </div>
                            <div className="flex gap-2 items-center">
                              <img src={IconTipeKamar} alt="hotel" className="w-4 h-4" />
                              <span className="font-semibold text-xs">
                                <Rating
                                  value={data.star}
                                  />
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}

                      {item.status == 0 && confirmation && permissions.includes("update_calculator-package") && (
                        <div className="flex items-center gap-4">
                          <div className="w-full">
                            <Buttons 
                              customClass="w-full btn-outline-light btn-large"
                              text="Approve"
                              onClick={() => handleConfirmation(item.id, 1)}
                              />
                          </div>
                          <div className="w-full">
                            <Buttons
                              customClass="w-full btn-outline-light btn-large"
                              text="Reject"
                              onClick={() => handleConfirmation(item.id, 2)}
                              />
                          </div>
                        </div>
                      )}
                      
                    </div>
                  ))}
                </Masonry>
              </div>

            </Scroll>

          </div>
        ) : (
          <div className="content_empty flex flex-col w-full mx-auto justify-center items-center">
            {allPaket.length <= 0 && (
              <>
                <img
                  src={IconJamPasir}
                  alt="jam_pasir"
                  className="w-[260px] h-[269px]"
                />
                <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                  Belum ada paket yang direquest
                </div>
              </>
            )}
          </div>
      )}
      </div>
    </>
  )
}

RequestPackage.defaultProps = {
  confirmation: false,
}

export default RequestPackage