import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";

import Avatar from "components/avatar"
import Buttons from "components/buttons"
import { toCapital } from "utils/helpers"
import RoomBadge from "./components/RoomBadge"
import Badge from "components/badge"

import {
  handleGetFileRelasi,
  handleGetJamaahByRoomList,
  handleGetListRelasi,
  handleGetRoomlistByPackage,
  handleGetSetRoomList,
  handleUpdatedRelasi,
  handleUpdatedRoomList,
} from "stores/actions/paket/roomlist";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import Scroll from "components/scroll";
import Checkbox from "components/checkbox";
import { useFormik } from "formik";

const SetRoom = ({
	jamaahSelected,
  onSuccess,
  packageID
}) => {
  const dispatch = useDispatch()
  const params = useParams()
  
  const [setRoomlist, setSetRoomlist] = useState([])
  const [gettingSetRoomlist, setGettingSetRoomlist] = useState(false)
  const [jamaahSelectedID, setJamaahSelectedID] = useState([])
  const [selectedRoom, setSelectedRoom] = useState({})
  const [jamaahSwitchID, setJamaahSwitchID] = useState([])
  const [updating, setUpdating] = useState(false)
  
  const payload = useFormik({
    initialValues: {
      "selected_jamaah": [],
      "to_room_list_id": null,
      "switch_jamaah": []
    }
  })

  const handleSyncPayload = () => {
    // selectedJamaah
    let selectedJamaah = []
    jamaahSelected.forEach(jamaah => {
      selectedJamaah.push({
        "jamaah_id": jamaah.jamaah.id,
        "room_id": jamaah?.paket_room_list_id
      })
    })

    let toRoomlistID = null
    toRoomlistID = selectedRoom?.id

    let switchJamaah = []
    jamaahSwitchID.forEach(jamaahID => {
      switchJamaah.push({
        "jamaah_id": jamaahID,
      })
    })

    payload.setFieldValue("selected_jamaah", selectedJamaah)
    payload.setFieldValue("to_room_list_id", toRoomlistID)
    payload.setFieldValue("switch_jamaah", switchJamaah)

  }
  
  const onHandleGetSetRoomList = () => {
    setGettingSetRoomlist(true)
    dispatch(handleGetSetRoomList(
      packageID,
      {selected_jamaah: jamaahSelectedID},
      (response) => {
        setGettingSetRoomlist(false)
        setSetRoomlist(response.data.data)
      },
      (err) => {
        setGettingSetRoomlist(false)
        dispatch(handleError(err.data))
      }
    ))
  }

  const handleSelectedRoom = (room) => {
    setSelectedRoom(room)
  }

  const handleSelectedJamaahSwitch = (id, selected) => {
    if(selected) {
      setJamaahSwitchID([...jamaahSwitchID, id])
    } else {
      setJamaahSwitchID(jamaahSwitchID.filter(jamaahID => jamaahID !== id))
    }
  }

  const handleSave = () => {
    setUpdating(true)
    dispatch(
      handleUpdatedRoomList(
        packageID,
        payload.values,
        (response) => {
          setUpdating(false)
          dispatch(handleSuccess(response))
          onSuccess()
          setJamaahSelectedID([])
          setJamaahSwitchID([])
          setSelectedRoom({})
          payload.resetForm()
        },
        (err) => {
          setUpdating(false)
          dispatch(handleError(err.data))
        }
      )
    )
  }

  useEffect(() => {
    setJamaahSelectedID(jamaahSelected.map(jamaah => jamaah.jamaah.id))
  }, [jamaahSelected])

  useEffect(() => {
    if(jamaahSelectedID.length > 0) {
      onHandleGetSetRoomList()
    }
    handleSyncPayload()
  }, [jamaahSelectedID])

  useEffect(() => {
    handleSyncPayload()
  }, [selectedRoom])

  useEffect(() => {
    handleSyncPayload()
  }, [jamaahSwitchID])

	return (
		<>
			<div className="border rounded-tl-2xl rounded-tr-2xl p-4 pb-0 pr-0 text-sm">

				<Scroll
          customClass="!h-[calc(100vh-310px)] pr-4"
          >
          
          <div>
            <div className="font-semibold mb-4">Jamaah Terpilih</div>
            <div className="flex flex-col gap-4">
              {jamaahSelected.map((jamaah, index) => (
                <>
                  <div className="flex items-center p-4 rounded-xl shadow-soft gap-4">
                    <div className="flex gap-4 items-center w-64">
                      <Avatar
                        value={jamaah.jamaah}
                        containerClass="items-center justify-center  w-[32px] h-[32px] rounded-[100%]  bg-slate-100"
                        customClass= "object-cover w-[32px] h-[32px] rounded-[100%]"
                        />
                      <span>
                        {toCapital(jamaah.jamaah.title)}. {jamaah.jamaah.name}
                      </span>
                    </div>
                    <div className="inline-block w">
                      <RoomBadge 
                        roomType={jamaah.jamaah.room_type}
                        roomNumber={jamaah.jamaah.room_number}
                        />
                    </div>
                    <div className="mx-6 w-56">
                      <div className="py-2 px-4 bg-red-5 rounded-lg inline-block">
                        {jamaah.jamaah.relasi}
                      </div>
                    </div>
                    <div className="mx-6">
                      <Badge
                        backgroundColor={jamaah.jamaah.branch.bg_color}
                        color={jamaah.jamaah.branch.text_color}
                        label={jamaah.jamaah.branch.name.toUpperCase()}
                        />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>

          <div className="mt-4">
            <div className="font-semibold">Pilih Kamar</div>
            <div className="flex gap-4 overflow-x-auto overflow-y-hidden flex-nowrap scroll-horizontal-hidden py-4">
              {setRoomlist.map((item, index) => (
                <>
                  <div 
                    onClick={() => handleSelectedRoom(item)}
                    className={`
                      p-4 cursor-pointer shadow-soft rounded-xl !w-[200px] flex-shrink-0 active:bg-red-4
                      ${selectedRoom?.id == item.id ? "bg-red-4" : "bg-white"}
                    `}>
                    <div className="inline-block">
                      <RoomBadge 
                        roomType={item.room_type}
                        roomNumber={item.room_number}
                        />
                    </div>
                    <div className="flex justify-between items-center mt-4">
                      <div>
                        Kamar Terisi
                      </div>
                      <div className="font-semibold">
                        {item.total_pax_filled}/{item.total_pax}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>

              
          {selectedRoom?.total_pax_empty < jamaahSelected.length && (
            <div className="">
              <div className="mb-4 font-semibold">Tukar Jamaah</div>
              <div className="flex flex-col gap-4">
              {selectedRoom?.jamaah.map((jamaah, index) => (
                <>
                  <div className={`
                    flex items-center p-4 rounded-xl shadow-soft gap-4
                    ${jamaahSwitchID.includes(jamaah.jamaah.id) ? "!bg-red-4" : "!bg-white"}
                    `
                    }>
                    <div className="flex gap-4 items-center w-64">
                      <Checkbox
                        checked={jamaahSwitchID.includes(jamaah.jamaah.id)}
                        onChange={(selected) => handleSelectedJamaahSwitch(jamaah.jamaah.id, selected.target.checked)}
                        />
                      <Avatar
                        value={jamaah.jamaah}
                        containerClass="items-center justify-center  w-[32px] h-[32px] rounded-[100%]  bg-slate-100"
                        customClass= "object-cover w-[32px] h-[32px] rounded-[100%]"
                        />
                      <span>
                        {toCapital(jamaah.jamaah.title)}. {jamaah.jamaah.name}
                      </span>
                    </div>
                    <div className="inline-block w">
                      <RoomBadge 
                        roomType={jamaah.jamaah.room_type}
                        roomNumber={jamaah.jamaah.room_number}
                        />
                    </div>
                    <div className="mx-6 w-56">
                      <div className="py-2 px-4 bg-red-5 rounded-lg inline-block">
                        {jamaah.jamaah.relasi}
                      </div>
                    </div>
                    <div className="mx-6">
                      <Badge
                        backgroundColor={jamaah.jamaah.branch.bg_color}
                        color={jamaah.jamaah.branch.text_color}
                        label={jamaah.jamaah.branch.name.toUpperCase()}
                        />
                    </div>
                  </div>
                </>
              ))}
            </div>
            </div>
          )}

        </Scroll>

			</div>
			<div className="border rounded-bl-2xl rounded-br-2xl !border-t-0 p-4 flex justify-end">
				<Buttons
          loading={updating}
					text="Simpan"
					customClass="btn-primary btn-large"
          onClick={handleSave}
					/>
			</div>
		</>
	)
}

export default SetRoom