import PropTypes from "prop-types";

import IconCheclistBlue from 'assets/icons/checklist-blue-2.svg';

import BadgeComponent from "components/badge";

const BadgeCheck = ({
    option,
    value
}) => {
    return (
        <>
            <div className="select-custom-badge flex justify-between items-center">
                <BadgeComponent
                    label={option.label}
                    backgroundColor={option.data.bgColor}
                    color={option.data.textColor}
                    />
                {option.check && <img src={IconCheclistBlue} alt="checklist"/>}
            </div>
        </>
    );
}

BadgeCheck.protoTypes  = {
    option: PropTypes.object
}

BadgeCheck.defaultProp = {
    option: {}
}

export default BadgeCheck