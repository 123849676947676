import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import useDebounce from "utils/helpers/useDebounce";

import RequestPackage from "components/RequestPackage";

const useStyle = makeStyles({
  content: {
    background: "#fff",
    borderRadius: "16px",
    boxShadow:
      "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
    "& .content_header": {
      padding: "9px 16px",
      borderBottom: "1px solid #E0E0E0",
    },
    "& .content_list": {
      display: "grid",
      gap: "10px",
      gridTemplateColumns: "repeat(3,minmax(0,1fr))",
      height: "calc(100vh - 260px)",
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "4px",
        // height: scrollHorizontal ? "5px" : "100px",
      },
      "&::-webkit-scrollbar-track": {
        width: "4px",
        borderRight: "10px solid #fff",
        borderLeft: "10px solid #fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E0E0E0",
        height: "30px",
        width: "4px",
        borderRadius: "16px",
      },
      "&::-webkit-scrollbar-track-piece:start": {
        background: "#fff",
      },
      "&::-webkit-scrollbar-track-piece:end": {
        background: "#fff",
      },
      scrollBehavior: "smooth",
      "@media (max-width: 1400px)": {
        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
      },

      "& .paket_cards_container": {},

      "& .paket_card": {
        borderRadius: "0px 40px 40px 0px",
        transition: "transform 0.3s ease-in-out",
        // position: "relative",
        margin: "0 auto",
        "&:hover ": {
          // position: "absolute",
          top: "0",
          right: "auto",
          // zIndex: '99',
        },
        // zIndex: '99',
        background: "#FBF3F2",
        borderRadius: "40px",
        cursor: "pointer",
        fontSize: "General Sans, sans-serif",
        padding: "51px 12px 12px 12px",
        // position: "relative",
        // zIndex: 1,
        "&:nth-child(-n+3)": {
          borderRadius: "0px 0px 40px 40px",
        },
        "& .card_content": {
          background: "#fff",
          borderRadius: "32px",
          boxShadow:
            "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
          display: "flex",
          padding: "10px 10px 12px 12px",
        },
        "& .card_image_wrapper": {
          borderRadius: "24px",
          boxShadow: "0px 6px 64px -4px rgba(0, 0, 0, 0.04)",
          filter:
            "drop-shadow(0px 10px 16px rgba(0, 0, 0, 0.04)) drop-shadow(0px 6px 32px rgba(0, 0, 0, 0.04))",
          overflow: "hidden",
          position: "relative",
          marginTop: "-38px",
          "& .card_image": {
            height: "248px",
            objectFit: "cover",
            width: "200px !important",
          },
        },
        "& .card_text_wrapper": {
          paddingLeft: "12px",
          width: "100%",
          "& .card_text_title": {
            borderBottom: "1px dashed #BDBDBD",
            borderSpacing: "5px",
            paddingBottom: "8px",
            position: "relative",
            "& h2": {
              fontWeight: 600,
              fontSize: "14px",
              textTransform: "uppercase",
              lineHeight: "20px",
            },
            "& .card_badge": {
              background: "#2eb24333",
              borderRadius: "4px",
              color: "#333D29",
              fontSize: "8px",
              fontWeight: "600",
              padding: "4px 8px",
              position: "absolute",
              bottom: "10px",
              right: "0",
              width: "fit-content",
            },
          },
          "& .card_detail_item": {
            display: "flex",
            gap: "8px",
            alignItems: "center",
            marginTop: "12px",
            fontSize: "12px",
            "& .green_bold_text": {
              background:
                "linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%)",
              fontWeight: 700,
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
            },
          },
        },
      },
    },
    "& .content_empty": {
      height: "calc(100vh - 260px)",
    },
    "& .content_footer": {
      display: "flex",
      justifyContent: "flex-end",
      padding: "14px",
    },
  },
});

const RequestPaket = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  // search state
  const [searchValue, setSearchValue] = useState("");
  const searchDebounce = useDebounce(searchValue, 1000);
  // pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  return (
    <>
      <div className={classes.content}>
        <div className="content_header">
          <p className="font-bold text-xl py-[12px]">Request Paket</p>
        </div>
  
        <RequestPackage 
          perPage={pageSize}
          currentPage={currentPage}
          setTotal={setTotal}
          confirmation={true}
          />

          <div className="content_footer mosleme-pagination">
            <Pagination
              showSizeChanger={true}
              current={currentPage}
              pageSize={pageSize}
              defaultPageSize={12}
              pageSizeOptions={["12", "24", "60", "120"]}
              defaultCurrent={1}
              total={total}
              onChange={(e) => setCurrentPage(e)}
              onShowSizeChange={(current, pageSize) => {
                setPageSize(pageSize);
                setCurrentPage(current);
              }}
            />
          </div>
        </div>
    </>
  );
};

export default React.memo(RequestPaket);
