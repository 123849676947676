import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Buttons from "components/buttons";
import AddCouponCard from "./AddCouponCard";
import CouponForm from "pages/tagihan/components/CouponForm";

import { handleError } from "stores/actions/errorGeneral";

const AddCouponForm = ({
  packageID,
  jamaahOrders,
  branch,
  onChange,
  additionals,
  bookingForm,
  couponList,
  setCouponList
}) => {
  const dispatch = useDispatch()
  const [isAddingCoupon, setIsAddingCoupon] = useState(false)
  const [selectedCoupons, setSelectedCoupons] = useState([])

  const handleAddCoupon = () => {
    setIsAddingCoupon(true)
  }

  const handleSetSelectedCoupons = (couponId, jamaahOrdersIndex) => {
    const _tempSelectedCoupons = [...selectedCoupons]

    const newCoupon = handleSelectedCoupon(couponId, jamaahOrdersIndex)

    if(!_tempSelectedCoupons.find(item => item.id === newCoupon.id)) {
      _tempSelectedCoupons.push(newCoupon)
    } else {
      const couponIndex = _tempSelectedCoupons.findIndex(item => item.id === newCoupon.id)
      _tempSelectedCoupons[couponIndex] = newCoupon
    }

    setSelectedCoupons(_tempSelectedCoupons)
    setIsAddingCoupon(false)
    onChange(_tempSelectedCoupons)
  }

  const handleSelectedCoupon = (couponId, jamaahOrdersIndex) => {
    // Mencari kupon yang sesuai dengan couponId
    let coupon = selectedCoupons.find(item => item.id === couponId);
  
    // Jika kupon tidak ditemukan, dapatkan detail kupon
    if (!coupon) {
      coupon = handleGetDetailCoupon(couponId);
    }
  
    // Menambahkan pesanan jamaah ke dalam kupon
    jamaahOrdersIndex.forEach(jamaahIndex => {
      const jamaahOrder = handleGetDetialJamaahOrders(jamaahIndex);
  
      // Jika pesanan jamaah tidak ada dalam kupon, tambahkan
      if (!coupon.jamaah_orders.find(item => item.nik === jamaahOrder.nik)) {
        coupon.jamaah_orders.push(jamaahOrder);
      }
    });
  
    // Menghapus pesanan jamaah dari kupon jika tidak ada dalam jamaahOrdersIndex
    coupon.jamaah_orders = coupon.jamaah_orders.filter(couponJamaah => {
      const selectedJamaahNIKS = jamaahOrdersIndex.map(jamaahIndex => jamaahOrders[jamaahIndex].jamaah_identity_number);
      return selectedJamaahNIKS.includes(couponJamaah.nik);
    });

    coupon = {
      ...coupon,
      total_amount: handleTotalDiscountAmount(coupon)
    }
  
    return coupon;
  }

  const handleRemove = (couponId) => {
    // Membuat salinan array selectedCoupons
    const _tempSelectedCoupons = selectedCoupons.filter(coupon => coupon.id !== couponId);
    
    // Debugging: log couponId dan array yang baru
    console.log(couponId, _tempSelectedCoupons);
    
    // Mengupdate state selectedCoupons
    setSelectedCoupons(_tempSelectedCoupons);
    
    // Memanggil callback onChange dengan array yang baru
    onChange(_tempSelectedCoupons);
}


  const handleGetDetailCoupon = (couponId) => {
    let coupon = couponList.find((coupon) => coupon.id_coupon === couponId)

    coupon = {
      id: coupon.id_coupon,
      code: coupon.code_coupon,
      amount: coupon.nominal_diskon,
      discount_type: coupon.jenis_diskon,
      coupon_type: coupon.tipe_coupon,
      jamaah_orders: []
    }

    return coupon
  }

  const handleGetDetialJamaahOrders = (jamaahOrdersIndex) => {
    let jaamahOrder = jamaahOrders[jamaahOrdersIndex]
    
    jaamahOrder = {
      name: jaamahOrder.jamaah_name,
      title: jaamahOrder.jamaah_title,
      nik: jaamahOrder.jamaah_identity_number,
      room_price: jaamahOrder.paket_kamar.price
    }
    return jaamahOrder
  }

  const handleTotalDiscountAmount = (coupon) => {
    let discount = 0;

    if(coupon.discount_type ==  2) {
      coupon.jamaah_orders.forEach(jamaahOrder => {
        discount += (coupon.amount / 100) * (jamaahOrder.room_price + handleCountAdditonalPriceJamaah(jamaahOrder))
      })
    } else {
      discount += coupon.amount * coupon.jamaah_orders.length
    }

    return discount
  }


  const handleCountAdditonalPriceJamaah = (jamaah) => {
    let total = 0

    additionals.forEach(additional => {
      const jamaahAdditional = additional.jamaah.find(item => item.ktp_nik === jamaah.nik)

      if(jamaahAdditional) {
        total += additional.price * jamaahAdditional.quantity
      }
    })

    return total
  }

  const handleParseCoupons = () => {
    const bookingJamaahOrders = [...bookingForm.values.order_kamars]
    const dataCoupons = []
    
    bookingJamaahOrders.forEach((jamaahOrder, index) => {
      jamaahOrder.coupons.forEach(couponId => {
        let couponData = dataCoupons.find(coupon => coupon.id_coupon === couponId)
        console.log("couponData :", couponData)

        if(couponData) {
          if(!couponData.jamaah_orders_index.includes(index)) {
            couponData.jamaah_orders_index.push(index)
          }
        } else {
          couponData = {
            id_coupon: couponId,
            jamaah_orders_index: []
          }

          couponData.jamaah_orders_index.push(index)
          dataCoupons.push(couponData)
        }
      })

    })

    let _tempSelectedCoupons = []

    dataCoupons.forEach(coupon => {
      _tempSelectedCoupons.push(handleSelectedCoupon(coupon.id_coupon, coupon.jamaah_orders_index))
    })

    onChange(_tempSelectedCoupons)
    setSelectedCoupons(_tempSelectedCoupons)

  }

  useEffect(() => {
    
    const fetchData = () => {
      if(jamaahOrders.length > 0 && couponList.length > 0) {
        handleParseCoupons()
      }
    }

    fetchData()
  }, [jamaahOrders, couponList])



  return (
    <div className="mt-4 rounded-2xl bg-[#fbf3f2] p-4">
      <div className="flex justify-between items-center">
        <h3 className="text-sm font-semibold">Kupon</h3>
        <Buttons
          text="Tambah Kupon"
          customClass="btn-primary btn-small"
          onClick={() => handleAddCoupon()}
          disabled={isAddingCoupon}
          />
      </div>

      {selectedCoupons?.map((coupon, index) => {
        return (
          <div className="mt-4">
            <AddCouponCard 
              key={index}
              coupon={coupon}
              onRemove={(couponSelected) => handleRemove(couponSelected.id)}
              />
          </div>
        )
      })}

      <div id="coupon-form">
        {isAddingCoupon && (
          <div className="bg-white rounded-xl p-4 mt-4">
            <CouponForm 
              packageID={packageID}
              jamaahOrders={jamaahOrders}
              branch={branch}
              label="Kupon 1"
              thema="booking"
              couponListFill={couponList}
              setCouponListFill={setCouponList}
              onCancel={() => {
                setIsAddingCoupon(false)
              }}
              onChange={(couponId, jamaahOrderId) => {
                handleSetSelectedCoupons(couponId, jamaahOrderId)
              }}
            />
          </div>
        )}
      </div>

    </div>
  );
};

export default AddCouponForm;