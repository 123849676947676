import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropType from "prop-types";
import { Select } from "antd";
import { makeStyles } from "@material-ui/core";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import uuid from "react-uuid";
import "../styles/index.scss";
import { getMasterMusim } from "stores/actions/datamaster/musim";
import { resetDataUpload, setDataUpload } from "stores/actions/uploadFile";
import Grid from "@mui/material/Grid";

import DatePicker from "components/datepicker/DatePicker";
import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import Textarea from "components/form/textarea";
import ImageField from "components/image_field";
import Close from "assets/icons/Close.svg";
import Buttons from "components/buttons";
import { editPaketInfoDasar } from "stores/actions/paket/infoDasar";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import axios from "axios";
import RichTextEditor from "components/richTextEditor";

const useStyle = makeStyles({
  step1: {
    "& .input-label, .select-label": {
      fontSize: "14px",
      fontWeight: 600,
      color: "#4f4f4f",
      marginBottom: "4px",
    },
    "& .ant-picker": {
      width: "100%",
    },
    "& .ant-select-selector": {
      borderColor: "transparent",
      boxShadow: "none !important",
      padding: "10px 16px",
      minHeight: "54px",
      backgroundColor: "#f2f2f2 !important",
      border: "0 !important",
      borderRadius: "12px",
      width: "100%",
    },
    "& .ant-select-selection-item": {
      background: "#fff",
      borderRadius: "30px !important",
      height: "fit-content",
      padding: "4px 10px !important",
    },
    "& .select-label": {
      fontSize: "16px",
      fontWeight: "600",
      color: "rgb(79, 79, 79)",
      marginBottom: "8px",
    },
    "& .ant-select-selection-placeholder": {
      color: "#9CA3AF",
      left: "20px",
      fontSize: "14px",
    },
  },
  custom_dropdown: {
    "& .rc-virtual-list-holder-inner": {
      flexDirection: "row !important",
      flexWrap: "wrap",
      gap: "6px !important",
    },
    "& .ant-select-item-option": {
      border: "1px solid #ccc",
      borderRadius: "50px !important",
    },
    "& .ant-select-item-option-content": {
      marginRight: "6px !important",
    },
  },
});

const InfoDasar = ({ info, closePopup, setSuccessEdit }) => {
  const [maskapaiOptions, setMaskapaiOptions] = useState([]);
  const dispatch = useDispatch();
  const storeMusim = useSelector(({ musim }) => musim.all_musim);
  let data = info;
  const classes = useStyle();
  const [optionsMusim, setOptionsMusim] = useState([]);
  const [optionsTags, setOptionTags] = useState([]);
  const [editTagValue, setEditTagValue] = useState([]);
  const [imageInfo, setImageInfo] = useState({
    value: data.image_thumbnail,
    error: false,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      image: data.image_thumbnail,
      judul: data.judul_paket,
      date: dayjs(data.jadwal_keberangkatan, "YYYY-MM-DD"),
      musim: data.musim_id,
      totalDay: data.jumlah_hari,
      category: data.kategori,
      tags: data.tag,
      description: data.deskripsi,
      maskapai_id: data.maskapai.id_maskapai,
      slug: data.slug
    },
    validationSchema: Yup.object().shape({
      judul: Yup.string().required("Judul wajib diisi."),
      date: Yup.object().required("Jadwal Keberangkatan wajib dipilih."),
      musim: Yup.string().required("Musim wajib dipilih."),
      totalDay: Yup.string().required("Jumlah Hari wajib diisi."),
      category: Yup.string().required("Kategori wajib diisi."),
      tags: Yup.string().required("Tags Wajib dipilih."),
      description: Yup.string().required("Deskripsi wajib diisi."),
      maskapai_id: Yup.string().required("Maskapai wajib diisi."),
      slug: Yup.string().required("Permalink wajib diisi."),
    }),
    onSubmit: async (values, actions) => {
      const status = await dispatch(
        editPaketInfoDasar({
          id_paket: data.id_paket,
          judul_paket: values.judul,
          jadwal_keberangkatan: dayjs(values.date).format("YYYY-MM-DD"),
          musim_id: values.musim,
          jumlah_hari: values.totalDay,
          kategori: values.category,
          tag: values.tags,
          deskripsi: values.description,
          image_thumbnail: imageInfo.value,
          tipe_paket: data.tipe_paket,
          maskapai_id: values.maskapai_id,
          slug: values.slug
        })
      );
      const { message } = status;
      if (message === "Berhasil memperbarui info dasar paket") {
        setSuccessEdit(true);
      } else {
        actions.resetForm();
        dispatch(resetDataUpload());
        setSuccessEdit(true);
      }
    },
  });

  const onDatePickerChange = (val) => {
    setFieldValue("date", val);
  };

  useEffect(() => {
    dispatch(getMasterMusim());
  }, [dispatch]);

  useEffect(() => {
    let result = [];
    storeMusim?.data?.map((val, idx) => {
      return result.push({
        id: val.id_musim,
        label: `${val.thn_masehi} M/${val.thn_hijriyah} H`,
        value: val.id_musim,
        description: val.description,
      });
    });
    setOptionsMusim(result);
  }, [storeMusim?.data, storeMusim?.data?.length]);

  useEffect(() => {
    let split = data.tag.split(",");
    let result = [];
    split.map((val, idx) => {
      result.push({
        value: val,
        label: val,
      });
    });
    setEditTagValue(result);
    setOptionTags(result);
  }, []);

  useEffect(() => {
    dispatch(
      setDataUpload([
        {
          id: uuid(), // di import dari library 'uuid'
          fileName: "Image GeneralInfo",
          path: data.image_thumbnail,
          name: "generalinfo_image", // harus unique
        },
      ])
    );
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/admin/get-maskapai`,
        {
          sort_column: "name",
          sort_order: "",
          keyword: "",
        }
      );
      const _temp = [];
      response.data.data.forEach((item) => {
        _temp.push({
          label: item.name,
          value: item.id_maskapai,
        });
      });
      setMaskapaiOptions(_temp);
    };
    fetch();
  }, []);

  return (
    <div className="w-full ">
      <div className="w-full">
        <div className="flex px-4 gap-4 items-center">
          <div className="w-full">
            <ImageField
              label="Thumbnail"
              name="generalinfo_image"
              error={imageInfo.error}
              errorMesssage={imageInfo.error ? "Gambar Wajib dipilih" : ""}
              isMulti={false}
              onRemove={() =>
                setImageInfo({
                  value: "",
                  error: false,
                })
              }
              setFile={(file) =>
                setImageInfo({
                  value: file?.path,
                  error: false,
                })
              }
              width="100%"
              height="16rem"
              customContainer="cursor-pointer !rounded-2xl"
            />
            {/* <p className="text-[12px] w-full">
              Ukuran: maks. 10 Mb. Ekstensi: .JPG .JPEG .PNG
            </p> */}
          </div>
          <div className="w-full">
            <Inputs
              id="judul"
              label="Judul Paket"
              placeholder="Masukan judul paket"
              containerClass="w-full mb-4"
              value={values.judul}
              onBlur={handleBlur}
              error={touched.judul && errors.judul && errors.judul}
              customClass={touched.judul && errors.judul && "input-error"}
              onChange={handleChange}
            />
            <Inputs
              id="slug"
              label="Permalink"
              placeholder="Masukan permalink paket"
              containerClass="w-full mb-4"
              value={values.slug}
              onBlur={handleBlur}
              error={touched.slug && errors.slug && errors.slug}
              customClass={touched.slug && errors.slug && "input-error"}
              onChange={handleChange}
            />
            <div className="w-full">
              <div className="input-label">Jadwal Keberangkatan</div>
              <DatePicker
                id="date"
                placeholder="Pilih Tanggal"
                onChange={onDatePickerChange}
                containerClass="mt-2"
                customClass="w-full"
                formatManual
                value={values.date}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setFieldTouched({
                      ...touched,
                      date: true,
                    });
                  } else {
                    setFieldTouched({
                      ...touched,
                      date: false,
                    });
                  }
                }}
              />
              {touched.date && errors.date && (
                <div className="flex flex-row items-center gap-2 mt-1">
                  <img src={Close} alt="close" />
                  <div className="text-[#e82320]">{errors.date}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex px-4 flex-row items-start justify-start mt-[20px] gap-x-[16px] w-full">
          <div className="w-[33%]">
            <Selects
              id="musim"
              label="Pilih Musim"
              placeholder="Pilih Musim"
              options={optionsMusim}
              onChange={(e) => {
                setFieldValue("musim", e[0].id);
                setFieldError("musim", "", true);
              }}
              values={[
                {
                  value: `${data.musim_id}`,
                  label: `${data.thn_masehi} M/${data.thn_hijriyah} H`,
                },
              ]}
              error={touched.musim && errors.musim && errors.musim}
              customClass={`w-[32%] ${
                touched.musim && errors.musim && "select-error"
              }`}
              onDropdownClose={() => {
                setFieldTouched("musim", true, false);
              }}
            />
          </div>
          <div className="w-[33%]">
            <Inputs
              id="totalDay"
              label="Jumlah Hari"
              placeholder="Masukan jumlah hari"
              value={values.totalDay}
              onBlur={handleBlur}
              error={touched.totalDay && errors.totalDay && errors.totalDay}
              customClass={`w-[33%] ${
                touched.totalDay && errors.totalDay && "input-error"
              }`}
              onChange={handleChange}
            />
          </div>
          <div className="w-[33%]">
            <Inputs
              id="category"
              label="Kategori"
              placeholder="Isi Kategori"
              value={values.category}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.category && errors.category && errors.category}
              customClass={touched.category && errors.category && "input-error"}
            />
          </div>
        </div>
        
        <div className="mx-4 mt-4">
          <Selects
              label="Maskapai Penerbangan Internasional"
              placeholder="Pilih Maskapai"
              options={maskapaiOptions}
              onChange={(e) => {
                setFieldValue("maskapai_id", e[0].value);
                setFieldError("maskapai_id", "", true);
              }}
              values={maskapaiOptions.filter(
                (item) => String(item.value) === String(values.maskapai_id)
              )}
              error={
                touched.maskapai_id && errors.maskapai_id && errors.maskapai_id
              }
              customClass={`w-[254px] mb-4 ${
                touched.maskapai_id && errors.maskapai_id && "select-error"
              }`}
              onDropdownClose={() => {
                setFieldTouched("maskapai_id", true, false);
              }}
          />
        </div>
        
        <div className={`w-full px-4 mt-4 ${classes.step1}`}>
          <div className="mb-2 text-[#4F4F4F] text-[16px] font-semibold">
            Tag
          </div>
          {editTagValue.length !== 0 && (
            <Select
              id="tags"
              mode="tags"
              style={{
                width: "100%",
              }}
              placeholder="Pilih Tags"
              options={optionsTags}
              popupClassName={classes.custom_dropdown}
              dropdownStyle={{ zIndex: 2000 }}
              defaultValue={editTagValue}
              onChange={(e) => {
                setFieldValue("tags", e.toString());
                setFieldError("tags", "", true);
              }}
              error={touched.tags && errors.tags && errors.tags}
              customClass={touched.tags && errors.tags && "select-error"}
              onDropdownClose={() => {
                setFieldTouched("tags", true, false);
              }}
            />
          )}
        </div>
        <div className="w-full px-4 mt-[20px]">
          <Grid item sm={12}>
            <RichTextEditor
              placeholder="Masukkan Deskripsi"
              className="text-[#4f4f4f]"
              data={{ value: values.description }}
              onChange={(values) => {
                setFieldValue("description", values);
              }}
            />
          </Grid>
        </div>
      </div>
      <div className="flex flex-col justify-end border-t my-4 border-[#e5e7eb] w-full">
        <Buttons
          text="Simpan"
          loading={isSubmitting}
          customClass={`mt-4 mx-4 btn-primary rounded-2xl btn-medium !w-[186px] float-right ${
            isSubmitting && "loading"
          }`}
          onClick={() => {
            setImageInfo({
              value: imageInfo.value,
              error:
                imageInfo.value === "" || imageInfo.value === undefined
                  ? true
                  : false,
            });
            handleSubmit();
          }}
        />
      </div>
    </div>
  );
};

InfoDasar.propTypes = {
  info: PropType.object,
  closePopup: PropType.func,
  setSuccessEdit: PropType.func,
};

export default React.memo(InfoDasar);
