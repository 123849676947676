import Buttons from "components/buttons"
import InputsCurrency from "components/form/inputsCurrency"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import OperationalOtherForms from "../../components/OperationalOtherForms"
import OperationalAirplaneDomestic from "../../components/OperationalAirplaneDomesticForm"
import OperationalAirplaneDomesticForm from "../../components/OperationalAirplaneDomesticForm"
import OperationalEquipmentAdditionalForm from "../../components/OperationalEquipmentAdditionalForm"

import * as Yup from "yup";

const OperationalDomestic = ({
  data,
  onChange,
  isLoadingSave,
  savingCategory
}) => {
  
  const [saved, setSaved] = useState(false)

  const formik = useFormik({
    initialValues: {
      handling_domestic: 0,
      visa: 0,
      complate_equipment: 0,
      manasik: 0,
      mentor_pocket_money: 0,
      center_fee: 0,
      marketing_fee: 0,
      insurance: 0,
      system: 0,
      meningitis: 0,
      passport: 0,
      others: [
        {
            "item_id": 4,
            "range_pax": '1-15',
            "price": 0
        },
      ],
      airplane_domestics: [
        {
          "airplane_id": null,
          "starting_id": null,
          "price": 0
        }
      ],
      equipment_additionals: [
        {
          "id": null,
          "name": null,
          "price": 0
        }
      ]
    }
  })

  const handleGetDataPrice = (key) => {
    const dataSelected = data?.find(item => item.item === key)

    if(dataSelected) {
      if('data' in dataSelected) {
        
        if(key == 'other_domestics') {
          const _tempData = dataSelected.data.map(item => {
            return {
              item_id: item.item_id,
              range_pax: item.range_pax,
              price: item.price
            }
          })

          return _tempData
        } 

        if(key == 'airplane_domestics') {
          const _tempData = dataSelected.data.map(item => {
            return {
              airplane_id: item.airplane_id,
              starting_id: item.starting_id,
              price: item.price
            }
          })

          return _tempData
        }

        if(key == 'equipment_additionals') {
          const _tempData = dataSelected.data.map(item => {
            return {
              id: item.id,
              name: item.name,
              price: item.price
            }
          })

          return _tempData
        }
        
      } else {
        return dataSelected.price
      }
    }

  }

  const handleUpdateData = (key, value) => {
    setSaved(false)
    formik.setFieldValue(key, value)
  }

  const handleChangeOperationalOthers = (itemId, newData) => {
    setSaved(false)
    let _newTempData = newData.map(item => {
      if (!('item_id' in item)) {
        item.item_id = itemId;
      }
      return item;
    });

    let _tempData = formik.values.others
    _tempData = _tempData.filter(item => item.item_id !== itemId)
    _tempData = _tempData.concat(_newTempData)

    formik.setFieldValue('others', _tempData)
  }

  const handleSave = () => {
    setSaved(true)
    onChange(formik.values)
  }

  const handleUpdateDataAirplaneDomestic = (newData) => {
    setSaved(false)
    formik.setFieldValue('airplane_domestics', newData)
  }

  const handleUpdateDataEquipmentAdditional = (newData) => {
    setSaved(false)
    formik.setFieldValue('equipment_additionals', newData)
  }

  useEffect(() => {
    formik.setValues({ ...formik.values, ...data })
  }, [data])

  return (
    <>
      <div className="flex justify-end items-center">
        <Buttons
          text="Simpan"
          customClass="btn-primary btn-small"
          disabled={!(formik.isValid && formik.dirty) || saved}
          onClick={handleSave}
          loading={(isLoadingSave) && (savingCategory == "domestics")}
          />
      </div>
      
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 w-full">
          <div className="w-1/2 flex flex-col gap-4">
            <InputsCurrency
                label="Handling Indonesia"
                value={formik.values.handling_domestic}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('handling_domestic', val)}
              />
            <InputsCurrency
                label="Perlengkapan Lengkap"
                value={formik.values.complate_equipment}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('complate_equipment', val)}
              />
            <InputsCurrency
                label="Uang Saku Pembimbing"
                value={formik.values.mentor_pocket_money}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('mentor_pocket_money', val)}
              />
            <InputsCurrency
                label="Fee Marketing"
                value={formik.values.marketing_fee}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('marketing_fee', val)}
              />
            <InputsCurrency
              label="System"
              value={formik.values.system}
              placeholder="Masukan harga"
              onChange={(val) => handleUpdateData('system', val)}
            />
          </div>
          <div className="w-1/2 flex flex-col gap-4">
            <InputsCurrency
                label="Visa"
                value={formik.values.visa}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('visa', val)}
              />
            <InputsCurrency
                label="Manasik"
                value={formik.values.manasik}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('manasik', val)}
              />
            <InputsCurrency
                label="Fee Pusat"
                value={formik.values.center_fee}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('center_fee', val)}
              />
            <InputsCurrency
                label="Asuransi"
                value={formik.values.insurance}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('insurance', val)}
              />
            <InputsCurrency
              label="Passport"
              value={formik.values.passport}
              placeholder="Masukan harga"
              onChange={(val) => handleUpdateData('passport', val)}
            />
          </div>
        </div>
        <div className="w-full">
          <InputsCurrency
              label="Meningitis"
              value={formik.values.meningitis}
              placeholder="Masukan harga"
              onChange={(val) => handleUpdateData('meningitis', val)}
            />
        </div>
        <div className="w-full flex flex-col gap-4">
          <OperationalEquipmentAdditionalForm
            data={formik?.values?.equipment_additionals}
            onChange={handleUpdateDataEquipmentAdditional}
            />
          <OperationalAirplaneDomesticForm
            data={formik?.values?.airplane_domestics}
            onChange={handleUpdateDataAirplaneDomestic}
            />
          {/* <OperationalOtherForms 
            label="Biaya Petugas/Pembimbing/Pendamping"
            data={formik?.values?.others?.filter(item => item.item_id == 4)}
            onChange={(newData) => handleChangeOperationalOthers(4, newData)}
            /> */}
        </div>
      </div>

    </>
  )

}

export default OperationalDomestic