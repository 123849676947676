import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "antd";
import IconArrow from '../../assets/icons/dropdown-arrow.svg'
import IconClose from '../../assets/icons/Close.svg'

import DropdownsStyle from "./Dropdowns.style";
import { Arrow, ArrowDown, ArrowDown2 } from "iconsax-react";
import { ArrowDropDown } from "@mui/icons-material";

const Dropdowns = ({
  forceOpen,
  containerClass,
  customClass,
  arrow,
  placement,
  disabled,
  dropdownHeader,
  children,
  onClick,
  customAreaDropdown,
  labelDropdown,
  dropdownArrow,
  onClose,
  iconOnly,
  error,
  errorMsg
}) => {

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handlDropdownOpen = (open) => {
    setDropdownOpen(open)
    if(!open) {
      onClose()
    }
  }

  useEffect(() => {
    if (forceOpen) {
      handlDropdownOpen(true)
    }
  }, [forceOpen])

  return (
    <DropdownsStyle className={`${containerClass}`}>
      <Dropdown
        className={`custom-dropdown ${customClass} ${error? "error" : ""}`}
        trigger={["click"]}
        arrow={arrow}
        placement={placement}
        disabled={disabled}
        getPopupContainer={() =>
          document.getElementById(`area-dropdown${customAreaDropdown}`)
        }
        dropdownRender={() => <>{children}</>}
        onOpenChange={handlDropdownOpen}
        open={dropdownOpen}

      >
        <div onClick={onClick}>
          <div className="text-sm">
            {labelDropdown}
          </div>
          {dropdownArrow && 
            <div className={`transition-all duration-500 ${dropdownOpen ? "rotate-180" : ""}`}>
              {/* <img src={IconArrow} width="16px" height="16px" /> */}
              <ArrowDown2
                size="16px"
                />
            </div>
          }
        </div>
      </Dropdown>
      {error && 
        <div className="dropdown-error-feedback flex text-sm text-red-3 mt-1 gap-2 items-center">
          <img src={IconClose} /> <span>{error}</span>
        </div>
      }
      <div
        id={`area-dropdown${customAreaDropdown}`}
        className="custom-dropdown-open"
      ></div>
    </DropdownsStyle>
  );
};

Dropdowns.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  arrow: PropTypes.bool,
  placement: PropTypes.oneOfType([
    "bottomLeft",
    "bottom",
    "bottomRight",
    "topLeft",
    "top",
    "topRight",
  ]),
  disabled: PropTypes.bool,
  dropdownRender: PropTypes.func,
  onClick: PropTypes.func,
  customAreaDropdown: PropTypes.string,
  labelDropdown: PropTypes.string,
  dropdownArrow: PropTypes.bool,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  forceOpen: PropTypes.bool,
  onClose: PropTypes.func
};

Dropdowns.defaultProps = {
  containerClass: "",
  customClass: "",
  arrow: false,
  placement: "bottomLeft",
  disabled: false,
  dropdownRender: () => {},
  onClick: () => {},
  iconOnly: false,
  customAreaDropdown: "",
  labelDropdown: "",
  dropdownArrow: false,
  error: false,
  errorMsg: "",
  forceOpen: false,
  onClose: () => {}
};

export const closeDropdown = () => {
  document.getElementsByClassName("ant-dropdown-open")[0].click();
};

export default Dropdowns;
