import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";

import InputsCurrency from "components/form/inputsCurrency";
import Buttons from "components/buttons";
import OperationalSaudi from "./partials/operationals/OperationalSaudi";
import OperationalDomestic from "./partials/operationals/OperationalDomestic";

import { useFormik } from "formik";
import * as Yup from "yup";

const OperationalPricUpdate = () => {
    const dispatch = useDispatch()

    const [isLoadingGet, setIsLoadingGet] = useState(false)
    const [isLoadingSave, setIsLoadingSave] = useState(false)
    const [savingCategory, setSavingCategory] = useState(null)

    const [operational, setOperational] = useState([]);

    const itemNameByCategory = {
      saudies: [
        'handling_saudi', 'handling_saudi_fee', 'zamzam_water'
      ],
      domestics: [
        'handling_domestic', 'visa', 'complate_equipment',
        'manasik', 'mentor_pocket_money', 'center_fee',
        'marketing_fee', 'insurance', 'system', 'meningitis', 
        'passport', 'airplane_domestics', 'equipment_additionals'
      ]
    }

    const othersItemIdByCategory = {
      saudies: [1, 2, 3],
      domestics: [4]
    };

    const formik = useFormik({
        initialValues: {
          handling_saudi: 0,
          handling_saudi_fee: 0,
          zamzam_water: 0,
          handling_domestic: 0,
          visa: 0,
          complate_equipment: 0,
          manasik: 0,
          mentor_pocket_money: 0,
          center_fee: 0,
          marketing_fee: 0,
          insurance: 0,
          system: 0,
          meningitis: 0,
          passport: 0,
          others: [
            {
              "item_id": 1,
              "range_ax": '1-15',
              "price": 0
            },
            {
              "item_id": 2,
              "range_ax": '1-15',
              "price": 0
            },
            {
              "item_id": 3,
              "range_ax": '1-15',
              "price": 0
            },
            {
              "item_id": 4,
              "range_ax": '1-15',
              "price": 0
            },
          ],
          airplane_domestics: [
            {
              "airplane_id": null,
              "starting_id": null,
              "price": 0
            }
          ],
          equipment_additionals: [
            {
              "id": null,
              "name": null,
              "price": 0
            }
          ]
        },
        validationSchema: Yup.object().shape({
          handling_saudi: Yup.number().required("Harga handling saudi wajib diisi").min(0),
          handling_saudi_fee: Yup.number().required("Harga handling saudi fee wajib diisi").min(0),
          zamzam_water: Yup.number().required("Harga Air Zam-zam wajib diisi").min(0),
          others: Yup.array().of(Yup.object().shape({
            item_id: Yup.number().required("Item wajib diisi"),
            range_pax: Yup.string().required("Jumlah Pax wajib diisi"),
            price: Yup.number().required("Harga wajib diisi").min(0),
          }))
        })
      })



    const onHandleGet = async () => {
        try {
            setIsLoadingGet(true)
            const res = await PriceUpdateAPI.operationalGet()

            if(res.status === 200) {
                setOperational(res.data.data)
            } else {
                dispatch(handleError(res))
            }

            setIsLoadingGet(false)
            
        } catch (error) {
            dispatch(handleError(error))
            setIsLoadingGet(false)
        }
    }

    const handleGetDataPrice = (key) => {
      const dataSelected = operational?.find(item => item.item === key);
    
      // Jika dataSelected tidak ditemukan, kembalikan array kosong
      if (!dataSelected) return [];
    
      if ('data' in dataSelected) {
        const mapData = (fields) => dataSelected.data.map(item => {
          let mappedItem = {};
          fields.forEach(field => {
            mappedItem[field] = item[field];
          });
          return mappedItem;
        });
    
        switch (key) {
          case 'other_domestics':
          case 'other_saudies':
            return mapData(['item_id', 'range_pax', 'price']);
            
          case 'airplane_domestics':
            return mapData(['airplane_id', 'starting_id', 'price', 'starting_exists']);
            
          case 'equipment_additionals':
            return mapData(['id', 'name', 'price']);
            
          default:
            return [];
        }
      } else {
        // Kembalikan dataSelected.price atau array kosong jika undefined
        return dataSelected.price !== undefined ? dataSelected.price : [];
      }
    };
    

    const onHandleSave = async (data) => {
        try {
            setIsLoadingSave(true)
            const res = await PriceUpdateAPI.operationalSave(data)

            if(res.status === 200) {
                dispatch(handleSuccess(res))
                await setOperational(res.data.data)
                onHandleGet()
            } else {
                dispatch(handleError(res.data))
            }

            setIsLoadingSave(false)
            
        } catch (error) {
            dispatch(handleError(error))
            setIsLoadingSave(false)
        }
    }

    const handleChangeData = (category, values) => {
      // Ambil nilai formik dan salin ke _tempData
      let _tempData = { ...formik.values };
    
      // Salin data `others` ke _tempOtherData
      let _tempOtherData = [..._tempData.others];
    
    
      // Filter data `others` untuk menghapus item yang ada di dalam itemIdOthers untuk kategori tertentu
      _tempOtherData = _tempOtherData.filter(
        item => !othersItemIdByCategory[category].includes(item.item_id)
      );
    
      // Tambahkan data `others` baru dari `values` ke _tempOtherData
      if (values.others && values.others.length > 0) {
        _tempOtherData = _tempOtherData.concat(values.others);
      }
    
      // Gabungkan nilai `values` ke dalam _tempData, dan perbarui `others` dengan _tempOtherData
      _tempData = { ..._tempData, ...values, others: _tempOtherData };

      onHandleSave(_tempData)
      setSavingCategory(category)
      // Set nilai hasil ke state `operational`
      formik.setValues(_tempData);
    };


    const handleFilterDataByCategory = (category) => {
      let _tempDataOperationals = { ...formik.values };
    
      // Filter `others` berdasarkan kategori
      let _tempOtherData = [..._tempDataOperationals.others];
      _tempOtherData = _tempOtherData?.filter(
        item => othersItemIdByCategory[category].includes(item.item_id)
      );
    
      // Buat object untuk menyimpan hasil
      let filteredData = {};
    
      // Loop melalui itemNameByCategory dan tambahkan data ke filteredData
      itemNameByCategory[category].forEach(item => {
        filteredData[item] = _tempDataOperationals[item];
      });
    
      // Tambahkan hasil filter `others` ke dalam objek
      filteredData.others = _tempOtherData;
  
      return filteredData;
    
    }
    
    

    useEffect(() => {
      // Gabungkan hasil dari handleGetDataPrice untuk 'other_saudies' dan 'other_domestics' menjadi satu array
      const _tempDataOthers = [
        ...handleGetDataPrice('other_saudies'),
        ...handleGetDataPrice('other_domestics')
      ];
    
      // Buat objek newValue dengan hasil handleGetDataPrice untuk setiap kategori
      const newValue = {
        handling_saudi: handleGetDataPrice('handling_saudi'),
        handling_saudi_fee: handleGetDataPrice('handling_saudi_fee'),
        zamzam_water: handleGetDataPrice('zamzam_water'),
        handling_domestic: handleGetDataPrice('handling_domestic'),
        visa: handleGetDataPrice('visa'),
        complate_equipment: handleGetDataPrice('complate_equipment'),
        manasik: handleGetDataPrice('manasik'),
        mentor_pocket_money: handleGetDataPrice('mentor_pocket_money'),
        center_fee: handleGetDataPrice('center_fee'),
        marketing_fee: handleGetDataPrice('marketing_fee'),
        insurance: handleGetDataPrice('insurance'),
        system: handleGetDataPrice('system'),
        meningitis: handleGetDataPrice('meningitis'),
        passport: handleGetDataPrice('passport'),
        airplane_domestics: handleGetDataPrice('airplane_domestics'),
        equipment_additionals: handleGetDataPrice('equipment_additionals'),
        others: _tempDataOthers // Menggabungkan data others dari saudies dan domestics
      };

      // console.log(newValue)

      // Set nilai baru ke formik
      formik.setValues(newValue);
    }, [operational]); // Hanya menjalankan efek ketika 'operational' berubah    

    useEffect(() => {
        onHandleGet()
    },[])

    return (

        <>
            <div className="flex flex-row">
              <div className="w-[25%]">
                <div className="text-sm font-semibold">Biaya Operasional Saudi</div>
              </div>
              <div className="w-[75%]">
                <OperationalSaudi 
                  data={handleFilterDataByCategory('saudies')}
                  onChange={(data) => handleChangeData('saudies', data)}
                  isLoadingSave={isLoadingSave}
                  savingCategory={savingCategory}
                  />
              </div>
            </div>
            <div className="divider my-6"></div>
            <div className="flex flex-row">
              <div className="w-[25%]">
                <div className="text-sm font-semibold">Biaya Operasional Tanah Air</div>
              </div>
              <div className="w-[75%]">
                <OperationalDomestic
                  data={handleFilterDataByCategory('domestics')}
                  onChange={(data) => handleChangeData('domestics', data)}
                  isLoadingSave={isLoadingSave}
                  savingCategory={savingCategory}
                  />
              </div>
            </div>
            <div className="divider my-6"></div>
        </>

    )
}

export default OperationalPricUpdate