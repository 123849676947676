import React from "react";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";
import ILCoomingSoon from "assets/images/cooming-soon.svg";

const Haji = () => {
  return (
    <div className="flex flex-row justify-center items-center w-full !h-[calc(100vh-135px)] bg-white rounded-2xl">
      <div className="flex flex-col items-center">
        <img src={ILCoomingSoon} alt="comming soon" />
        <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
          Haji dalam pengerjaan
        </div>
      </div>
    </div>
  );
};

export default Haji;
