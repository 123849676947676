
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";

import AntdSelect from "components/form/antdSelect";
import AlertPopup from "components/popup/alert";
import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import InputsCurrency from "components/form/inputsCurrency";

import IconTrash from "assets/icons/trash-maroon.svg";

import { useFormik } from "formik";
import * as Yup from 'yup';

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import AddItemTourCountryForm from "./AddItemTourCountryForm";
import { setIsLoading } from "stores/actions/booking";

const TourCountryForm = ({
  index,
  countryTour,
  onDelete,
  onCancel,
  onUpdateParent,
  onUpdateItem
}) => {

  const dispatch = useDispatch()
  const [months, setMonths] = useState([])
  const [years, setYears] = useState([])

  const [totalDayOptions, setTotalDayOptions] = useState([])
  const [totalNightOptions, setTotalNightOptions] = useState([])

  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(null)

  const [isSaving, setIsSaving] = useState(false)
  
  const [combinationsDayNightExists, setCombinationsDayNightExists] = useState([])

  const [formDirty, setFormDirty] = useState(false)

  const [isLoading, setIsloading] = useState(false)

  const handleSetYears = () => {
    const yearNow = new Date().getFullYear();

    // Membuat daftar tahun
    const yearList = [];
    
    for (let i = 0; i <= 20; i++) {
        const yearSelected = yearNow + i;
        yearList.push({ value: String(yearSelected), label: yearSelected });
    }

    yearList.sort((a, b) => a - b);

    return yearList;
  };

  const handleSetTotalOptions = (disableNumbers = []) => {
    let _tempOptions = []
  
    for(let i = 1; i <= 20; i++) {
      _tempOptions.push({
        value: i,
        label: i,
        disabled: disableNumbers.includes(i) // Disable jika angka ada dalam array
      })
    }
  
    return _tempOptions
  }
  
  const formikItems = useFormik({
    initialValues: {
      data: [
        {
          name: '',
          month: '',
          year: '',
          total_nights: '',
          total_days: '',
          id: null,
          price: ''
        }
      ],
      delete_ids: []
    },
    validationSchema: Yup.object({
      data: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Nama wajib diisi'),
          month: Yup.string()
            .oneOf(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'])
            .required('Bulan wajib diisi'),
          year: Yup.number().required('Tahun wajib diisi'),
          total_nights: Yup.number().min(1).required('Jumlah malam wajib diisi'),
          total_days: Yup.number().min(1).required('Jumlah hari wajib diisi'),
          price: Yup.number().min(1).required('Harga wajib diisi')
        })
      ),
      delete_ids: Yup.array().of(Yup.number())
    })
  })

  const formikTour = useFormik({
    initialValues: {
      name: null,
      month: null,
      year: null
    }
  })

  const handleParseCountryTourItem = () => {
    let items = []

    countryTour.data.forEach((item) => {
      items.push({
        id: item.id,
        name: item.name,
        month: item.month,
        year: item.year,
        total_nights: item.total_nights,
        total_days: item.total_days,
        price: item.price
      })
    })

    formikItems.setFieldValue('data', items)
  }

  const handleParseTotalOptions = () => {
    let _tempTotalDayOptions = []
    let _tempTotalNightOptions = []
  
    let combinations = formikItems.values.data.map(item => ({
      total_days: item.total_days,
      total_nights: item.total_nights
    }));
  
    formikItems.values.data.forEach((item, index) => {
      // Disable night options where the same total_days already exists
      const disableNightsForSameDay = combinations
        .filter(c => c.total_days === item.total_days)
        .map(c => c.total_nights);
  
      _tempTotalNightOptions[index] = handleSetTotalOptions(disableNightsForSameDay);
  
      // Disable day options where the same total_nights already exists
      const disableDaysForSameNight = combinations
        .filter(c => c.total_nights === item.total_nights)
        .map(c => c.total_days);
  
      _tempTotalDayOptions[index] = handleSetTotalOptions(disableDaysForSameNight);
    });
  
    setTotalDayOptions(_tempTotalDayOptions);
    setTotalNightOptions(_tempTotalNightOptions);
    setCombinationsDayNightExists(combinations)
  };

  const handleDeleteItemConfirm = (index) => {
    setShowConfirmDelete(true)
    setDeleteIndex(index)
  }

  const handleDeleteItem = () => {
    const _itemTours = [...formikItems.values.data]

    const itemSelected = _itemTours[deleteIndex]
    const deleteId = itemSelected.id

    if(deleteId) {
      formikItems.setFieldValue('delete_ids', [...formikItems.values.delete_ids, deleteId])
    }

    _itemTours.splice(deleteIndex, 1)

    formikItems.setFieldValue('data', _itemTours)

    setShowConfirmDelete(false)
    setDeleteIndex(null)
  }

  const handleAddItem = (value) => {
    const newData = {
      id: null,
      name: countryTour.name,
      month: countryTour.month,
      year: countryTour.year,
      total_nights: value.total_nights,
      total_days: value.total_days,
      price: value.price
    }

    formikItems.setFieldValue('data', [...formikItems.values.data, newData])
  }

  const handleSave = async () => {
    setIsSaving(true)
    const response = await PriceUpdateAPI.countryToursSave(formikItems.values)
    setIsSaving(false)

    if(response.status == 200) {
      dispatch(handleSuccess(response))
      formikItems.setFieldValue('delete_ids', [])
      setFormDirty(false)
      onUpdateItem(index, response.data.data)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleGetByMonthYear = async (month, year) => {
    setIsLoading(true)
    const response = await PriceUpdateAPI.countryToursGetByNameTime({
      name: countryTour.name,
      month,
      year
    })
    setIsLoading(false)

    if(response.status == 200) {
      onUpdateItem(index, response.data.data)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleCheckFormDirty = () => {
    const formikData = formikItems.values.data;
    const originalData = countryTour.data;
  
    // Pengecekan jumlah item
    if (formikData.length !== originalData.length) {
      return true;
    }
  
    // Pengecekan item satu per satu
    for (let i = 0; i < originalData.length; i++) {
      if (
        formikData[i].total_nights !== originalData[i].total_nights ||
        formikData[i].total_days !== originalData[i].total_days ||
        formikData[i].price !== originalData[i].price
      ) {
        return true;
      }
    }
  
    // Jika tidak ada perubahan
    return false;
  };
  

  useEffect(() => {
      setMonths([
          { value: "01", label: "Januari" },
          { value: "02", label: "Februari" },
          { value: "03", label: "Maret" },
          { value: "04", label: "April" },
          { value: "05", label: "Mei" },
          { value: "06", label: "Juni" },
          { value: "07", label: "Juli" },
          { value: "08", label: "Agustus" },
          { value: "09", label: "September" },
          { value: "10", label: "Oktober" },
          { value: "11", label: "November" },
          { value: "12", label: "Desember" },
      ]);

      setYears(handleSetYears())
  }, [])

  useEffect(() => {
    handleParseCountryTourItem()
    formikTour.setFieldValue('name', countryTour.name)
    formikTour.setFieldValue('month', countryTour.month)
    formikTour.setFieldValue('year', countryTour.year)

  }, [countryTour])

  useEffect(() => {
    handleParseTotalOptions()
    setFormDirty(handleCheckFormDirty())
  }, [formikItems.values.data])

  useEffect(() => {
    onUpdateParent(formikTour.values)
  }, [formikTour.values])

  return (
    <>
      <AlertPopup
          open={showConfirmDelete}
          handleClose={() => setShowConfirmDelete(false)}
          handleContinue={() => handleDeleteItem()}
          // loading={isRemoveLoading}
          subtitle="Apakah anda yakin menghapus data item tour ini?"
      />
      <div
          className="border border-[#E0E0E0] p-4 rounded-2xl"
          key={index}
          >

          <div className="flex justify-between items-center">
              <div className="font-semibold">{countryTour?.newData && 'Tambah '}Negara Tour {index + 1}</div>
              <div className="flex justify-end gap-4 items-center">
                  {countryTour?.newData ? (
                    <>
                      <Buttons
                        onClick={() => onCancel()}
                        text="Batal"
                        customClass="btn-primary btn-outline btn-small"/>
                    </>
                  ) : (
                    <>
                      <i onClick={() => onDelete()} className="!cursor-pointer">
                        <img src={IconTrash} className={`w-6 h-6`} />
                      </i>
                    </>
                  )}
                  <Buttons
                      text="Simpan"
                      disabled={!formikItems.isValid || !formDirty}
                      onClick={() => handleSave()}
                      loading={isSaving}
                      customClass="btn-primary btn-small"
                  />
              </div>
          </div>
          
          <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                  
                  <div className="w-full">
                      <Inputs
                          label="Negara Tour"
                          readOnly={!countryTour?.newData}
                          value={countryTour?.name}
                          onChange={(e) => {
                            formikTour.setFieldValue('name', e.target.value)
                          }}
                          placeholder="Masukkan negara tour"
                      />
                  </div>

                  <div className="w-full">
                      <AntdSelect
                          label="Bulan"
                          placeholder="Pilih bulan"
                          options={months}
                          disabled={isLoading}
                          onChange={(item) => {
                            formikTour.setFieldValue('month', item.value)
                            handleGetByMonthYear(item.value, countryTour.year)
                          }}
                          value={countryTour.month}
                      />
                  </div>
                  <div className="w-full">
                      <AntdSelect
                          label="Tahun"
                          placeholder="Pilih tahun"
                          options={years}
                          disabled={isLoading}
                          onChange={(item) => {
                            formikTour.setFieldValue('year', item.value)
                            handleGetByMonthYear(countryTour.month, item.value)
                          }}
                          value={countryTour.year}
                          />
                  </div>

              </div>
              <div className="flex flex-col gap-4">
                  {formikItems.values.data.length > 0 && formikItems.values.data?.map((tourItem, indexTourItem) => (
                      <>
                        <div className="flex gap-4" key={indexTourItem}>
                            <div className="w-full">
                              <AntdSelect 
                                label={"Pilih Jumlah Hari"}
                                placeholder={"Pilih jumlah hari"}
                                showSearch={true}
                                disabled={isLoading}
                                options={totalDayOptions[indexTourItem]}
                                value={tourItem?.total_days}
                                onChange={(dataSelected) => {
                                  formikItems.setFieldValue(`data.${indexTourItem}.total_days`, dataSelected.value)
                                }}
                                />
                            </div>
                            <div className="w-full">
                              <AntdSelect 
                                label={"Pilih Jumlah Malam"}
                                placeholder={"Pilih jumlah malam"}
                                disabled={isLoading}
                                showSearch={true}
                                options={totalNightOptions[indexTourItem]}
                                value={tourItem?.total_nights}
                                onChange={(dataSelected) => {
                                  formikItems.setFieldValue(`data.${indexTourItem}.total_nights`, dataSelected.value)
                                }}
                                />
                            </div>
                            <div className="w-full">
                                <InputsCurrency
                                    label="Harga"
                                    value={tourItem?.price}
                                    disabled={isLoading}
                                    placeholder="Masukan harga tourItem"
                                    onChange={(val) => {
                                        formikItems.setFieldValue(`data.${indexTourItem}.price`, val)
                                    }}
                                />
                            </div>
                            {formikItems.values.data.length > 1 && (
                              <div 
                                  onClick={() => handleDeleteItemConfirm(indexTourItem)}
                                  className={`cursor-pointer flex-shrink-0 flex items-center justify-center`}>
                                  <img src={IconTrash} className="mt-6" />
                              </div>
                            )}
                        </div>
                      </>
                  ))}
                  <AddItemTourCountryForm 
                    combinationsDayNightExists={combinationsDayNightExists}
                    onAddItem={handleAddItem}
                    />
              </div>
          </div>

      </div>
    </>
  )
}

export default TourCountryForm